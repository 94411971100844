import { useSelector, useDispatch } from 'react-redux';
import { Actions } from '../../../redux/actions';

export const useSetRedirectOnLogin = (debug: boolean = false) => {
    const dispatch = useDispatch()
    const redirectOnLogin = useSelector((state: any) => state.redirectOnLogin)

    const setRedirectOnLogin = (path: string) => {
        dispatch(Actions.setRedirectOnLogin(path))
        if (debug) console.log('redirecOnLogin set to:', path)
    }

    const clearRedirectOnLogin = () => {
        dispatch(Actions.setRedirectOnLogin(''))
        if (debug) console.log('cleared redirectOnLogin')
    }

    return [
        redirectOnLogin,
        setRedirectOnLogin,
        clearRedirectOnLogin,
    ] as const;
}

export const useRedirectOnLogin = (onRedirect: (path: string) => void, debug: boolean = false) => {
    const [redirectPath, _, clearRedirectOnLogin] = useSetRedirectOnLogin(debug)

    const handleRedirect = (path?: string) => {
        if (redirectPath) {
            const to = path || redirectPath
            if (debug) console.log('redirecting to:', to)
            onRedirect(to)
            clearRedirectOnLogin()
        }
    }

    return [
        redirectPath,
        handleRedirect
    ] as const
}

export default {
    useSetRedirectOnLogin,
    useRedirectOnLogin
}