import React, { useCallback, useMemo } from 'react'
import { Grid, Card } from '@material-ui/core';
import { getThumbnail } from '@necta-tech/s3';
import { PrimaryButton } from '../../global/style';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    background: #eaeaea;
`;

const CPrimaryButton = styled(PrimaryButton)`
    && {
        margin: 0.2rem;
        max-width: 250px;
        height: 2.6rem;
        font-size: 0.8rem !important;
    }
`;

const ImageItem = styled(Grid)<{background?: string}>`
    min-height: 215px;
    background: url(${props => props.background ? props.background : require('../../assets/img/default-profile.png')}) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;    
`;

const UserInfo = styled(Grid)`
    padding: 1rem 0.5rem;
    h3 {
        font-weight: 500;
        font-size: 1.25rem;
        margin: 0.4rem 0 0.7rem 0;
    }
    h4 {
        font-size: 0.95rem;
        font-weight: 400;
        margin: 0.3rem 0;
    }
    p {
        margin: 0.2rem 0 1rem 0;
    }
`;

const DetailPanel = ({rowData}: any) => {


    const cvLink = useMemo(() => 'https://scrumpro.co.za/cv/' + rowData.shortid, [rowData]);

    const handleCv = useCallback(() => window.open(cvLink, '_blank'), [cvLink]);

    return (
        <Container>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Card>
                        <Grid container spacing={0}>
                            <ImageItem item md={5} xs={12} background={rowData.profilePicUrl ? getThumbnail(rowData.profilePicUrl) : ''} />
                            <UserInfo item md={7} xs={12}>
                                <h3>{rowData.firstname} {rowData.lastname}</h3>
                                <h4>CV Link:</h4>
                                <p><a href={cvLink} target="_blank" rel="noopener noreferrer">{cvLink}</a></p>
                                <h4>Position:</h4>
                                <p>{rowData.stats && rowData.stats.positionPrimary ? rowData.stats.positionPrimary : 'None selected'}</p>
                            </UserInfo>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <div>
                        <CPrimaryButton onClick={handleCv}>
                            View Full CV
                        </CPrimaryButton>
                    </div>
                </Grid>
            </Grid>
        </Container>
    )
}

export default DetailPanel
