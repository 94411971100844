import React from 'react';
import { CircularProgress } from '@material-ui/core';
import * as yup from 'yup';
import { Formik, Field, FormikValues, FormikHelpers } from 'formik';
import { forgotPasswordSubmit } from '@necta-tech/cognito';
import { presentSuccess, presentError } from '@necta-tech/alert';
import { KeyboardArrowLeft as Back } from '@material-ui/icons';
import { PasswordField, getFormData } from '../../services/helpers/form-helper';
import SubmitButton from '../../components/submit-button';
import {
  Padder,
  CenterColumn,
  CTextField,
  CardTitle,
  Fields,
  LoginCard,
  AltForm as Form,
  BackButton
} from './style';

const { schema, fields, initialValues } = getFormData(
  yup.object().shape({
    otp: yup
      .string()
      .required()
      .label('OTP'),
    password: yup
      .string()
      .required()
      .label('Password')
  })
);

const ChangePassword = ({ passwordChanged, handleBack, email }: any) => {

  const handleSendOTP = async (values: FormikValues, actions: FormikHelpers<any>) => {
    actions.setSubmitting(true);
    try {
      await forgotPasswordSubmit(email, values.otp, values.password);
      presentSuccess('Password has been changed');
      actions.setSubmitting(false);
      if (passwordChanged) passwordChanged();
    } catch (e) {
      actions.setSubmitting(false);
      presentError(e, 'Unable to reset password');
    }
  };

  return (
    <Formik validationSchema={schema} initialValues={initialValues} onSubmit={handleSendOTP} validateOnBlur>
      {({ handleSubmit, isSubmitting }) => (
        <> 
          <LoginCard>
            <CenterColumn>
              <CardTitle>
                <BackButton onClick={() => handleBack()}>
                  <Back />
                </BackButton>
                <h1>Change Password</h1>
              </CardTitle>
              <Form>
                <Fields>
                  <Field {...fields.otp} component={CTextField} />
                  <Field {...fields.password} component={PasswordField} />
                </Fields>
                <Padder />
              </Form>
            </CenterColumn>
          </LoginCard>
          <SubmitButton loading={isSubmitting} handleSubmit={handleSubmit}>
            Change Password
          </SubmitButton>
        </>
      )}
    </Formik>
  );
};

export default ChangePassword;
