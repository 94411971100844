import React, { useState, FunctionComponent, useEffect } from 'react';
import ChipInput from 'material-ui-chip-input';
import { MuiPickersUtilsProvider, KeyboardDatePicker, MaterialUiPickersDate, KeyboardDateTimePicker } from '@material-ui/pickers';
import MoodIcon from '@material-ui/icons/Mood';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import CircularProgress from '@material-ui/core/CircularProgress';
import Rating from '@material-ui/lab/Rating';
import DateFnsUtils from '@date-io/date-fns';
import { FieldProps, getIn } from 'formik';
import { List as MUIList, ListItem, ListItemText, Checkbox, FormControlLabel, FormControl, Select, MenuItem, InputLabel, FormHelperText } from '@material-ui/core';
import Field from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { startCase } from 'lodash';
import Yup from 'yup';
import { mapValues } from 'lodash';
import styled, { css } from 'styled-components';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';

import { regExPassword } from '../../global/regex';

const defaultValues = {
  string: '',
  number: 0,
  boolean: false,
  date: new Date().toISOString(),
  array: []
} as any;

/**
 * Generates Formik helpers from a yup validation schema.
 *
 * @param schema The yup validation schema
 * @return returns the schema, formik fields, and initial values for each field
 */
export const getFormData = (schema: Yup.ObjectSchema<any>) => {
  const description: Yup.SchemaDescription = schema.describe();
  const initialValues = schema.cast(schema);

  return {
    schema: schema as Yup.ObjectSchema<any>,
    fields: mapFields(description),
    initialValues: mapInitialValues(initialValues, description)
  }
};

export const generatePassword = (length: number = 8): string => {
  const lower = (Math.random().toString(36) + '00000000000000000').slice(2, length / 2 + 2).toLowerCase();
  const upper = (Math.random().toString(36) + '00000000000000000').slice(2, length / 2 + 2).toUpperCase();
  const pass = lower + upper;
  if (regExPassword.test(pass)) {
    return pass;
  }
  return generatePassword(length);
  // const len = length ? length : 8
  // const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
  // let retVal = ""
  // for (var i = 0; i < len; i++) {
  //     retVal += charset.charAt(Math.floor(Math.random() * charset.length))
  // }
  // return retVal
};

export const PasswordField: React.FC<FieldProps & {disabled?: boolean}> = ({ disabled = false, field, form, ...props }) => {

  const [show, setShow] = useState<boolean>(false);

  const errorText = getIn(form.touched, field.name) && getIn(form.errors, field.name);
  const { isSubmitting } = form

  return (
    <Field
      fullWidth
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="Toggle password visibility" onClick={() => setShow(!show)}>
              {show ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
        autoComplete: 'new-password'
      }}
      helperText={errorText}
      error={!!errorText}
      {...field}
      {...props}
      type={show ? 'text' : 'password'}
      disabled={isSubmitting || disabled}
    />
  );
};

export const TextField: React.FC<FieldProps & { disabled?: boolean }> = ({ disabled = false, field, form, ...props }) => {
  const { value } = field;
  const { isSubmitting } = form
  const errorText = getIn(form.touched, field.name) && getIn(form.errors, field.name);

  return <Field disabled={isSubmitting || disabled} margin="normal" variant="outlined" helperText={errorText} error={!!errorText} {...field} {...props} value={value || ''} />;
};

type Option = {
  id: string | number;
  name: string;
};

export const NumberField: React.FC<FieldProps> = ({ field, form, ...props }) => {
  const errorText = getIn(form.touched, field.name) && getIn(form.errors, field.name);
  const { isSubmitting } = form;

  return <Field disabled={isSubmitting} margin="normal" type="number" error={!!errorText} {...field} {...props} />;
};

export const SelectField: React.FC<
  FieldProps & {
    label?: string;
    options?: Option[] | [];
    children?: React.ReactNode;
    required: boolean;
    labelWidth?: number;
    variant?: 'outlined' | 'standard' | 'filled';
  }
> = ({ field, form, label, options = [], required, labelWidth = 65, variant = 'outlined', children, ...props }) => {
  const errorText = getIn(form.touched, field.name) && getIn(form.errors, field.name);
  const { isSubmitting } = form;
  const { value, ...rest } = field;

  return (
    <FormControl variant={variant} error={!!errorText} fullWidth margin="normal">
      <InputLabel required={required}>{label}</InputLabel>
      <Select {...rest} value={value || ''} disabled={isSubmitting} labelWidth={variant === 'outlined' ? labelWidth : undefined} {...props}>
        {children ||
          options.map((opt: Option) => {
            const children = startCase(opt.name);
            return (
              <MenuItem key={opt.id} value={opt.id}>
                {children}
              </MenuItem>
            );
          })}
      </Select>
      { errorText && <FormHelperText>{errorText}</FormHelperText> }
    </FormControl>
  );
};

const formatDate = (date: any) => {
  if (date == null) return null;
  if (isNaN(new Date(date).getTime())) return null;
  return new Date(date).toISOString();
};

interface DatePickerProps {
  format?: string;
  variant?: 'outlined' | 'standard' | 'filled';
  type?: 'date' | 'datetime';
}

export const DatePicker: React.FC<FieldProps & DatePickerProps> = ({ field, form, format, variant = 'outlined', type = 'date', ...props }) => {
  const { name, value } = field;
  const { errors, touched, setFieldValue, setFieldTouched, isSubmitting } = form;

  const FORMAT = 'MMM do yyyy' + (type === 'datetime' ? ' - HH:mm' : '') || format;
  const errorText = getIn(touched, name) && getIn(errors, name);

  const handleChange = (date: MaterialUiPickersDate) => {
    setFieldValue(name, formatDate(date));
    setFieldTouched(name, true);
  };

  const handleBlur = () => setFieldTouched(name, true);

  // TODO:
  // 1) do not default to current date
  // 2) clean up and see if better solutions

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {type === 'datetime' ? (
        <KeyboardDateTimePicker
          margin="normal"
          inputVariant={variant}
          helperText={errorText}
          error={!!errorText}
          format={FORMAT}
          {...field}
          {...props}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value || null}
          disabled={isSubmitting}
        />
      ) : (
        <KeyboardDatePicker
          margin="normal"
          inputVariant={variant}
          helperText={errorText}
          error={!!errorText}
          format={FORMAT}
          {...field}
          {...props}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value || null}
          disabled={isSubmitting}
        />
      )}
    </MuiPickersUtilsProvider>
  );
};

export const ChippedInput: React.FC<FieldProps> = ({ field, form, ...props }) => {
  const { name, value } = field;
  const { setFieldValue, isSubmitting } = form;

  const handleAdd = (chip: string) => {
    setFieldValue(name, [...value, chip]);
  };

  const handleDelete = (chip: string, index: number) => {
    const newZones = [...value];
    newZones.splice(index, 1);
    setFieldValue(name, [...newZones]);
  };

  // Empty onChange and onBlur as we want to ignore the ones that Formik passes for us
  return <ChipInput disabled={isSubmitting} fullWidth variant="outlined" {...field} {...props} onAdd={handleAdd} onDelete={handleDelete} onChange={() => {}} onBlur={() => {}} />;
};

export const CheckboxField: React.FC<
  FieldProps & {
    label?: string;
    required: boolean;
    color?: 'primary' | 'secondary' | 'default' | undefined;
  }
> = ({ field, form, label, required, color = 'primary', ...props }) => {
  const { name, value } = field;
  const { isSubmitting, touched, errors } = form;
  const errorText = getIn(touched, name) && getIn(errors, name);

  return (
    <FormControl error={!!errorText}>
      <FormControlLabel control={
          <Checkbox disabled={isSubmitting} checked={value} color={color} {...field} />
        }
        labelPlacement="end"
        label={label}
      />
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

//TODO: Add typings
const Item = styled<FunctionComponent<any>>(ListItem)`
  cursor: pointer;
  ${p =>
    p.active === 'true'
      ? css`
          background-color: #eee;
        `
      : css``}
`;

const getAddress: (address_components: any[]) => Promise<{ country?: string; province?: string; city?: string }> = (address_components: any[]) => {
  return new Promise((resolve, reject) => {
    try {
      const address = address_components.reverse();
      const { long_name: country } = address.find(component => component.types.includes('country'));
      const { long_name: province } = address.find(component => component.types.includes('administrative_area_level_1'));
      const { long_name: city } = address.find(component => component.types.includes('locality'));
      resolve({ country, province, city });
    } catch (e) {
      reject(e);
    }
  });
};

const SuggestionContainer = styled.div`
  width: 100%;
  position: relative;
`;

const List = styled(MUIList)`
  position: absolute !important;
  width: 100%;
  background: white;
  border: 1px solid #aaa;
  top: 0;
  z-index: 100;
`;

export const LocationPicker: React.FC<
  FieldProps & {
    labelWidth?: number;
    onChange?: any;
    defaultValue?: string;
  }
> = ({ field, form, labelWidth = 65, children, defaultValue, ...props }) => {
  const { onChange: onChange2, ...restProps } = props;
  const { name, value, onChange, ...fieldRest } = field;
  const [searchTerm, setSearchTerm] = useState(value);
  const { setFieldValue, touched, errors, isSubmitting } = form;
  const errorText = getIn(touched, name) && getIn(errors, name);

  const handleSelect = async (address: string) => {
    try {
      const [result]: any = await geocodeByAddress(address);
      const { address_components } = result;
      const { country, province, city } = await getAddress(address_components);

      const { formatted_address } = result;
      setFieldValue('locationAddress', formatted_address);
      setFieldValue('locationCity', city);
      setFieldValue('locationCountry', country);
      setFieldValue('locationProvince', province);
      setSearchTerm(formatted_address);
    } catch (e) {
      console.error('Error', e);
    }
  };

  useEffect(() => {
    if (defaultValue) setSearchTerm(defaultValue);
  }, [defaultValue]);

  return (
    <PlacesAutocomplete value={searchTerm} onChange={setSearchTerm} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={{ width: '100%' }}>
          <Field
            {...getInputProps({ disabled: isSubmitting, margin: 'normal', variant: 'outlined', helperText: errorText, error: !!errorText, name, style: { width: '100%' }, ...fieldRest, ...restProps })}
          />
          <SuggestionContainer>
            {((suggestions && suggestions.length > 0) || loading) && (
              <List>
                {loading && <CircularProgress size={48} color="primary" style={{ display: 'block', width: '48px', margin: '1rem auto' }} />}
                {suggestions.map(sugg => {
                  const {
                    id,
                    active,
                    formattedSuggestion: { mainText, secondaryText }
                  } = sugg;
                  return (
                    <Item {...getSuggestionItemProps(sugg, { key: id, active: `${active}` })}>
                      <ListItemText primary={mainText} secondary={secondaryText} />
                    </Item>
                  );
                })}
              </List>
            )}
          </SuggestionContainer>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  align-items: center;

  .bad-icon {
    color: red;
    margin-right: 1rem;
  }

  .good-icon {
    color: green;
    margin-left: 1rem;
  }
`;

export const RatingField: React.FC<FieldProps & { required: boolean }> = ({ field, form, required, ...props }) => {

  const { name, value, ...fieldRest } = field;
  const { touched, errors, isSubmitting } = form
  const errorText = getIn(touched, name) && getIn(errors, name);

  return (
    <FormControl error={!!errorText} fullWidth margin="normal">
      <Row>
        <MoodBadIcon className="bad-icon" />
        <Rating disabled={isSubmitting} max={10} {...fieldRest} name={name} value={parseFloat(value)} precision={0.5} size="large" {...props} />
        <MoodIcon className="good-icon" />
      </Row>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

const mapInitialValues = (initialValues: any, description: Yup.SchemaDescription) => {
  const { fields: data } = description;

  const values = mapValues(data, ({type}: Yup.SchemaDescription, key: string) => {
    if (type === 'object') return mapInitialValues(initialValues[key], data[key] as Yup.SchemaDescription);
    return initialValues[key] || defaultValues[type]
  }) as any;

  return values
};

const mapFields = (description: Yup.SchemaDescription, prefix: string = '') => {
  const { fields: data } = description;

  const fields: any = mapValues(data, ({ type, tests, ...rest}: Yup.SchemaDescription, key: string) => {

    if (type === 'object') return mapFields(data[key] as Yup.SchemaDescription, prefix + key + '.');

    return {
      required: tests.some((t: any) => t.name === 'required'),
      name: prefix + key,
      ...rest
    }
  });

  return fields
};
