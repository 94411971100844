import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Login from './pages/login/Login';
import RouteSelector from './pages/RouteSelector';
import GuardRoute from './components/guard-route/GuardRoute';
import { connect } from 'react-redux';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

//DEFINE global MUI style props
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2CB7F8'
    },
    secondary: {
      main: '#302050'
    }
  }
});

//DEFINE global MUI style props
const SCTheme = {
  primary: '#2CB7F8',
  secondary: '#302050',
  warning: '#FDAB0D',
  success: '#45BB58',
  danger: '#f44336'
};

const Global = createGlobalStyle`
  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: normal;
  }
  h1, h2, h3 {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: normal;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: inherit;
  height: inherit;
`;

const App: React.FC = ({cognitoUser, props}: any) => {

  return (
    <React.Fragment>

      <ThemeProvider theme={SCTheme}>
        <MuiThemeProvider theme={theme}>
          <Global />
          <Router>
            <Container>
              <GuardRoute exact path="/login" redirect="/" guard={!cognitoUser.inSession} component={Login} />
              <GuardRoute path="/" redirect="/login" guard={cognitoUser.inSession} component={RouteSelector} />
            </Container>
          </Router>
        </MuiThemeProvider>
      </ThemeProvider>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any, props: any) => {
  return {
    cognitoUser: state.cognitoUser,
    props
  }
}

export default connect(mapStateToProps, null)(App);
