import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, CircularProgress, Checkbox } from '@material-ui/core';
import styled from 'styled-components';
import { presentSuccess, presentError } from '@necta-tech/alert';
import { useLazyQuery, useMutation } from 'react-apollo';
import { KeyboardArrowLeft as Back, KeyboardArrowRight as Forward } from '@material-ui/icons';
import { Formik, Field, FormikValues, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { setCurrentUser } from '../../redux/actions';
import { INVITE_USERS_MUTATION, FIND_USERS_QUERY } from '../../global/gql/users';
import {
  getFormData,
  ChippedInput
} from '../../services/helpers/form-helper';
import {
  Page,
  CenteredColumn,
  Form,
  CardTitle,
  Divider,
  Required,
  SubTitle,
  Card,
  BackButton,
  PrimaryButton,
} from '../../global/style';
import MaterialTable, { Column } from 'material-table';
import OrgsDropdown from '../../components/organisation/OrgDropDown';
import { getActiveOrgId } from '../../global/helpers/selectors';

const urlRegex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;

const { schema, fields, initialValues } = getFormData(
  yup.object().shape({
    cvLinks: yup
      .array()
      .required()
      .label('CV Links'),
    organisationId: yup.string().required().label('Organisation'),
  })
);

const CreateButton = styled(PrimaryButton)`
  && {
    height: 56px;
    width: 100%;
    border-radius: 0;
  }
`;

const ChipInput = styled(ChippedInput)`
  && {
    width: 100%;
    align-self: baseline;
    margin-top: 30px;
    margin-bottom: 20px;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
`;

const Title = styled.div`
  flex: 1;
`;

const ForwardButton = styled(BackButton)`
  && {
    margin-right: 0;
    margin-left: 10px;
  }
`;

const SelectUsers = styled.div`
  margin-bottom: 30px;
  width: 100%;
`;

const stripUrl = (potentialUrl: string): string => {
  if (urlRegex.test(potentialUrl)) {
    const parts = potentialUrl.split('/');
    return parts[parts.length - 1];
  }
  return potentialUrl;
}

const InviteUser = ({ cognitoUser, currentUser, activeOrgId, props: { history } }: any) => {
  const [defaultValues] = useState<any>({ ...initialValues, organisationId: activeOrgId });

  const [inviteUsers, { loading }] = useMutation(INVITE_USERS_MUTATION);

  const [getUsers, { loading: isLoading }] = useLazyQuery(FIND_USERS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data: any) => {
      if (!data || data.findByCvLinks.length === 0) {
        presentError('No results found matching criteria', '', true);
        return;
      }
      presentSuccess('Users found!', 'Success');
      const allUsers = [...users];
      for (const u of data.findByCvLinks) {
        if (!users.find((user: any) => user.id === u.id)) {
          allUsers.push(u);
        }
      }
      const userIds = allUsers.map((u: any) => u.id);
      const selected = selectedUsers.length > 0 ? selectedUsers.concat(userIds) : userIds;
      setSelectedUsers(selected);
      setUsers(allUsers);
      setState('submit');
    },
    onError: (err: any) => {
      presentError('Unable to find players, please try again', '', true);
    }
  });

  const [users, setUsers] = useState<any[]>([]);

  const [state, setState] = useState<string>('');

  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);

  const onSubmit = useCallback(async (values: FormikValues) => {
    try {
      await inviteUsers({ variables: { newInvitation: { organisationId: values.organisationId, ids: selectedUsers } } });
      presentSuccess('Players invited Successfully!', 'Success');
    } catch (e) {
      presentError(e, 'Unable to invite players!');
    }
  }, [selectedUsers, inviteUsers]);

  const onSearch = async (values: FormikValues, actions: FormikHelpers<any>) => {
    if (values.cvLinks.length <= 0) {
      presentError('Press enter to confirm a CV link once entered','Please enter CV links');
      return;
    }
    try {
      const shortids = values.cvLinks.map((v: any) => stripUrl(v));
      await getUsers({ variables: { shortids } });
    } catch (e) {
      presentError(e, 'Unable to add find users!');
    }
  };

  const handleClick = (row: any) => {
    if (selectedUsers.includes(row.id)) {
      const _selectedUsers = selectedUsers.filter((s: any) => s !== row.id);
      setSelectedUsers(_selectedUsers);
    } else {
      const _selectedUsers = [...selectedUsers];
      _selectedUsers.push(row.id);
      setSelectedUsers(_selectedUsers);
    }
  };

  const goBack = useCallback(() => {
    if (state !== '') {
      setState('');
    } else {
      history.goBack();
    }
  }, [history, state]);

  return (
    <Page>
      <Card>
        <CenteredColumn>
          <Formik initialValues={defaultValues} validateOnBlur validationSchema={schema} onSubmit={onSearch}>
            {({ handleSubmit, setFieldValue, values }) => (
              <>
                <CardTitle>
                  <BackButton onClick={() => goBack()}>
                    <Back />
                  </BackButton>
                  <Title>Invite Players</Title>
                  { users && users.length > 0 && state === '' && (
                    <ForwardButton onClick={() => setState('submit')}>
                      <Forward />
                    </ForwardButton>
                  )}
                </CardTitle>
                <Divider />
                <Required>
                  <SubTitle>{state === '' ? 'Find Users' : 'Select Users'}</SubTitle>
                  <h3>*required</h3>
                </Required>
                { state === '' ? (
                    <Form>
                        <Grid container spacing={2} direction="row" alignItems="center" justify="center">
                          <Grid item xs={12} md={12}>
                            <Field {...fields.cvLinks} component={ChipInput} placeholder="Type and press enter to add CV links" />
                          </Grid>
                          { cognitoUser.permissions === 'Admin' && (
                            <Grid item xs={12} md={12}>
                              <OrgsDropdown Render={Field} field={fields.organisationId} />
                            </Grid>
                          )}
                        </Grid>
                    </Form>
                  ) : (users && users.length > 0 && (
                    <SelectUsers>
                      <MaterialTable
                        title={'Players found'}
                        isLoading={isLoading || loading}
                        style={{ boxShadow: 'none', width: '100%', marginTop: '10px' }}
                        columns={
                          [
                            {
                              field: 'checked',
                              title: 'Selected',
                              render: (rowData: any) => {
                                const isSelected = selectedUsers.includes(rowData.id);
                                return <Checkbox checked={isSelected} />;
                              }
                            },
                            { title: 'Name', field: 'firstname', defaultSort: 'asc' },
                            { title: 'Surname', field: 'lastname' },
                            { title: 'Position', field: 'stats.positionPrimary' },
                            { title: 'Province', field: 'locationProvince' },
                          ] as Column<any>[]
                        }
                        data={users}
                        onRowClick={(e: any, row: any) => handleClick(row)}
                        options={{
                          search: false,
                          showTitle: false,
                          toolbar: false,
                          showFirstLastPageButtons: false,
                          emptyRowsWhenPaging: false,
                          paginationType: 'stepped',
                          paging: users && users.length > 5 ? true : false,
                          padding: 'dense'
                        }}
                      />
                    </SelectUsers>
                  )
                )}
                { state !== 'submit' ? (
                  <CreateButton disabled={isLoading || values.cvLinks.length <= 0 || !values.organisationId} onClick={() => handleSubmit()}>
                    {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Find Players'}
                  </CreateButton>
                ) : (
                  <CreateButton disabled={loading || selectedUsers.length <= 0 || !values.organisationId} onClick={() => onSubmit(values)}>
                    {loading ? <CircularProgress size={24} color="inherit" /> : 'Add Players'}
                  </CreateButton>
                )}
              </>
            )}
          </Formik>
        </CenteredColumn>
      </Card>
    </Page>
  );
};

const mapStateToProps = (state: any, props: any) => {
  return {
    cognitoUser: state.cognitoUser,
    currentUser: state.currentUser,
    activeOrgId: getActiveOrgId(state),
    props
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setCurrentUser: (currentUser: any) => {
      dispatch(setCurrentUser(currentUser));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(InviteUser)
);
