import React, { FC, useLayoutEffect, useRef, useState } from 'react';
import { KeyboardArrowLeft as Back } from '@material-ui/icons';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { BackButton, Card, CardTitle, CenteredColumn, Divider, Page } from '../../global/style';
import ListLoader from './ListLoader';
import { useWindowWidth } from '../../custom-hooks/viewhooks';
import FieldLoader from './FieldLoader';

declare interface LoaderContainerProps {
  marginTop?: string;
  marginBottom?: string;
}
const LoaderContainer = styled.div<LoaderContainerProps>`
  margin-top: ${p => p.marginTop ? p.marginTop : '30px'};
  margin-bottom: ${p => p.marginBottom ? p.marginBottom : '30px'};
`;

interface SkeletonLoader {
  history: any;
  type: string;
  title?: string;
  showHeader?: boolean;
  showCard?: boolean;
  backgroundColor?: string;
  foregroundColor?: string;
  iterations?: number;
  speed?: number;
  marginTop?: string;
  marginBottom?: string;
}

const SkeletonLoader: FC<SkeletonLoader & RouteComponentProps> = (props) => {
  const { backgroundColor, foregroundColor, speed, showHeader, showCard, title, iterations, type, history, marginTop, marginBottom } = props;
  const targetRef: any = useRef();

  const [ width, setWidth ] = useState(0);

  const { windowWidth } = useWindowWidth(width);

  useLayoutEffect(() => {
    if (targetRef.current) {
      setWidth(targetRef.current.offsetWidth*0.96);
    }
  }, [setWidth, targetRef, windowWidth]);

  const getLoader = () => {
    switch(type) {
      case 'list':
        return <ListLoader width={width} speed={speed || 0} foregroundColor={foregroundColor || ''} backgroundColor={backgroundColor || ''} iterations={iterations || 0}/>;
      case 'field':
        return <FieldLoader width={width} speed={speed || 0} foregroundColor={foregroundColor || ''} backgroundColor={backgroundColor || ''} iterations={iterations || 1}/>;

    }
  };

  const Content = () => {
    return (
      <CenteredColumn ref={targetRef}>
        { showHeader && (
          <>
            <CardTitle>
              <BackButton onClick={() => history.goBack()}>
                <Back />
              </BackButton>
              { title }
            </CardTitle>
            <Divider />
          </>
        )}
        <LoaderContainer marginTop={marginTop} marginBottom={marginBottom}>
          {getLoader()}
        </LoaderContainer>
      </CenteredColumn>
    )
  };

  return (
    <Page>
      { showCard ? <Card><Content /></Card> : <Content /> }
    </Page>
  );
};

SkeletonLoader.defaultProps = {
  backgroundColor: '#f3f3f3',
  foregroundColor: '#e6f7fe',
  speed: 2,
  iterations: 2,
  title: 'Go Back',
  showHeader: true,
  showCard: true,
  type: 'list',
};

export default withRouter(SkeletonLoader);
