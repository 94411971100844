import React, { useState, useRef } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Button, ButtonGroup, ClickAwayListener, Grid as G, Grow, Paper, Popper as P, MenuItem, MenuList } from '@material-ui/core';
import styled from 'styled-components';

const Popper = styled(P)`
  z-index: 150;
`;

const Grid = styled(G)`
  width: 100%;
`;

const Buttons = styled(ButtonGroup)`
  width: 100%;
`;

interface Options {
  index: number;
  text: string;
  button: React.ReactElement;
}

interface Props {
  options: Array<Options>;
}

const MultiButton: React.FC<Props> = ({ options }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(options[0].index);

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <Buttons variant="contained" color="primary" ref={anchorRef} aria-label="split button">
          {options[selectedIndex].button}
          <Button
            color="primary"
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select button action"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </Buttons>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem key={option.index} selected={index === selectedIndex} onClick={event => handleMenuItemClick(event, index)}>
                        {option.text}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};

export default MultiButton;
