import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Checkbox, Select, MenuItem, InputLabel, CircularProgress, OutlinedInput } from '@material-ui/core'
import { CenterColumn } from '../../global/style'
import { useValues, useRegex } from '../../custom-hooks/formhooks'
import { getRoles } from '../../services/helpers/role-helper'
import { setCurrentUser } from '../../redux/actions'
import { presentSuccess, presentError } from '@necta-tech/alert'
import { useMutation } from 'react-apollo'
import gql from 'graphql-tag'
import { generatePassword } from '../../services/helpers/form-helper'
import { SA_PROVINCES, COUNTRIES } from '../../global/constants';
import { regExName, regExEmail, regExMobile, regExPassword } from '../../global/regex';
import styled from 'styled-components'
import { Paper, TextField, FormControl } from '@material-ui/core'
import Password from '../../components/form/Password'
import { PrimaryButton } from '../../global/style';

const QUERY = gql`
    mutation SIGNUP_QUERY ($email: String!, $password: String!, $contactNumber: String!, 
                           $firstname: String!, $lastname: String!, $locationProvince: String, $locationCity: String,
                           $locationCountry: String, $roles: [Role!]) {
        addLogin(newUser:{
            email: $email
            password: $password
            contactNumber: $contactNumber
            firstname: $firstname
            lastname: $lastname
            locationCountry: $locationCountry
            locationProvince: $locationProvince
            locationCity: $locationCity
            roles: $roles
        }) {
            email,
            id
        }
    }
`;

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

`

export const InfoCard = styled(Paper)`
    && {
        width: 80%;
        margin: 0 auto;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding: 2rem;
    }
`

export const Title = styled.h1`
    width: 100%;
    text-align: left;
`

export const SubTitle = styled.h2`
    width: 100%;
    text-align: left;
`

export const Form = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;

    margin-bottom: 20px;
`

export const PasswordForm = styled(Form)`
    justify-content: center;
    flex-wrap: nowrap;
    width: calc(100% - 1rem);
`

export const InputField = styled(TextField)`
    && {
        width: 45%;
    }
`

export const BoxForm = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;

    margin-bottom: 20px;

    div {
        display: flex;
        flex-direction: row;
    }
`

export const AddButton = styled(PrimaryButton)`
    && {
        margin-right: 1rem;        
        margin-left: 1rem;
    }
`

export const PasswordButton = styled(PrimaryButton)`
    && {
        width: 30%;
    }
`

export const PasswordField = styled(Password)`
    && {
        margin-right: 1rem;
    }
`

export const CheckboxControl = styled(FormControl)`
    && {
        width: 45%;
        margin-top: 15px;
    }
`


//TODO: add type definition

const AddUser = ({currentUser, props: {userData}}: any) => {

    console.log(currentUser)

    const { values, handleOnChange, handleOnKeyPress, handleCheckOnChange, setValue, emptyValues } = useValues({
        email: '',
        password: '',
        contactNumber: '',
        firstname: '',
        lastname: '',
        locationCountry: '',
        locationProvince: '',
        locationCity: '',
        checkPlayer: false,
        checkCoach: false
    });

    const [firstname, lastname, email, contactNumber, password ] = [
        useRegex(regExName, values.firstname),
        useRegex(regExName, values.lastname),
        useRegex(regExEmail, values.email),
        useRegex(regExMobile, values.contactNumber),
        useRegex(regExPassword, values.password),
        useRegex(regExEmail, values.parentalContact),
    ];

    const scrapeUser = () => {
        const { checkPlayer, checkCoach, ...userData } = values
        return {
            ...userData,
            roles: getRoles(checkPlayer, checkCoach, false)
        }
    }

    const [ handleSubmit, { loading } ] = useMutation(QUERY, {
        variables: scrapeUser(),
        onError: error => {
            presentError(error, "Unable to register");
        },
        onCompleted: () => {
            presentSuccess('User uploaded Successfully!', 'Success')
            emptyValues()
        }
    })

    const submit = () => {
        if (!firstname.valid()) {
            presentError("Please enter a first name", "Unable to submit");
            return;
        }
        if (!lastname.valid()) {
            presentError("Please enter a last name", "Unable to submit");
            return;
        }
        if (!email.valid()) {
            presentError("Please enter a valid email", "Unable to submit");
            return;
        }
        if (!contactNumber.valid()) {
            presentError("Please enter a valid contact number", "Unable to submit");
            return;
        }
        if (!password.valid()) {
            presentError("Please enter a valid password", "Unable to submit");
            return;
        }
        handleSubmit();
    }

    return (
        <InfoCard>
            <Title>Add New User</Title>
            <CenterColumn width={80}>
                <SubTitle>Personal Information</SubTitle>
                <Form>
                    <InputField
                        margin="normal"
                        label="First Name"
                        name="firstname"
                        variant="outlined"
                        value={values.firstname}
                        onChange={handleOnChange}
                        onKeyPress={handleOnKeyPress}
                    />
                    <InputField
                        margin="normal"
                        label="Last Name"
                        name="lastname"
                        variant="outlined"
                        value={values.lastname}
                        onChange={handleOnChange}
                        onKeyPress={handleOnKeyPress}
                    />
                    <InputField
                        margin="normal"
                        label="Email"
                        name="email"
                        variant="outlined"
                        value={values.email}
                        onChange={handleOnChange}
                        onKeyPress={handleOnKeyPress}
                    />
                    <InputField
                        margin="normal"
                        label="Number"
                        name="contactNumber"
                        variant="outlined"
                        value={values.contactNumber}
                        onChange={handleOnChange}
                        onKeyPress={handleOnKeyPress}
                    />
                    <CheckboxControl variant="outlined">
                        <InputLabel htmlFor="country-helper">Country</InputLabel>
                        <Select
                            value={values.locationCountry}
                            onChange={handleOnChange}
                            id="country-helper"
                            input={
                                <OutlinedInput
                                    labelWidth={60}
                                    name="locationCountry"
                                />
                            }
                        >
                        {
                            COUNTRIES.map(
                                (element: any, i: number) => {
                                    return <MenuItem value={element}>{element}</MenuItem>
                                }
                            )
                        }
                        </Select>
                    </CheckboxControl>

                    { values.locationCountry === "South Africa" && 

                        <CheckboxControl variant="outlined">
                            <InputLabel htmlFor="province-helper">Province</InputLabel>
                            <Select
                                value={values.locationProvince}
                                onChange={handleOnChange}
                                name='locationProvince'
                                id='province-helper'
                                input={
                                    <OutlinedInput
                                        labelWidth={65}
                                        name="locationProvince"
                                    />
                                }
                            >
                            {
                                SA_PROVINCES.map(
                                    (element: any, i: number) => {
                                        return <MenuItem value={element}>{element}</MenuItem>
                                    }
                                )
                            }
                            </Select>
                        </CheckboxControl>
                    }

                    <InputField
                        margin="normal"
                        label="City"
                        name="locationCity"
                        variant="outlined"
                        value={values.locationCity}
                        onChange={handleOnChange}
                        onKeyPress={handleOnKeyPress}
                    />

                </Form>
                <SubTitle>Account Type</SubTitle>
                <BoxForm>
                    <div>
                        <Checkbox
                            color="primary"
                            checked={values.checkPlayer}
                            onChange={handleCheckOnChange}
                            onClick={handleCheckOnChange}
                            name="checkPlayer"
                        />
                        <h3>Player</h3>
                    </div>
                    <div>
                        <Checkbox
                            color="primary"
                            checked={values.checkCoach}
                            onChange={handleCheckOnChange}
                            onClick={handleCheckOnChange}
                            name="checkCoach"
                        />
                        <h3>Coach</h3>
                    </div>
                    {/* <div>
                        <Checkbox
                            color="primary"
                            checked={values.checkOther}
                            onChange={handleCheckOnChange}
                            onClick={handleCheckOnChange}
                            name="checkOther"
                        />
                        <h3>Other</h3>
                    </div> */}
                </BoxForm>
                <PasswordForm>
                    <PasswordField
                        value={values.password}
                        variant="outlined"
                        onChange={handleOnChange}
                    />
                    <PasswordButton onClick={() => { setValue('password', generatePassword()) }} >
                        generate
                    </PasswordButton>
                </PasswordForm>
                <AddButton onClick={() => { submit() }} disabled={loading}>
                    { loading ? <CircularProgress size={24} color="inherit" /> : 'Add User' }
                </AddButton>
            </CenterColumn>
        </InfoCard>
    )
}

const mapStateToProps = (state: any, props: any) => {
    return {
        cognitoUser: state.cognitoUser,
        currentUser: state.currentUser,
        props
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setCurrentUser: (currentUser: any) => { dispatch(setCurrentUser(currentUser)) }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddUser))