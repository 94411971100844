import styled from 'styled-components';
import Container from '@material-ui/core/Container';
import { PrimaryButton } from '../../global/style';
import Grid from '@material-ui/core/Grid';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

export const CContainer = styled(Container)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    position: relative;
    text-align: center;
    color: white;
  }
  && > h3 {
    text-align: left;
    width: 100%;
  }
  && > p {
    text-align: left;
    width: 100%;
  }
`;

export const CColumn = styled.div`
  flex: 1;
  padding: 0.75rem;
`;

export const CPrimaryButton = styled(PrimaryButton)`
  && {
    margin: 1rem;
    margin-top: 0.5rem;
    max-width: 516px;
  }
`;

export const GridOuter = styled(Grid)`
  flex: 1;
  justify-content: center;
  justify-items: center;
`;

export const Tile = styled(Grid)`
  cursor: pointer;
`;

export const AddTile = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-items: center;
`;

export const Add = styled(AddCircleOutlineIcon)`
  color: white;
  font-size: 8rem !important;
  text-align: center;
`;

export const PfpDiv = styled.div`
  border: 2px dashed rgb(10, 53, 90);
  border-image: none;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
`;

export const DropzoneInner = styled.div`
  height: 100%;
  color: black;
  text-align: center;
  padding: 3rem 2rem;
`;
