import gql from 'graphql-tag';

export const USERS_QUERY = gql`
    query USERS_QUERY ($userQuery: UserQuery!, $queryOptions: BaseQuery) {
        users (userQuery: $userQuery, queryOptions: $queryOptions) {
            firstname
            lastname
            email
            contactNumber
            profilePicUrl
            isPlayer
            isCoach
            dateOfBirth
            locationCountry
            locationProvince
            locationCity
            shortid
            id
            idNumber
            createdAt
            role
            stats {
                bio
                height
                weight
                positionPrimary
                positionSecondary
                levelPlayedSenior
                levelPlayedJunior
                educationSchool
                educationDescription
                accolades
                skills
                injuryHistory
                coachingSpecialization
                coachingQualification
                coachingHighestLevel
                clubCurrent
                team
            }
        }
    }
`;

export const ALL_USERS_QUERY = gql`
    query ALL_USERS_QUERY ($userQuery: UserQuery!, $queryOptions: BaseQuery) {
        allUsers (userQuery: $userQuery, queryOptions: $queryOptions) {
            firstname
            lastname
            email
            contactNumber
            profilePicUrl
            isPlayer
            isCoach
            dateOfBirth
            locationCountry
            locationProvince
            locationCity
            shortid
            id
            idNumber
            createdAt
            role
            stats {
                bio
                height
                weight
                positionPrimary
                positionSecondary
                levelPlayedSenior
                levelPlayedJunior
                educationSchool
                educationDescription
                accolades
                skills
                injuryHistory
                coachingSpecialization
                coachingQualification
                coachingHighestLevel
                clubCurrent
                team
            }
        }
    }
`;

export const MANAGERS_QUERY = gql`
    query MANAGERS_QUERY ($userQuery: UserQuery!, $queryOptions: BaseQuery) {
        managers (userQuery: $userQuery, queryOptions: $queryOptions) {
            firstname
            lastname
            email
            contactNumber
            profilePicUrl
            isPlayer
            isCoach
            dateOfBirth
            locationCountry
            locationProvince
            locationCity
            shortid
            id
            idNumber
            createdAt
            stats {
                bio
                height
                weight
                positionPrimary
                positionSecondary
                levelPlayedSenior
                levelPlayedJunior
                educationSchool
                educationDescription
                accolades
                skills
                injuryHistory
                coachingSpecialization
                coachingQualification
                coachingHighestLevel
                clubCurrent
                team
            }
        }
    }
`;

export const DELETE_USER_QUERY = gql`
    mutation DELETE_USER_QUERY ($id: String!) {
        removeUser (id: $id)
    }
`;

export const NEW_USER_MUTATION = gql`
    mutation NEW_USER($newUser: NewAdminAddUserInput!) {
        adminAddUser(newUser: $newUser) {
            id
            firstname
            lastname
            email
            stats {
                bio
            }
        }
    }
`;

export const NEW_MANAGER_MUTATION = gql`
    mutation NEW_MANAGER($newUser: NewAdminAddUserInput!) {
        adminAddUser(newUser: $newUser) {
            id
        }
    }
`;

// TODO: Change mutation if needed
export const INVITE_USERS_MUTATION = gql`
    mutation INVITE_USERS_MUTATION($newInvitation: InviteOrgUserInput!) {
        inviteUsers(newInvitation: $newInvitation) {
            id
        }
    }
`;

export const IMPORT_USERS_MUTATION = gql`
    mutation IMPORT_USERS_MUTATION($users: [NewAdminAddUserInput!]!, $sendWelcome: Boolean!, $organisationId: String!) {
        importUsers(users: $users, sendWelcome: $sendWelcome, organisationId: $organisationId)
    }
`;

export const PROSPECTS_PLAYERS = gql`
    query PROSPECTS_PLAYERS($id: String!) {
        savesByOrgId(id: $id) {
            firstname
            lastname
            email
            contactNumber
            profilePicUrl
            isPlayer
            isCoach
            dateOfBirth
            locationCountry
            locationProvince
            locationCity
            shortid
            id
            idNumber
            createdAt
            stats {
                bio
                height
                weight
                positionPrimary
                positionSecondary
                levelPlayedSenior
                levelPlayedJunior
                educationSchool
                educationDescription
                accolades
                skills
                injuryHistory
                coachingSpecialization
                coachingQualification
                coachingHighestLevel
                clubCurrent
                team
            }
        }
    }
`;

export const FIND_USERS_QUERY = gql`
    query FIND_USERS_QUERY ($shortids: [String!]!) {
        findByCvLinks (shortids: $shortids) {
            firstname
            lastname
            profilePicUrl
            isPlayer
            isCoach
            locationProvince
            shortid
            id
            stats {
                positionPrimary
                coachingSpecialization
            }
        }
    }
`;

export const UPDATE_USER = gql`
    mutation UPDATE_USER_MUTATION($id: String!, $user: UpdateUserInput!) {
        adminUpdateUser(id: $id, user: $user) {
            firstname
            lastname
            email
            contactNumber
            profilePicUrl
            dateOfBirth
            gender
            ethnicity
            locationAddress
            locationCountry
            locationProvince
            locationCity
            shortid
            id
            idNumber
            stats {
                bio
                height
                weight
                positionPrimary
                positionSecondary
                levelPlayedSenior
                levelPlayedJunior
                educationSchool
                educationDescription
                accolades
                skills
                injuryHistory
                coachingSpecialization
                coachingQualification
                coachingHighestLevel
                clubCurrent
                team
            }
            isPlayer
            isCoach
            isClub
        }
    }
`;

export const GET_USER = gql`
    query GET_USER_QUERY($shortid: String!) {
        cvData(shortid: $shortid) {
            firstname
            lastname
            email
            contactNumber
            profilePicUrl
            dateOfBirth
            gender
            ethnicity
            locationAddress
            locationCountry
            locationProvince
            locationCity
            shortid
            id
            idNumber
            stats {
                bio
                height
                weight
                positionPrimary
                positionSecondary
                levelPlayedSenior
                levelPlayedJunior
                educationSchool
                educationDescription
                accolades
                skills
                injuryHistory
                coachingSpecialization
                coachingQualification
                coachingHighestLevel
                clubCurrent
                team
            }
            isPlayer
            isCoach
            isClub
        }
    }
`;
