import { actions } from '../actions';
/*
When a store is made with createStore(exampleReducer),
Any dispatch calls to said store will pass its action token to this function
tokens have the form {type,...args}
each token has a type field which determines what action it corresponds with, along with any other information needed
In the case of ToggleUser the token is generated when an event occurs which changes the user from active to inactive or vice versa
it contains the type field TOGGLE_USER, defined in Actions as well as the id of the user that was toggled which is then used to find the correct user in the current state and flip their active status

You can see the structure of all tokens in Actions.tsx
*/

/*
    When writing new reducer functions, reference https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns#updating-nested-objects
    reselect uses reference comparison to determine when the store changes, which requires returning new versions of all levels for a nested update
*/
const rootReducer = (state = defaultStoreState, action : any) => {

    switch (action.type) {

        case actions.SET_COGNITO_USER:
            return { ...state, cognitoUser: action.user};

        case actions.SET_LOGOUT_PANE_ACTIVE:
            return { ...state, logoutPaneActive: action.paneActive }

        case actions.SET_IN_SESSION:
            return { ...state, cognitoUser: { ...state.cognitoUser, inSession: action.inSession } }

        case actions.SET_CURRENT_USER:
            return { ...state, currentUser: action.currentUser }
            
        case actions.SET_PAGE_TITLE:
            return { ...state, pageTitle: action.pageTitle };
        
        case actions.SET_REDIRECT_ON_LOGIN:
            return { ...state, redirectOnLogin: action.path };
    }

    return state;
}

export const defaultStoreState = {
   cognitoUser: { inSession: false, id: null },
   logoutPaneActive: false,
   currentUser: null,
   redirectOnLogin: '/',
};

export default rootReducer