import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

interface AutoSaveProps {
  debounce: number
}

const AutoSave = ({ debounce }: AutoSaveProps) => {
  const formik = useFormikContext();

  useEffect(() => {
    setTimeout(() => formik.submitForm(), debounce)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce, formik.values]);

  return null;
};

AutoSave.defaultProps = {
  debounce: 100
};

export default AutoSave;
