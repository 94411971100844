import React from 'react'
import { setLogoutPaneActive, setCognitoUser } from '../../redux/actions'
import { PrimaryButton, DangerButton } from '../../global/style'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logout } from '@necta-tech/cognito';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components'
import { useLogout } from '../../custom-hooks'

interface ContainerProps {
    active: boolean
}

const Container = styled.div<ContainerProps>`

    position: fixed;
    top: 0px;
    left: 0px;

    z-index: 9000;

    ${ props => !props.active
        ? 'display: none;'
        : `display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;`
    }


    width: 100vw;
    height: 100vh;

    background-color: rgb(0,0,0, 0.5);

`;

const LogoutButton = styled(PrimaryButton)`
    && {
        width: 100px;
        height: 40px;
        margin: 0.3rem;
    }
`;

const CancelButton = styled(DangerButton)`
    && {
        width: 100px;
        height: 40px;
        margin: 0.3rem;
    }
`;

const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items : center;
`;

const IconContainer = styled.div`
`;

const ButtonContainer = styled.div`
`;

const Lock = styled(LockOutlinedIcon)`
    color: white;
    font-size: 12rem !important;
    padding: 1rem;
    margin-bottom: 1rem;
`;

const LogoutPane = ({logoutPaneActive, setLogoutPaneActive, setCognitoUser, props}: any) => {

    const [handleLogout, { loading }] = useLogout()

    const close = () => setLogoutPaneActive(false)

    const onLogout = () => setLogoutPaneActive(false)

    return (
        <Container active={logoutPaneActive}>
            <FlexContainer>
                <IconContainer>
                    <Lock />
                </IconContainer>
                <ButtonContainer>
                    <CancelButton onClick={close}>
                        Cancel
                    </CancelButton>
                    <LogoutButton onClick={() => handleLogout(onLogout)} >
                        { loading ? <CircularProgress size={24} color="inherit" /> : 'Log out' }
                    </LogoutButton>                
                </ButtonContainer>

            </FlexContainer>
        </Container>
    )
}


const mapStateToProps = (state: any, props: any) => {
    return {
        logoutPaneActive: state.logoutPaneActive,
        props
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setLogoutPaneActive: (paneActive: boolean) => { dispatch(setLogoutPaneActive(paneActive)) },
        setCognitoUser: (user: any) => { dispatch(setCognitoUser(user)) }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LogoutPane))
