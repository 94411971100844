import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import { presentSuccess, presentError } from '@necta-tech/alert';
import { useMutation } from 'react-apollo';
import { KeyboardArrowLeft as Back, ExpandMore, ExpandLess } from '@material-ui/icons';
import { getThumbnail } from '@necta-tech/s3';
import { Formik, Field, FormikValues, FormikHelpers } from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Dropzone from 'react-dropzone';
import { setCurrentUser } from '../../redux/actions';
import { NEW_USER_MUTATION } from '../../global/gql/users';
import FileUpload from '../../components/file-upload/FileUpload';
import {
  PasswordField,
  SelectField,
  TextField as FormikTextField,
  getFormData,
  generatePassword,
  LocationPicker
} from '../../services/helpers/form-helper';
import {
  Page,
  CenteredColumn,
  Form,
  CardTitle,
  Divider,
  Required,
  SubTitle,
  Card,
  BackButton,
  PrimaryButton,
  Collapser,
  Collapsable,
  Collapse,
  ImageContainer,
  ImageClick,
  DropzoneContainer,
  DropzoneInner,
  Highlight
} from '../../global/style';
import { useExpanded } from '../../custom-hooks/formhooks';
import { mapArrayToObjects } from '../../global/helpers/data-maps';
import { COUNTRIES, POSITIONS, SA_PROVINCES, TEAMS } from '../../global/constants';
import OrgsDropdown from '../../components/organisation/OrgDropDown';
import { getIsAdmin } from '../../global/helpers/selectors';
import playerSchema from '../player/schema';

const fullSchema = playerSchema.shape({
  dateOfBirth: yup.string().nullable().label('Date of Birth'),
  addToOrg: yup.string().nullable().label('Organisation'),
});

const MySwal = withReactContent(Swal);

const countries = mapArrayToObjects(COUNTRIES);
const positions = mapArrayToObjects(POSITIONS);
const provinces = mapArrayToObjects(SA_PROVINCES);
const teams = mapArrayToObjects(TEAMS);

const { schema, fields, initialValues } = getFormData(fullSchema);

const TextField = styled(FormikTextField)`
  && {
    width: 100%;
  }
`;

const CreateButton = styled(PrimaryButton)`
  && {
    height: 56px;
    width: 100%;
    border-radius: 0;
  }
`;

const CoachAdd : React.FC<any> = ({ cognitoUser, isAdmin, props }) => {
  const [defaultValues] = useState<any>({ ...initialValues });

  const [addCoach, { loading }] = useMutation(NEW_USER_MUTATION);

  const { expanded, handleOnExpandedClick } = useExpanded({
    stats: false,
    location: false,
    access: false,
    coachSpec: false
  });

  const onSubmit = async (values: FormikValues, actions: FormikHelpers<any>) => {
    actions.setSubmitting(true);
    try {
      const user = { ...values, isPlayer: true, isCoach: false, isClub: false, role: 'User' };
      const { id, ...newUser } = user as any;
      await addCoach({ variables: { newUser } });
      presentSuccess('Coach added Successfully!', 'Success');
      actions.resetForm(initialValues);
    } catch (e) {
      presentError(e, 'Unable to add coach!');
    } finally {
      actions.setSubmitting(false);
    }
  };

  const imageUploaded = (setFieldValue: any) => (key: string) => {
    setFieldValue('profilePicUrl', key);
    MySwal.close();
  };

  const uploadImage = (setFieldValue: any, defaultFiles?: File[]) => {
    MySwal.fire({
      title: 'Upload Profile Picture',
      html: <FileUpload path="profilePictures" id={cognitoUser.username} type="image" single={true} onComplete={imageUploaded(setFieldValue)} defaultFile={defaultFiles} />,
      width: '800px',
      showConfirmButton: false,
      showCloseButton: true
    });
  };

  return (
    <Page>
      <Card>
        <CenteredColumn>
          <Formik initialValues={defaultValues} validateOnBlur validationSchema={schema} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
              <>
                <CardTitle>
                  <BackButton onClick={() => props.history.goBack()}>
                    <Back />
                  </BackButton>
                  Add Coach
                </CardTitle>
                <Divider />
                <Required>
                  <SubTitle>Coach Information</SubTitle>
                  <h3>*required</h3>
                </Required>
                <Form>
                  <ImageContainer>
                        <img onClick={() => uploadImage(setFieldValue)} alt="profile" src={!values.profilePicUrl ? require('../../assets/img/default-org.png') : getThumbnail(values.profilePicUrl)} />
                    <ImageClick>
                      <Dropzone onDrop={acceptedFiles => uploadImage(setFieldValue, acceptedFiles)} disabled={loading}>
                        {({ getRootProps, getInputProps }) => (
                          <DropzoneContainer {...getRootProps()}>
                            <input {...getInputProps()} />
                            <DropzoneInner>
                              <h4>Profile Picture<br />
                              Drag and Drop or <Highlight>Click</Highlight> to search</h4>
                            </DropzoneInner>
                          </DropzoneContainer>
                        )}
                      </Dropzone>
                    </ImageClick>
                  </ImageContainer>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Field {...fields.firstname} component={TextField} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field {...fields.lastname} component={TextField} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field {...fields.email} component={TextField} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field {...fields.contactNumber} component={TextField} />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Field {...fields.password} component={PasswordField} />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <PrimaryButton onClick={() => {
                            setFieldValue('password', generatePassword())
                        }}>
                        Generate
                    </PrimaryButton>
                    </Grid>
                  </Grid>

                </Form>

                <Divider />
                <Collapser onClick={(e: any) => handleOnExpandedClick(e, 'location')}>
                  <Required>
                    <SubTitle>Location</SubTitle>
                    {expanded.location ? <ExpandLess /> : <ExpandMore />}
                  </Required>
                </Collapser>
                <Collapse in={expanded.location}>
                  <Collapsable>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Field {...fields.locationAddress} component={LocationPicker} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field {...fields.locationCountry} component={SelectField} options={countries} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field {...fields.locationProvince} component={SelectField} options={provinces} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field {...fields.locationCity} component={TextField} />
                      </Grid>
                    </Grid>
                  </Collapsable>
                </Collapse>
                <Divider />
                { isAdmin && (
                  <>
                    <Collapser onClick={(e: any) => handleOnExpandedClick(e, 'access')}>
                      <Required>
                        <SubTitle>Access&nbsp;<span>*</span></SubTitle>
                        {expanded.security ? <ExpandLess /> : <ExpandMore />}
                      </Required>
                    </Collapser>
                    <Collapse in={expanded.access}>
                      <Collapsable>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <OrgsDropdown Render={Field} field={fields.addToOrg} />
                          </Grid>
                        </Grid>
                      </Collapsable>
                    </Collapse>
                    <Divider />
                  </>
                )}

                <Collapser onClick={(e: any) => handleOnExpandedClick(e, 'stats')}>
                  <Required>
                    <SubTitle>Stats</SubTitle>
                    {expanded.stats ? <ExpandLess /> : <ExpandMore />}
                  </Required>
                </Collapser>
                <Collapse in={expanded.stats}>
                  <Collapsable>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <Field {...fields.stats.bio} component={TextField} multiline={true} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field {...fields.stats.height} component={TextField} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field {...fields.stats.weight} component={TextField} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field {...fields.stats.positionPrimary} component={SelectField} options={positions} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field {...fields.stats.positionSecondary} component={SelectField} options={positions} />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Field {...fields.stats.team} component={SelectField} options={teams} />
                      </Grid>
                    </Grid>
                  </Collapsable>
                </Collapse>
                <Divider />

                <Collapser onClick={(e: any) => handleOnExpandedClick(e, 'coachSpec')}>
                  <Required>
                    <SubTitle>Coach Specifics</SubTitle>
                    {expanded.coachSpec ? <ExpandLess /> : <ExpandMore />}
                  </Required>
                </Collapser>
                <Collapse in={expanded.coachSpec}>
                  <Collapsable>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Field {...fields.stats.coachingSpecialization} component={TextField} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field {...fields.stats.coachingQualification} component={TextField} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field {...fields.stats.coachingHighestLevel} component={TextField} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field {...fields.stats.clubCurrent} component={TextField} />
                      </Grid>
                    </Grid>
                  </Collapsable>
                </Collapse>

                <CreateButton disabled={loading || isSubmitting} onClick={() => handleSubmit()}>
                  {loading || isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Add Coach'}
                </CreateButton>
              </>

            )}
          </Formik>
        </CenteredColumn>
      </Card>
    </Page>
  );
};

const mapStateToProps = (state: any, props: any) => {
  return {
    cognitoUser: state.cognitoUser,
    currentUser: state.currentUser,
    isAdmin: getIsAdmin(state),
    props
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setCurrentUser: (currentUser: any) => {
      dispatch(setCurrentUser(currentUser));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CoachAdd)
);
