import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useLazyQuery, useMutation } from 'react-apollo';
import MaterialTable, { Column } from 'material-table';
import { presentSuccess, presentError } from '@necta-tech/alert';
import { Block, Edit, Delete, Cached } from '@material-ui/icons';
import { findGeneric, findAge, getAge, getDateFull } from '../../global/helpers/table-helper';
import { USERS_QUERY, DELETE_USER_QUERY } from '../../global/gql/users';
import { mapArrayToEnum } from '../../global/helpers/data-maps';
import DetailPanel from '../user/DetailPanel';
import { getActiveOrgId, getIsAdmin, getIsCurrentOrgOwner } from '../../global/helpers/selectors';
import { POSITIONS, SA_PROVINCES, TEAMS } from '../../global/constants';
import { TableContainer, TableOuter } from '../../global/style';
import Swal from 'sweetalert2';
import { UPDATE_ORG_USER } from '../../global/gql/orgAccess';
import { getThumbnail } from '@necta-tech/s3';
import AccountProgress from '../../components/account-progress/AccountProgress';
import { get } from 'lodash';

const ageGroups = { '0-9': 'Under 9', '10-11': 'Under 11', '12-13': 'Under 13', '14': 'Under 14', '15': 'Under 15', '16': 'Under 16', '17-18': 'Under 18', '19': 'Under 19', '20-21': 'Under 21','22-999': '22+' };
const provinces = mapArrayToEnum(SA_PROVINCES);
const positions = mapArrayToEnum(POSITIONS);
const teams = mapArrayToEnum(TEAMS);

const columns: Column<any>[] = [
  {
    field: 'profilePicUrl',
    title: 'Picture',
    filtering: false,
    editable: 'never',
    grouping: false,
    render: rowData => (
      <>
        {rowData && (
          <img
            // onClick={(event: any) => uploadImage(event, rowData)}
            src={!rowData.profilePicUrl ? require('../../assets/img/default-profile.png') : getThumbnail(rowData.profilePicUrl)}
            style={{ width: 50, borderRadius: '50%' }}
            alt="Profile Pic"
          />
        )}
      </>
    )
  },
  {
    title: 'First Name',
    field: 'firstname'
  }, {
    title: 'Surname',
    field: 'lastname'
  }, {
    title: 'Email',
    field: 'email',
    hidden: false,
    filtering: true,
    grouping: false,
  }, {
    title: 'Cell',
    field: 'contactNumber',
    hidden: true,
    filtering: true,
    grouping: false,
  }, {
    title: 'Shortid',
    field: 'shortid',
    hidden: true,
    filtering: true,
    grouping: false,
  }, {
    title: 'Database ID',
    field: 'id',
    hidden: true,
    filtering: false,
    grouping: false,
    editable: 'never'
  }, {
    title: 'Primary Position',
    field: 'stats.positionPrimary',
    lookup: positions,
    customFilterAndSearch: (term: string[], r: any) => (r && r.stats) ? findGeneric(term, r.stats.positionPrimary) : false
  }, {
    title: 'Age',
    field: 'dateOfBirth',
    type: 'numeric',
    editable: "never",
    lookup: ageGroups,
    grouping: false,
    render: (r: any) => r ? getAge(r.dateOfBirth): '',
    customFilterAndSearch: (term: string[], r: any) => r ? findAge(term, r.dateOfBirth) : false
  }, {
    title: 'Province',
    field: 'locationProvince',
    lookup: provinces,
    customFilterAndSearch: (term: string[], r: any) => r ? findGeneric(term, r.locationProvince) : false
  }, {
    title: 'Team',
    field: 'stats.team',
    lookup: teams,
    customFilterAndSearch: (term: string[], r: any) => r ? findGeneric(term, get(r, 'stats.team')) : false
  }, {
    title: 'Profile completion',
    field: 'profileComplete',
    filtering: false,
    sorting: false,
    editable: 'never',
    render: (r: any) => <AccountProgress user={r} />
  }, {
    title: 'Signed Up',
    field: 'createdAt',
    filtering: false,
    sorting: true,
    grouping: false,
    editable: 'never',
    render: (r: any) => r ? getDateFull(r.createdAt): '',
  }
];

const PlayerList : React.FC<any> = ({ cognitoUser, currentUser, activeOrgId, isOwner, isAdmin, history, props }) => {
  const [players, setPlayers] = useState<any[]>([]);

  const [fetchPlayers, { loading, data }] = useLazyQuery(USERS_QUERY, {
    variables: { userQuery: { isCoach: false } },
    fetchPolicy: 'network-only',
    onError: (error: any) => {
      console.log(error)
    }
  });

  const [handleDelete, { loading: isLoading }] = useMutation(DELETE_USER_QUERY, {
    onError: error => {
      presentError(error, "Unable to delete");
    }
  });

  const [handleRemoveFromOrg, { loading: isRemoveLoading }] = useMutation(UPDATE_ORG_USER, {
    onError: error => {
      presentError(error, "Unable to remove player");
    }
  });

  const deleteUser = useCallback(async (e: any, row: any) => {
    if (!isAdmin) {
      return
    }
    if (row.id === cognitoUser.username) {
      presentError('You may not delete yourself');
      return;
    }
    const confirm = await Swal.fire({
      title: 'Delete user',
      text: 'This will permanently delete the user',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete'
    });
    if (!confirm.value) {
      return;
    }
    const res = await handleDelete({ variables: { id: row.id } });
    if (res) { // Present success
      presentSuccess("User deleted successfully", "Success", true);
      const filtered = players.filter((d: any) => d.id !== row.id);
      setPlayers(filtered);
    }
  }, [setPlayers, players, handleDelete, isAdmin, cognitoUser.username]);

  const removeUserFromOrg = useCallback(async (e: any, row: any) => {
    const confirm = await Swal.fire({
      title: 'Remove user',
      text: 'This will remove this player from your organisation',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Remove'
    });
    if (!confirm.value) {
      return;
    }
    const res = await handleRemoveFromOrg({ variables: { id: activeOrgId, updateOrgAccess: { archived: true } } });
    if (res) { // Present success
      presentSuccess("Player removed from organisation", "Success", true);
      const filtered = players.filter((d: any) => d.id !== row.id);
      setPlayers(filtered);
    }
  }, [setPlayers, players, handleRemoveFromOrg, activeOrgId]);

  const editUser = useCallback(async (e: any, row: any) => {
    history.push('/users/edit/' + row.shortid);
  }, [history]);

  useEffect(() => {
    if (data && data.users) {
      setPlayers(data.users);
    } else {
      fetchPlayers();
    }
  }, [fetchPlayers, data]);

  const detailPanel: any = (rowData: any) => {
    return <DetailPanel rowData={rowData} />;
  };

  return (
    <TableContainer>
      <TableOuter onContextMenu={e => console.log('do something')}>
        <MaterialTable
          title="Player List"
          columns={columns}
          detailPanel={detailPanel}
          options={{
            emptyRowsWhenPaging: false,
            columnsButton: true,
            pageSize: 10,
            filtering: true,
            exportButton: true,
            grouping: true
          }}
          isLoading={loading || isLoading || isRemoveLoading}
          data={players}
          actions={[
            {
              tooltip: 'Edit',
              icon: () => <Edit />,
              onClick: editUser,
            },
            {
              tooltip: 'Delete user',
              icon: () => <Delete />,
              onClick: deleteUser,
              hidden: !isAdmin
            },
            {
              tooltip: 'Remove from organisation',
              icon: () => <Block />,
              onClick: removeUserFromOrg,
              hidden: !isOwner
            },
            {
              tooltip: 'Refresh',
              icon: () => <Cached />,
              onClick: fetchPlayers,
              isFreeAction: true
            }
          ]}
        />
      </TableOuter>
    </TableContainer>
  )
};

const mapStateToProps = (state: any, props: any) => {
  return {
    cognitoUser: state.cognitoUser,
    currentUser: state.currentUser,
    isOwner: getIsCurrentOrgOwner(state),
    isAdmin: getIsAdmin(state),
    activeOrgId: getActiveOrgId(state),
    ...props,
  }
};

const mapDispatchToProps = (dispatch: Function) => {
  return {}
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayerList));
