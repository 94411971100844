import { useSelector, useDispatch } from 'react-redux';
import { setCognitoUser } from '../../../redux/actions';

export const useCognitoUser = () => {
  const dispatch = useDispatch()
  const cognitoUser = useSelector((state: any) => state.cognitoUser)

  const setUser = (user: any) => {
    dispatch(setCognitoUser(user))
  }

  return [
    cognitoUser, 
    setUser
  ] as const;
}

export default useCognitoUser