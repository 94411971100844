import React, { FC, useCallback, useState } from 'react';
import { AccountCircle, Camera, CheckCircleOutline, Description } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import styled from 'styled-components';

// const FINAL_HEADING = 'Account complete';
const FINAL_DESCRIPTION = 'Account is fully complete';

const Progress = styled.div<{ progress: number }>`
  cursor: pointer;
  font-size: 16px;
  color: ${p => {
  if (p.progress >= 100) return p.theme.success;
  else if (p.progress >= 50) return p.theme.warning;
  else return p.theme.danger;
}}
`;

const MARKERS = [
  { criteria: [], statsCriteria: [], weight: 25, heading: '', desc: []},
  { criteria: ['profilePicUrl'], statsCriteria: [], weight: 25, heading: 'Signed up', desc: ['profile picture'], icon: <Camera />},
  { criteria: [], player: true, coach: false, statsCriteria: ['positionPrimary', 'height', 'weight'], weight: 25, heading: 'Player Stats', desc: ['primary position', 'height', 'weight'], icon: <AccountCircle />},
  { criteria: [], statsCriteria: ['bio'], weight: 25, heading: 'Hero Yourself', desc: ['bio'], icon: <Description />},
  // { criteria: ['images'], statsCriteria: [], weight: 20, heading: 'Player Action', desc: ['images'], icon: <BurstMode />}
];

const calculateProgress = (user: any) => {
  const failedMarkers = [];
  for (const m of MARKERS) {
    let flag = true;
    if ((m.player == null && m.coach == null) || m.player && user.isPlayer || m.coach && user.isCoach) {
      for (const c of m.criteria) {
        if (user[c] == null || user[c] === '') {
          flag = false;
        }
      }
      for (const s of m.statsCriteria) {
        if (!user.stats || user.stats[s] == null || user.stats[s] === '') {
          flag = false;
        }
      }
    }
    if (!flag) {
      failedMarkers.push(m);
    }
  }

  const progress: number = failedMarkers.reduce((total: number, f: any) => {
    return total - f.weight;
  }, 100);

  return {
    failed: failedMarkers,
    progress
  };
};

interface AccountProgressProps {
  user: any;
}

const AccountProgress: FC<AccountProgressProps> = (props) => {
  const { user } = props;

  const [values, setValues] = useState<any>(calculateProgress(user));

  // For if we want a larger and more descriptive component later
  const getIcon = useCallback(() => {
    if (values.progress >= 100) {
      return <CheckCircleOutline />
    }
    return values.failed.length > 0 ? values.failed[0].icon : <CheckCircleOutline />;
  }, [values]);

  const getDesc = useCallback(() => {
    if (values.failed.length === 0) return FINAL_DESCRIPTION;
    const missing: string[] = values.failed.map((v: any) => v.desc);
    if (missing.length > 1) {
      const lastIndex = missing.pop();
      return 'Account still needs: ' + missing.join(', ') + ' and ' + lastIndex;
    }
    return 'Account still needs: ' + missing[0];
  }, [values]);

  return (
    <Tooltip title={getDesc()}>
      <Progress progress={values.progress}>{values.progress} %</Progress>
    </Tooltip>
  );
};

AccountProgress.defaultProps = {};

export default AccountProgress;
