import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useLazyQuery, useMutation } from 'react-apollo';
import MaterialTable, { Column, MTableToolbar } from 'material-table';
import { presentSuccess, presentError } from '@necta-tech/alert';
import { Paper, Select, MenuItem } from '@material-ui/core';
import { findGeneric } from '../../global/helpers/table-helper';
import { ORGS_QUERY, UPDATE_ORG_QUERY } from '../../global/gql/organisations';
import { SelectOuter } from '../../global/style';
import DetailPanel from './DetailPanel';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: #dfdfdf;
    padding: 2rem;
`;

const TableOuter = styled(Paper)`
    overflow-x: scroll;
`;

const provinces = {
    'Eastern Cape': 'Eastern Cape',
    'Free State': 'Free State',
    'Gauteng': 'Gauteng',
    'KwaZulu-Natal': 'KwaZulu-Natal',
    'Limpopo': 'Limpopo',
    'Mpumalanga': 'Mpumalanga',
    'Northern Cape': 'Northern Cape',
    'North West': 'North West',
    'Western Cape': 'Western Cape'
}

export const removeNulls = (obj: any): any => {
    Object.keys(obj).forEach(key => obj[key] == null && delete obj[key]);
    return obj;
}

export const OrganisationList = ({props}: any) => {

    const [orgs, setOrgs] = useState<any>({ archived: [], current: [], all: [] });
    const [archiveLoading, setArchiveLoading] = useState<boolean>(false);
    const [status, setStatus] = useState('current');

    const [fetchOrgs, { loading, data }] = useLazyQuery(ORGS_QUERY, {
        variables: { organisationQuery: {} },
        fetchPolicy: 'network-only',
        onError: (error: any) => {
            console.log(error)
        }
    })

    const [handleSubmit] = useMutation(UPDATE_ORG_QUERY, {
        onError: error => {
            presentError(error, "Unable to update");
            setArchiveLoading(false);
        }
    });

    const handleStatusChange = (e: any) => {
      setStatus(e.target.value);
    };

    const columns: Column<any>[] = [{
        title: 'Name',
        field: 'name'
    }, {
        title: 'Primary Contact',
        field: 'primaryContactName',
        filtering: true,
    }, {
        title: 'Email',
        field: 'primaryContactEmail',
        filtering: true,
    }, {
        title: 'Cell',
        field: 'primaryContactCell',
        filtering: true,
    }, {
        title: 'Database ID',
        field: 'id',
        hidden: true,
        filtering: false,
        editable: 'never'
    }, {
        title: 'Province',
        field: 'locationProvince',
        lookup: provinces,
        customFilterAndSearch: (term: string[], r: any) => r ? findGeneric(term, r.locationProvince) : false
    }];

    const filterArchived = (data: any[]) => {
      const archived: any[] = [],
        current: any[] = [];
      data.reduce((oldData: any[], currentData: any) => {
        if (currentData.archived) {
          archived.push(currentData);
        } else {
          current.push(currentData);
        }
        return [...oldData];
      }, []);
      setOrgs({ archived, current, all: data });
    };

    const getArchive = (status: boolean) => {
      return (status ? 'un' : '') + 'archive';
    };

    const toggleArchived = (rowData: any) => {
        if (!window.confirm(`Are you sure you want to ${getArchive(rowData.archived)} ${rowData.name}?`)) {
          return;
        }
        setArchiveLoading(true);
        handleSubmit({
            variables: { id: rowData.id, updateOrganisation: { archived: !rowData.archived } },
            update: (cache: any, response: any) => {
                presentSuccess('Organisation ' + getArchive(rowData.archived) + 'd successfully', 'Success', true);
                const found: any = orgs.all.find((d: any) => d.id === rowData.id);
                if (found) {
                    found.archived = !rowData.archived;
                    filterArchived(orgs.all);
                }
                setArchiveLoading(false);
            }
        });
    };

    useEffect(() => {
        if (data && data.organisations) {
            filterArchived(data.organisations);
        } else {
            fetchOrgs();
        }
    }, [loading, data, fetchOrgs])

    const detailPanel: any = (rowData: any) => {
        return <DetailPanel organisation={rowData} />;
    }

    return (
        <Container>
            <TableOuter onContextMenu={e => console.log('do something')}>
                <MaterialTable
                    title="Organisation List"
                    columns={columns}
                    detailPanel={detailPanel}
                    options={{
                        emptyRowsWhenPaging: false,
                        columnsButton: true,
                        pageSize: 10,
                        filtering: true,
                        exportButton: true
                    }}
                    components={{
                        Toolbar: props => (
                          <div>
                            <MTableToolbar {...props} />
                            <div style={{ padding: '10px' }}>
                              <SelectOuter>
                                <Select id="archived-selection" variant="outlined" value={status} displayEmpty onChange={handleStatusChange}>
                                  <MenuItem value="current">Current</MenuItem>
                                  <MenuItem value="archived">Archived</MenuItem>
                                </Select>
                              </SelectOuter>
                            </div>
                          </div>
                        )
                    }}
                    actions={[
                        {
                          tooltip: status === 'current' ? 'Archive' : 'Unarchive',
                          icon: status === 'current' ? 'archive' : 'unarchive',
                          onClick: (evt, data) => toggleArchived(data)
                        },
                        {
                          tooltip: 'Edit',
                          icon: 'edit',
                          onClick: (evt, data) => props.history.push('/org/edit/' + data.id)
                        },
                    ]}
                    isLoading={loading || archiveLoading}
                    data={status === 'current' ? orgs.current : orgs.archived}
                />
            </TableOuter>
        </Container>
    )
}

const mapStateToProps = (state: any, props: any) => {
    return {
      props
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {}
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withRouter(OrganisationList)
)
