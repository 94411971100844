import { useState, useEffect, useCallback, useRef } from 'react';

export const useView = (defaultView: string) => {
    const [view, setView] = useState(defaultView);

    const handleValidate = (callback: any) => {
      callback();
    }

    const resetView = () => {
      setView(defaultView);
    }

    return {
      view,
      setView,
      handleValidate,
      resetView,
    }
}

export const useWindowWidth = (width: number) => {

  const [ windowWidth, setWindowWidth ] = useState<number>(width);

  const resize = useCallback(() => {
    setWindowWidth(window.innerWidth)
  }, [setWindowWidth])

  useEffect(() => {
    window.addEventListener('resize', resize)

    return () => {
      window.removeEventListener('resize', resize)
    }
  })

  return {
    windowWidth,
    setWindowWidth,
  };
};

export const useWindowHeight = (height: number) => {

  const [ windowHeight, setWindowHeight ] = useState<number>(height);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWindowHeight(window.innerHeight);
    })
  });

  return {
    windowHeight,
    setWindowHeight,
  };
};

export const useScrollStop = (delayInMilliSeconds: number, onScroll?: Function) => {

    const [ scrolling, setScrolling ] = useState<boolean>(false);
    const timeoutRef = useRef(0)

    const cancelDelayScrollStop = () => {
      window.clearTimeout(timeoutRef.current);
    }

    const delayScrollStop = useCallback((delayInMilliSeconds: number) => {
      window.clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setScrolling(false);
      }, delayInMilliSeconds)
    }, [timeoutRef])

    useEffect(() => {
      const onScrollStop : EventListener = (e: any) => {
        if (onScroll && !scrolling) onScroll();
        if (!scrolling) setScrolling(true);
        delayScrollStop(delayInMilliSeconds);
      }
      window.addEventListener('scroll', onScrollStop)
      return () => {
        window.removeEventListener('scroll', onScrollStop)
      }
    }, [scrolling, delayInMilliSeconds, onScroll, delayScrollStop]);

    return {
        scrolling,
        setScrolling,
        timeoutRef,
        delayScrollStop,
        cancelDelayScrollStop
    };
}
