import React, { useState } from 'react';
import { presentError } from '@necta-tech/alert';
import { zipObject } from 'lodash';
import Dropzone from 'react-dropzone';
import * as XLSX from 'xlsx';
import { Field, mapDataIntoSchema } from '../../../services/helpers/yup-mapper';
import { CContainer, PfpDiv, DropzoneInner } from '../style';

const DATE_FORMAT = 'yyyy/mm/dd';

export interface Column {
  title: string;
  field: string;
  type: string | 'boolean' | 'numeric' | 'date' | 'datetime' | 'time' | 'currency';
  hidden?: boolean;
  render?: React.FC | string;
}

interface SpreadsheetImportProps {
  onComplete: (data: any) => void;
  fields: Field[];
}

const SpreadsheetImport: React.FC<SpreadsheetImportProps> = ({ onComplete, fields }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const fileSelected = (files: any) => {
    if (loading) return;

    setLoading(true);
    const file = files[0];
    const reader = new FileReader();

    reader.onload = (e: any) => {
      try {
        const binaryData = new Uint8Array(e.target.result);
        const workbook = XLSX.read(binaryData, { type: 'array', cellDates: true, cellNF: false, cellText: false });
        const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];

        const [headers, ...body] = XLSX.utils.sheet_to_json(first_worksheet, { header: 1, dateNF: DATE_FORMAT });

        const keys = headers.map((h: string) => h.replace('*', ''));

        const data = body.map(row => zipObject(keys, row));

        console.log(data);

        const mappedData = data.map(row => mapDataIntoSchema(row, fields));

        console.log(mappedData);

        setLoading(false);
        onComplete(mappedData);
      } catch (e) {
        console.error(e);
        presentError('Invalid sheet format', 'Error');
        setLoading(false);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <CContainer>
      <PfpDiv>
        <Dropzone onDrop={acceptedFiles => fileSelected(acceptedFiles)}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <DropzoneInner>
                  <h4>{loading ? 'Importing sheet...' : 'Drop a spreadsheet or click to upload'}</h4>
                </DropzoneInner>
              </div>
            </section>
          )}
        </Dropzone>
      </PfpDiv>
    </CContainer>
  );
};

export default SpreadsheetImport;
