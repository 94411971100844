import React, { FC, useCallback, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import * as yup from 'yup';
import { presentSuccess, presentError } from '@necta-tech/alert';
import { CircularProgress, Grid, Tooltip } from '@material-ui/core';
import { Delete, KeyboardArrowLeft as Back, Telegram } from '@material-ui/icons';
import { Field, Formik, FormikHelpers, FormikValues } from 'formik';
import { TextField as FormikTextField, getFormData } from '../../services/helpers/form-helper';
import {
  BackButton,
  Card,
  CardTitle,
  CenteredColumn,
  Divider,
  Form,
  Page,
  PrimaryButton,
  Required,
  SubTitle
} from '../../global/style';
import { getActiveOrgId, getIsAdmin } from '../../global/helpers/selectors';
import OrgsDropdown from '../../components/organisation/OrgDropDown';
import Swal from "sweetalert2";

const UPDATE_ORG_MOTD_MUTATION = gql`
  mutation UPDATE_ORG_MOTD_MUTATION($organisation: String!, $motd: String!) {
    updateOrganisationMotd(id: $organisation, motd: $motd) {
      name
      id
    }
  }
`;

const { schema, fields, initialValues } = getFormData(
  yup.object().shape({
    motd: yup.string().required().label('Broadcast Message').typeError('Please enter a broadcast message'),
    organisation: yup.string().required().label('Organisation').typeError('Please select an organisation'),
  })
);

const TextField = styled(FormikTextField)`
  && {
    width: 100%;
  }
`;

const CreateButton = styled(PrimaryButton)`
  && {
    height: 56px;
    width: 100%;
    border-radius: 0;
  }
`;

const Title = styled.div`
  flex: 1;
`;

export const DeleteButton = styled(BackButton)`
  && {
    margin-right: 0;
  }
`;

interface OrganisationMotdProps {
  activeOrgId: string;
  isAdmin: string;
  history: any;
}

const OrganisationMotd: FC<OrganisationMotdProps> = ({ activeOrgId, isAdmin, history }) => {
  const [defaultValues] = useState<any>({ ...initialValues, organisation: activeOrgId });

  const [addMotd, { loading }] = useMutation(UPDATE_ORG_MOTD_MUTATION);

  const onSubmit = async (values: FormikValues, actions: FormikHelpers<any>) => {
    const result = await Swal.fire({
      title: 'Send broadcast message',
      text: 'This will send an email to all players and coaches at this organisation with the new broadcast message.',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Confirm'
    });

    if (!result.value) {
      return;
    }

    actions.setSubmitting(true);
    try {
      await addMotd({ variables: { ...values } });
      presentSuccess('Broadcast sent successfully!', 'Success');
      actions.resetForm(initialValues);
    } catch (e) {
      presentError(e, 'Unable to send broadcast message!');
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleDelete = useCallback((organisation: string) => async (e?: any) => {
    if (!organisation) {
      presentError('Please select an organisation', 'No organisation selected');
      return;
    }
    const result = await Swal.fire({
      title: 'Clear broadcast message',
      text: 'This will remove the current broadcast message and will not email anybody',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Confirm'
    });

    if (!result.value) {
      return;
    }

    const update = await addMotd({ variables : { organisation, motd: '' } });
    if (update) {
      presentSuccess('Broadcast message has been removed');
    }
  }, [addMotd]);

  return (
    <Page>
      <Card>
        <CenteredColumn>
          <Formik initialValues={defaultValues} validateOnBlur validationSchema={schema} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting, values }) => (
              <>
                <CardTitle>
                  <BackButton onClick={() => history.goBack()}>
                    <Back />
                  </BackButton>
                  <Title>Send Broadcast Message</Title>
                  <Tooltip title={'Remove broadcast message'}>
                    <DeleteButton onClick={handleDelete(values.organisation)} disabled={loading}>
                      <Delete />
                    </DeleteButton>
                  </Tooltip>
                </CardTitle>
                <Divider />
                <Required>
                  <SubTitle>This will send an email to all players and coaches of the organisation</SubTitle>
                </Required>
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Field {...fields.motd} component={TextField} multiline={true} disabled={loading}/>
                    </Grid>
                    { isAdmin && <Grid item xs={12} md={12}>
                      <OrgsDropdown Render={Field} field={fields.organisation} />
                    </Grid> }
                  </Grid>
                </Form>
                <Divider />
                <CreateButton disabled={loading || isSubmitting} onClick={() => handleSubmit()}>
                  {loading || isSubmitting ? <CircularProgress size={24} color="inherit" /> : <>Send <Telegram /></>}
                </CreateButton>
              </>
            )}
          </Formik>
        </CenteredColumn>
      </Card>
    </Page>
  );
};

const mapStateToProps = (state: any, props: any) => {
  return {
    isAdmin: getIsAdmin(state),
    activeOrgId: getActiveOrgId(state),
    props
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {};
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OrganisationMotd)
);
