import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from '../../App';
import { connect } from 'react-redux';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

const Container = styled.div`
    width: 100vw;
    height: 100vh;

    font-family: Ubuntu, Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
`;


const Providers = ({apolloClient, store, persistor }: any) => {

    return (
        <ApolloProvider client={apolloClient}>
            <ApolloHooksProvider client={apolloClient}>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <Container>
                            <App />
                        </Container>
                    </PersistGate>
                </Provider>
            </ApolloHooksProvider>
        </ApolloProvider>
    );
}

const mapStateToProps = (state: any, props: any) => {
    return {
        cognitoUser: state.cognitoUser,
        props
    }
}

export default connect(mapStateToProps, null)(Providers);