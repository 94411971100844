import React, { useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Grid } from '@material-ui/core';
import styled from 'styled-components';
import { useLazyQuery, useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { setCurrentUser } from '../../redux/actions';
import { presentError, presentSuccess } from '@necta-tech/alert';
import ErrorDisplay from '../../components/error-display/ErrorDisplay';
import SkeletonLoader from '../../components/loaders/LoaderContainer';
import { getThumbnail } from '@necta-tech/s3';
import Swal from 'sweetalert2';
import PageLayout from '../../components/layout/PageLayout';
import { UPDATE_ORG_ACCESS } from '../../global/gql/orgAccess';
import { getActiveOrgId } from '../../global/helpers/selectors';

const GET_MY_ORG_ACCESS = gql`
    query GET_MY_ORG_ACCESS {
        myOrgAccess (queryOptions: { relations: ["organisation"] }) {
            id
            status
            archived
            organisation {
              id
              name
              primaryContactName
              primaryContactEmail
              primaryContactCell
              logoUrl
              locationProvince
              ownedBy
            }
        }
    }
`;

const MY_ORG_TITLE = "My Organisation Access";

const CardInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 20px;
`;

const Organisation = styled.div`
  margin: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const OrgTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media only screen and (max-width: 599px) {
    flex-direction: column;
  }
`;

const OrgName = styled.div`
  font-size: 20px;
  padding: 5px 5px 5px 15px;
  @media only screen and (max-width: 599px) {
    font-size: 18px;
  }
`;

const OrgImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid ${p => p.theme.primary};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const OrgInner = styled.div`
  margin: 5px;
`;

const OrgStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end; 
  width: 100%;
  @media only screen and (max-width: 599px) {
    align-items: center;
    && {
      div, button {
        width: 100%;
      }
    }
  }
`;

const OrgStatusOuter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 100%;
`;

const Active = styled(Button)`
  && {
    color: ${p => p.theme.primary};
  }
`;

const Accept = styled(Button)`
  && {
    color: ${p => p.theme.success};
  }
`;

const Decline = styled(Button)`
  && {
    color: ${p => p.theme.danger};
  }
`;

const OrgHeader = styled.div`
  margin: 0 15px 5px 17px;
`;

const HeaderTitleL = styled.div`
  text-align: left;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.25);
  @media only screen and (max-width: 599px) {
    text-align: center;
  }
`;

const HeaderTitleR = styled(HeaderTitleL)`
  text-align: right;
  @media only screen and (max-width: 599px) {
    display: none;
  }
`;

const OrgContainer = styled.div`
  margin-bottom: 40px;
`;

const OrgHeaders = ({ title }: any) => {
  return (
    <OrgHeader>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={9}>
          <HeaderTitleL>
            {title}
          </HeaderTitleL>
        </Grid>
        <Grid item xs={12} sm={3}>
          <HeaderTitleR>
            Actions
          </HeaderTitleR>
        </Grid>
      </Grid>
    </OrgHeader>
  );
};

const MyOrganisations = ({ cognitoUser, currentUser, activeOrgId, props }: any) => {
  const [access, setAccess] = useState<any>({
    current: null,
    active: [],
    pending: [],
  });

  const [getOrgAccess, { loading, data }] = useLazyQuery(GET_MY_ORG_ACCESS, {
    fetchPolicy: 'network-only',
  });

  const [updateOrgAccess, { loading: isSubmitting }] = useMutation(UPDATE_ORG_ACCESS, {
    onError: e => {
      console.log(e);
      presentError(e, 'Unable to respond to invite');
    }
  });

  const goBack = () => {
    props.history.goBack();
  };

  const respondToInvite = useCallback(async (id: string, status: string) => {
    const update = await updateOrgAccess({ variables : { id, updateOrgAccess: { status }} });
    if (update) {
      if (!activeOrgId) {
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        await getOrgAccess();
      }
      presentSuccess('Invite has been ' + (status === 'ACTIVE' ? 'accepted' : 'declined'));
    }
  }, [updateOrgAccess, getOrgAccess, activeOrgId]);

  const setActiveOrg = useCallback(async (id: string) => {
    Swal.fire({
      title: 'Change active organisation',
      text: 'This will switch your current organisation. You will see only see data from your current organisation.',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Confirm'
    }).then(async (result) => {
      if (result.value) {
        const update = await updateOrgAccess({ variables : { id, updateOrgAccess: { setActive: true }} });
        if (update) {
          presentSuccess('Active organisation has been updated');
          setTimeout(() => {
            window.location.reload();
          }, 3000)
        }
      }
    });
  }, [updateOrgAccess]);

  const leaveOrg = useCallback(async (id: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This will disable your access to this organisation. You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Leave organisation'
    }).then(async (result) => {
      if (result.value) {
        const update = await updateOrgAccess({ variables : { id, updateOrgAccess: { status: 'DEACTIVATED' }} });
        if (update) {
          presentSuccess('You have left the organisation');
          if (activeOrgId === id) {
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
        }
      }
    });
  }, [updateOrgAccess, activeOrgId]);

  const canActivate = useCallback((id: string) => {
    return !currentUser.activeOrg || (id !== currentUser.activeOrg.id);
  }, [currentUser.activeOrg]);

  useEffect(() => {
    if (data && data.myOrgAccess) {
      const current = data.myOrgAccess.find((a: any) => a.organisation && a.organisation.id === activeOrgId);
      const active = data.myOrgAccess.filter((a: any) => a.status === 'ACTIVE' && (!current || current.id !== a.id));
      const pending = data.myOrgAccess.filter((a: any) => !a.status || a.status === 'PENDING');
      setAccess({
        current,
        active,
        pending
      });
    } else getOrgAccess();
  }, [ getOrgAccess, setAccess, data, activeOrgId ]);

  const OrgRow = ({ access }: any) => {
    return (
      <Organisation>
        <OrgInner>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <OrgTitle>
                <OrgImg
                  src={!access.organisation || !access.organisation.logoUrl ? require('../../assets/img/default-org.png') : getThumbnail(access.organisation.logoUrl)}
                  alt="Org Logo"
                />
                <OrgName>{ (access.organisation && access.organisation.name) || ''}</OrgName>
              </OrgTitle>
            </Grid>
            <Grid item xs={12} sm={4}>
              <OrgStatusOuter>
                <OrgStatus>
                  { access.status === 'ACTIVE' ? (
                    <div>
                      {access.organisation && canActivate(access.organisation.id) && <Active onClick={() => setActiveOrg(access.id)} disabled={isSubmitting}>Activate</Active> }
                      <Decline onClick={() => leaveOrg(access.id)} disabled={isSubmitting}>Leave</Decline>
                    </div>
                  ) : (
                    <div>
                      <Accept onClick={() => respondToInvite(access.id, 'ACTIVE')} disabled={isSubmitting}>Accept</Accept>
                      <Decline onClick={() => respondToInvite(access.id, 'DECLINED')} disabled={isSubmitting}>Decline</Decline>
                    </div>
                  )}
                </OrgStatus>
              </OrgStatusOuter>
            </Grid>
          </Grid>
        </OrgInner>
      </Organisation>
    );
  };

  if (loading) return <SkeletonLoader title={MY_ORG_TITLE} type="list" iterations={3}/>;

  if (!data || (data && !data.myOrgAccess)) {
    return <ErrorDisplay title={MY_ORG_TITLE} error="Unable to load organisation access" reload={getOrgAccess} />
  }

  if (!access.current && access.active.length === 0 && access.pending.length === 0) return <ErrorDisplay title={MY_ORG_TITLE} error="You have no active organisations or pending invites." reload={getOrgAccess} />;

  return (
    <PageLayout Title={() => MY_ORG_TITLE} reload={getOrgAccess} back={goBack} >
      <CardInner>
        {
          currentUser.activeOrg && access.current && (
            <OrgContainer>
              <OrgHeaders title="Current Organisation" />
              <OrgRow access={access.current} />
            </OrgContainer>
          )
        }
        {
          access.active && access.active.length > 0 && (
            <OrgContainer>
              <OrgHeaders title="Joined Organisations" />
              {
                access.active.map((a: any, i: number) => {
                  return <OrgRow access={a} key={i} />
                })
              }
            </OrgContainer>
          )
        }
        {
          access.pending && access.pending.length > 0 && (
            <OrgContainer>
              <OrgHeaders title="Pending Invitations" />
              {
                access.pending.map((a: any, i: number) => {
                  return <OrgRow access={a} key={i} />
                })
              }
            </OrgContainer>
          )
        }
      </CardInner>
    </PageLayout>
  );
};

const mapStateToProps = (state: any, props: any) => {
  return {
    cognitoUser: state.cognitoUser,
    currentUser: state.currentUser,
    activeOrgId: getActiveOrgId(state),
    props
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setCurrentUser: (currentUser: any) => {
      dispatch(setCurrentUser(currentUser));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MyOrganisations)
);
