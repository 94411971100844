import React, { useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { useMutation } from 'react-apollo';
import { presentError } from '@necta-tech/alert';
import { KeyboardArrowLeft as Back } from '@material-ui/icons';
import { Formik, FormikHelpers, FormikValues, Field as FormikField } from 'formik';
import Moment from 'moment';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AutoForm from '../../components/form/auto-form';
import OrgsDropdown from '../../components/organisation/OrgDropDown';
import ImportTables from '../../components/sheet-importer/import-tables';
import SubmitButton from '../../components/submit-button';
import { useWindowWidth } from '../../custom-hooks/viewhooks';
import { IMPORT_USERS_MUTATION } from '../../global/gql/users';
import { getActiveOrgId, getIsAdmin } from '../../global/helpers/selectors';
import playerSchema, { Player, confirmSchema } from './schema';
import { CheckboxField, getFormData } from '../../services/helpers/form-helper';
import { exportDataToExcel } from '../../services/helpers/template-exporter';
import { mapFieldsFromSchema } from '../../services/helpers/yup-mapper';
import {
  BackButton,
  Card, CardTitle,
  CenteredColumn,
  Page, PrimaryButton,
} from '../../global/style';

const MySwal = withReactContent(Swal);

const { schema, initialValues } = getFormData(playerSchema);
const fields = mapFieldsFromSchema(playerSchema);

const { schema: cSchema, fields: cFields, initialValues: cInitialValues } = getFormData(confirmSchema);

const CForm = styled.form`
  && {
    width: 100%;
    max-height: 500px;
    overflow-y: scroll;
    text-align: left !important;
    .MuiCheckbox-root {
      width: auto !important;
      margin-left: 15px !important;
    }
  }
`;

const CField = styled(FormikField)`
  && {
    width: 100%;
  }
`;

const ConfirmForm = styled.form`
  && {
    width: 100%;
  }
`;

const ImportContainer = styled.div<{hidden: boolean}>`
  display: ${p => p.hidden ? 'none' : ''};
  width: 100%;
`;

const ImportFinalize = styled.div`
  width: 100%;
  text-align: left;
`;

const ImportConfirm = styled.div`
  margin: 5px 15px 15px 15px;
`;

const ImportFinish = styled.div`
  margin: 10px 15px 25px 15px;
  text-align: center;
`;

const FormInner = styled.div`
  padding: 10px;
`;

const sanitize = (obj: Player[]) => {
  return JSON.parse(JSON.stringify(obj, (key, value) => {
    return (value === null ? undefined : value);
  }));
};

const PlayerImport: React.FC<any> = ({ activeOrgId, isAdmin }) => {
  const [players, setPlayers] = useState<Player[]>([]);
  const [importSuccess, setImportSuccess] = useState<boolean>(false);

  const { windowWidth } = useWindowWidth(window.innerWidth);

  const [importUsers] = useMutation(IMPORT_USERS_MUTATION);

  const cDefaultValues = useMemo(() => Object.assign({ ...cInitialValues, organisationId: isAdmin ? null : activeOrgId }), [activeOrgId, isAdmin]);

  const handleEdit = useCallback((row: any) => {
    return new Promise((resolve, reject) => {

      const values = {...initialValues, ...row};
      const width = windowWidth > 768 ? '400px' : (windowWidth * 0.96) + 'px';

      const onComplete = async (values: FormikValues, actions: FormikHelpers<any>) => {
        actions.setSubmitting(true);
        try {
          MySwal.close();
          resolve(values);
          actions.resetForm(initialValues);
        } catch (e) {
          presentError(e, 'Unable to edit');
          reject(e);
        } finally {
          actions.setSubmitting(false);
        }
      };

      MySwal.fire({
        title: 'Edit Row',
        html: <AutoForm form={CForm} schema={schema} fields={fields} initialValues={values} onComplete={onComplete} />,
        width,
        showConfirmButton: false,
        showCloseButton: true
      });
    });
  }, [windowWidth]);

  const handleComplete = useCallback((data: any) => {
    console.log(data);
    setPlayers(data);
  }, [setPlayers]);

  const handleClear = useCallback(() => {
    setImportSuccess(false);
    setPlayers([]);
  }, []);

  const exportPlayers = useCallback(() => {
    exportDataToExcel(fields, players, 'scrumpro-data-import_' + Moment().format('YYYY-DD-MM') + '.xlsx');
  }, [players]);

  const handleFinalize = async (values: FormikValues, actions: FormikHelpers<any>) => {
    actions.setSubmitting(true);
    try {
      const confirm = await Swal.fire({
        title: 'Are you Sure?',
        text: 'This will attempt to create accounts for all valid users in this import. Any users with existing accounts will be sent an invite to your organisation.',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Confirm'
      });
      if (!confirm.value) return;

      const { downloadExcel, ...importData } = values;
      const users: Player[] = [ ...sanitize(players) ];

      await importUsers({ variables: { users, ...importData }});

      setImportSuccess(true);

      if (downloadExcel) {
        exportPlayers();
      }
      // presentSuccess('Server is busy importing your data, please wait a few minutes and you will be sent a success email when your import has been completed', 'Import successful', false);
      actions.resetForm(initialValues);
    } catch (e) {
      presentError(e, 'Unable to import');
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Page>
      <Card>
        <CenteredColumn>
          { !importSuccess && (
            <>
              <ImportContainer hidden={players.length > 0}>
                <ImportTables onComplete={handleComplete} validationSchema={schema} fields={fields} initialValues={initialValues} editRow={handleEdit} />
              </ImportContainer>
              { players.length > 0 && (
                <>
                  <CardTitle>
                    <BackButton onClick={handleClear}>
                      <Back />
                    </BackButton>
                    Finalize Import
                  </CardTitle>
                  <ImportFinalize>
                    <ImportConfirm>
                      You are about to import {players.length} user{players.length !== 1 && 's'}.
                    </ImportConfirm>
                    <Formik initialValues={cDefaultValues} validateOnBlur validationSchema={cSchema} onSubmit={handleFinalize}>
                      {({ handleSubmit, isSubmitting }) => (
                        <ConfirmForm>
                          <FormInner>
                            {isAdmin && <OrgsDropdown Render={CField} field={cFields.organisationId} />}
                            <CField {...cFields.sendWelcome} component={CheckboxField} />
                            <CField {...cFields.downloadExcel} component={CheckboxField} />
                            {!isAdmin && !activeOrgId && 'You need to have an active organisation to be able to import players'}
                          </FormInner>
                          <SubmitButton handleSubmit={() => handleSubmit()} disabled={isSubmitting} component={PrimaryButton}>Confirm</SubmitButton>
                        </ConfirmForm>
                      )}
                    </Formik>
                  </ImportFinalize>
                </>
              )}
            </>
          )}
          { importSuccess && (
            <>
              <CardTitle>
                <BackButton onClick={handleClear}>
                  <Back />
                </BackButton>
                Import Complete
              </CardTitle>
              <ImportFinalize>
                <ImportFinish>
                  Server is busy importing your data, please wait a few minutes and you will be sent a success email when your import has been completed.
                </ImportFinish>
                <PrimaryButton onClick={handleClear}>
                  Acknowledge
                </PrimaryButton>
              </ImportFinalize>
            </>
          )}
        </CenteredColumn>
      </Card>
    </Page>
  )
};

const mapStateToProps = (state: any, props: any) => {
  return {
    isAdmin: getIsAdmin(state),
    activeOrgId: getActiveOrgId(state),
    ...props,
  }
};

const mapDispatchToProps = (dispatch: Function) => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerImport);
