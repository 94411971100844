import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { useLazyQuery } from 'react-apollo';
import { CircularProgress, Checkbox, Button } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { presentError } from '@necta-tech/alert';
import gql from 'graphql-tag';
import * as yup from 'yup';
import { Formik, Field, FormikValues, FormikHelpers } from 'formik';
import MaterialTable, { Column } from 'material-table';
import { TextField as FormikTextField, getFormData } from '../../services/helpers/form-helper';
import { Form } from '../../global/style';

// Note: if you want to add fields, a backend security change is needed to allow the fields to be fetched
const FIND_USERS = gql`
  query FIND_USERS($userQuery: UserQuery!) {
    userSearch(userQuery: $userQuery) {
      firstname
      lastname
      email
      locationProvince
      id
    }
  }
`;

const { schema, fields, initialValues } = getFormData(
  yup.object().shape({
    email: yup
      .string()
      .email()
      .trim()
      .required()
      .label('Search by Email')
  })
);

const SearchButton = styled(Button)`
  && {
    margin-top: 6px;
    width: 3.4rem;
    height: 3.4rem;
    color: ${p => p.theme.primary};
    @media only screen and (max-width: 959px) {
      width: 1.75rem;
    }
  }
`;

const ImportForm = styled(Form)`
  width: 100%;
  margin: 0;
`;

const TextField = styled(FormikTextField)`
  && {
    width: 100%;
  }
  flex: 1;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 3.4rem;
  width: 100%;
`;

type UserSelectProps = {
  userId: string | null,
  setValue: any,
  externalLoading?: boolean,
  title?: string
}
const UserSelect = ({ userId, setValue, externalLoading, title }: UserSelectProps) => {

  const [users, setUsers] = useState<any[]>([]);

  const [ getUsers, { data, loading } ] = useLazyQuery(FIND_USERS, { 
    onCompleted: (result: any) => {
      setUsers(result.userSearch)
      if (data.userSearch.length === 1) 
        setInitial(data.userSearch[0].id)
    },
    fetchPolicy: 'network-only' 
  });

  const handleClick = (row: any) => {
    if (row.id === userId) {
      setValue(null);
    } else {
      setValue(row.id);
    }
  };

  const handleSearch = async (values: FormikValues, actions: FormikHelpers<any>)  => {
    try {
      await getUsers({ variables: { userQuery: { email: values.email.split( ' ').join('') } } });
    } catch (e) {
      presentError(e, 'Unable to find user');
    } finally {
    }
  };

  /*ts-ignore*/
  const setInitial = useCallback((value: string) => setValue(value), [setValue]);

  return (
    <Formik initialValues={initialValues} validateOnBlur validationSchema={schema} onSubmit={handleSearch}>
      {({ handleSubmit, isSubmitting, values }) => (
        <ImportForm>
          <SearchContainer>
              <Field {...fields.email} component={TextField} />
              <SearchButton onClick={() => handleSubmit()} disabled={loading}>
                {loading ? <CircularProgress size={24} color="inherit" /> : <Search />}
              </SearchButton>
          </SearchContainer>
          {users && users.length > 0 && <MaterialTable
            title={title ? title : 'Select Owner:'}
            isLoading={loading || externalLoading}
            style={{ boxShadow: 'none', width: '100%', marginTop: '10px' }}
            columns={
              [
                {
                  field: 'checked',
                  title: 'Checked',
                  render: (rowData: any) => {
                    const isSelected = rowData.id === userId;
                    return <Checkbox checked={isSelected} />;
                  }
                },
                { title: 'Name', field: 'firstname', defaultSort: 'asc' },
                { title: 'Surname', field: 'lastname' },
                { title: 'Email', field: 'email' },
                { title: 'Province', field: 'locationProvince' },
              ] as Column<any>[]
            }
            data={users}
            onRowClick={(e: any, row: any) => handleClick(row)}
            options={{
              search: false,
              header: false,
              showTitle: true,
              showFirstLastPageButtons: false,
              emptyRowsWhenPaging: false,
              paginationType: 'stepped',
              paging: users && users.length > 5 ? true : false,
              padding: 'dense'
            }}
          />}
        </ImportForm>
      )}
    </Formik>
  );
}

export default UserSelect;
