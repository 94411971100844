import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

export const useScrollToTop = () => {
    //FYI you react-router has some dope ass hooks we can use (fuck HOC's)
    const history = useHistory()

    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        })

        return () => { unlisten() }
    }, [])
}

const ScrollToTop: React.FC<any> = (props) => {
    useScrollToTop()
    return props.children || null
}

export default ScrollToTop