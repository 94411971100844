export const actions = {
    SET_COGNITO_USER: 'SET_COGNITO_USER',
    SET_IN_SESSION: 'SET_IN_SESSION',
    SET_CURRENT_USER: 'SET_CURRENT_USER',
    SET_PAGE_TITLE: 'SET_PAGE_TITLE',
    SET_REDIRECT_ON_LOGIN: 'SET_REDIRECT_ON_LOGIN',
    SET_LOGOUT_PANE_ACTIVE: 'SET_LOGOUT_PANE_ACTIVE',
};

//Token supported by the reducer, each token corresponds to an event that can occur and that causes a change in the store
//These functions are called from ExampleFunctionality, which calls them as part of the process of exposing functions to components

export const setCognitoUser = (user: any) => {
    return { type: actions.SET_COGNITO_USER, user };
};

export const setInSession = (inSession: boolean) => {
    return { type: actions.SET_IN_SESSION, inSession };
};

export const setCurrentUser = (currentUser: any) => {
    return { type: actions.SET_CURRENT_USER, currentUser };
};

export const setPageTitle = (pageTitle: string) => {
    return { type: actions.SET_PAGE_TITLE, pageTitle };
};

export const setRedirectOnLogin = (path: string) => {
    return { type: actions.SET_REDIRECT_ON_LOGIN, path };
};

export const setLogoutPaneActive = (paneActive: boolean) => {
    return { type: actions.SET_LOGOUT_PANE_ACTIVE, paneActive };
};

export const Actions = {
    setCognitoUser,
    setInSession,
    setCurrentUser,
    setPageTitle,
    setRedirectOnLogin,
    setLogoutPaneActive
};