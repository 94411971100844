import React from 'react'
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AppBar, Typography, IconButton, Toolbar } from '@material-ui/core';
import Lock from '@material-ui/icons/Lock';
import MenuIcon from '@material-ui/icons/Menu';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components'
import { connect } from 'react-redux';
import { setLogoutPaneActive } from '../../redux/actions';

const CToolbar = styled(Toolbar)`
    background: #302050;
    color: white;
`;

const Title = styled(Typography)`
    flex: 1;
`;  

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: 240,
      width: `calc(100% - ${240}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
  })
);

const Header = ({setLogoutPaneActive, open, windowWidth, handleDrawerOpen, props}: any) => {
    const classes = useStyles();

    const route = props.location.pathname.split('/')[1];
    const title = route.charAt(0).toUpperCase() + route.substring(1);

    return (
        <AppBar
            position="fixed"
            className={windowWidth > 768 ? clsx(classes.appBar, {
                [classes.appBarShift]: open,
            }) : ''}
        >
            <CToolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, {
                        [classes.hide]: open,
                    })}
                >
                    <MenuIcon />
                </IconButton>
                <Title variant="h6" noWrap>
                    { title }
                </Title>
                <IconButton color="inherit" onClick={() => setLogoutPaneActive(true)}>
                    <Lock />
                </IconButton>
            </CToolbar>
        </AppBar>
    )
}

const mapStateToProps = (state: any, props: any) => {
    return {
        logoutPaneActive: state.logoutPaneActive,
        props
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setLogoutPaneActive: (active: boolean) => { dispatch(setLogoutPaneActive(active)) }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
