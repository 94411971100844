import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { Submit } from './style';
import useSubmit from '../../custom-hooks/use-submit';

const Content: React.FC<{ loading: boolean }> = ({ loading, ...props }) => {
  return (
    <>
      { loading ? <CircularProgress size={24} color="inherit" /> : props.children || null }
    </>
  )
}

interface SubmitProps {
    handleSubmit: () => void;
    loading?: boolean;
    disabled?: boolean;
    component?: any;
}

export const SubmitButton: React.FC<SubmitProps & any> = ({ handleSubmit, loading = false, component: Component, ...props }) => {
  useSubmit(handleSubmit)
  const handleOnClick = () => handleSubmit()
  const Render = Component ? Component : Submit;
  const disabled = props.disabled ? props.disabled || loading : loading;
  return (
    <Render {...props} disabled={disabled} onClick={handleOnClick}>
      <Content loading={loading}>
        {props.children || null}
      </Content>
    </Render>
  )
}

export default SubmitButton

