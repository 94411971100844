import { useEffect } from 'react';

export const useSubmit = (onSubmit: any) => {
  useEffect(() => {
    const onKeyPress = (e: any) => {
      if (e.keyCode === 13) onSubmit()
    }
    document.addEventListener('keypress', onKeyPress)
    return () => document.removeEventListener('keypress', onKeyPress)
  }, [])
}

export default useSubmit