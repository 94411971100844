import React from 'react';
import { CircularProgress } from '@material-ui/core';
import * as yup from 'yup';
import { Formik, Field, FormikValues, FormikHelpers } from 'formik';
import { forgotPassword } from '@necta-tech/cognito';
import { presentSuccess, presentError } from '@necta-tech/alert';
import { KeyboardArrowLeft as Back } from '@material-ui/icons';
import {
  Padder,
  CenterColumn,
  CTextField,
  CardTitle,
  Fields,
  LoginCard,
  AltForm as Form,
  BackButton
} from './style';
import { getFormData } from '../../services/helpers/form-helper';
import SubmitButton from '../../components/submit-button';

const { schema, fields, initialValues } = getFormData(
  yup.object().shape({
    email: yup
      .string()
      .email()
      .trim()
      .required()
      .label('Email')
  })
);

const SendResetOTP = ({ otpSent, handleBack }: any) => {
  const handleSendOTP = async (values: FormikValues, actions: FormikHelpers<any>) => {
    actions.setSubmitting(true);
    try {
      const email = values.email.replace(/\s/g, '').toLowerCase();
      const data = await forgotPassword(email);
      if (data) {
        actions.setSubmitting(false);
        presentSuccess('Please check your emails for an OTP');
        if (otpSent) otpSent(email);
      }
    } catch (e) {
      actions.setSubmitting(false);
      presentError(e, 'Unable to reset password');
    }
  };

  return (
    <Formik validationSchema={schema} initialValues={initialValues} onSubmit={handleSendOTP} validateOnBlur>
      {({ handleSubmit, isSubmitting }) => (
        <> 
          <LoginCard>
            <CenterColumn>
              <CardTitle>
                <BackButton onClick={() => handleBack()}>
                  <Back />
                </BackButton>
                <h1>Forgot Password</h1>
              </CardTitle>
              <Form>
                <Fields>
                  <Field {...fields.email} autoComplete="email" component={CTextField} />
                </Fields>
                <Padder />
              </Form>
            </CenterColumn>
          </LoginCard>
          <SubmitButton loading={isSubmitting} handleSubmit={handleSubmit}>
            Send OTP
          </SubmitButton>
        </>
      )}
    </Formik>
  );
};

export default SendResetOTP;
