import Amplify from 'aws-amplify';
import { setCognitoUser } from '../redux/actions';
import { cognitoConfig, User, refresh } from '@necta-tech/cognito';
import { s3config } from '@necta-tech/s3';
import Swal from 'sweetalert2';

export default (store: any) => {

    Amplify.configure({
        Auth: {
            // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
            identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,      
            // REQUIRED - Amazon Cognito Region
            region: process.env.REACT_APP_AWS_AUTH_REGION, 
            // REQUIRED - Amazon Cognito User Pool ID
            userPoolId: process.env.REACT_APP_USER_POOL_ID,
            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
        },
        Storage: {
            AWSS3: {
                bucket: process.env.REACT_APP_AWS_BUCKET, //REQUIRED -  Amazon S3 bucket
                region: process.env.REACT_APP_AWS_BUCKET_REGION, //OPTIONAL -  Amazon service region
            }
        }
    });

    s3config(process.env.REACT_APP_S3_HOST);

    const cognitoSuccess = (user: User) => {
        store.dispatch(setCognitoUser(user));
    };

    const cognitoFailure = (message?: string, title?: string) => {
        Swal.fire(title || "Authentication failure", message, "error");
        const user = { inSession: false };
        store.dispatch(setCognitoUser(user));
    }

    cognitoConfig(cognitoSuccess, cognitoFailure);

    setTimeout(() => {      
        if (store.getState().cognitoUser.inSession) refresh();
    })
}