import gql from 'graphql-tag';

export const ORGS_QUERY = gql`
    query ORGS_QUERY ($organisationQuery: OrganisationQuery!) {
        organisations (organisationQuery: $organisationQuery) {
            id
            name
            primaryContactName
            primaryContactEmail
            primaryContactCell
            logoUrl
            locationProvince
            ownedBy
            archived
        }
    }
`;

export const DELETE_ORG_QUERY = gql`
    mutation DELETE_ORG_QUERY ($id: String!) {
        removeOrganisation (id: $id)
    }
`;

export const UPDATE_ORG_QUERY = gql`
    mutation UPDATE_ORG_QUERY ($id: String!, $updateOrganisation: UpdateOrganisationInput!) {
        updateOrganisation (id: $id, updateOrganisation: $updateOrganisation) {
            id
            name
            primaryContactName
            primaryContactEmail
            primaryContactCell
            logoUrl
            locationProvince
            ownedBy
            archived
        }
    }
`;

export const MANAGERS_AT_ORG_QUERY = gql`
    query USERS_AT_ORG($id: String!) {
        managersByOrgId(id: $id) {
          id
          shortid
          firstname
          lastname
          email
          profilePicUrl
          orgAccess {
            id
            archived
            expires
          }
        }
    }
`;