import React, { FC } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { KeyboardArrowLeft as Back, Refresh } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { BackButton, Card, CardTitle, CenteredColumn, Divider, Page } from '../../global/style';

const ErrorContainer = styled.div`
  margin: 50px 10px;
  display: flex;
  align-content: center;
  align-items: center;
  @media only screen and (min-height: 769px) {
    min-height: 400px;
  }
`;

const Title = styled.div`
  flex: 1;
`;

interface ErrorProps {
  history: any;
  error?: any;
  title?: string;
  showHeader?: boolean;
  showCard?: boolean;
  reload?: (e: any) => void;
}

const ErrorDisplay: FC<ErrorProps & RouteComponentProps> = (props) => {
  const { history, title, error, showHeader, showCard, reload } = props;

  const Content = () => {
    return (
      <CenteredColumn>
        { showHeader && (
          <>
            <CardTitle>
              <BackButton onClick={() => history.goBack()}>
                <Back />
              </BackButton>
              <Title>{ title }</Title>
              { !!reload && (
                <IconButton onClick={reload}>
                  <Refresh />
                </IconButton>
              )}
            </CardTitle>
            <Divider />
          </>
        )}
        <ErrorContainer>
          { error.message }
        </ErrorContainer>
      </CenteredColumn>
    )
  };

  return (
    <Page>
      { showCard ? <Card><Content /></Card> : <Content /> }
    </Page>
  );
};

ErrorDisplay.defaultProps = {
  error: 'Unable to load content',
  title: 'Go Back',
  showHeader: true,
  showCard: true,
};

export default withRouter(ErrorDisplay);
