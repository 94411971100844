export const getBrowser = (): string => {
  if (isOpera()) return 'Opera';
  if (isFirefox()) return 'Firefox';
  if (isSafari()) return 'Safari';
  if (isInternetExplorer()) return 'Internet Explorer';
  if (isEdge()) return 'Edge';
  if (isChrome()) return 'Chrome';
  if (isBlink()) return 'Blink';
  return 'Unknown';
};

export const isOpera = (): boolean => {
  // @ts-ignore
  return (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
};

export const isFirefox = (): boolean => {
  // @ts-ignore
  return typeof InstallTrigger !== 'undefined';
};

export const isSafari = (): boolean => {
  // @ts-ignore
  return /constructor/i.test(window.HTMLElement) || ((p) => p.toString() === '[object SafariRemoteNotification]')(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
};

export const isInternetExplorer = (): boolean => {
  // @ts-ignore
  return /*@cc_on!@*/false || !!document.documentMode;
};

export const isEdge = (): boolean => {
  // @ts-ignore
  return !this.isInternetExplorer && !!window.StyleMedia;
};

export const isChrome = (): boolean => {
  // @ts-ignore
  return !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
};

export const isBlink = (): boolean => {
  // @ts-ignore
  return (this.isChrome || this.isOpera) && !!window.CSS;
};
