import { Field } from './yup-mapper';
import * as Excel from 'exceljs';
import { Document, downloadFile } from './file-helper';
import { orderBy, get } from 'lodash';
import Moment from 'moment';

const DEFAULT_BG_COLOR = 'FF2CB7F8'; // argb value used to color the column headers
const DEFAULT_COLOR = 'FFFFFFFF'; // argb value used to color the column header text
const DEFAULT_NAME = 'scrumpro-import-template.xlsx';

/**
 * Un-nests all fields
 * @param {Field[]}            fields         An array of Fields (created from a schema with mapFieldsFromSchema)
 */
const getFields = (fields: Field[]): any[] => {
  return fields.reduce((acc: Field[], curr: Field) => {
    if (curr.fields) {
      const nestedFields = getFields(curr.fields);
      return [...acc, ...nestedFields];
    }

    return [...acc, curr];
  }, []);
};

/**
 * Create an import template and download it based on a list of Fields
 * @param {Field[]}             fields         An array of Fields (created from a schema with mapFieldsFromSchema)
 * @param {name}                name           An optional name for the template
 */
export const exportTemplate = async (fields: Field[], name?: string) => {
  const { fields: oFields, worksheet, workbook } = createWorkbook(fields);

  const defaults = oFields.map((field: Field) => get(field, 'typeInfo.description') || '');

  worksheet.addRow(defaults);

  downloadWorkbook(workbook, name);
};

/**
 * Exports processed data into the import template and download it based on a list of Fields
 * @param {Field[]}             fields          An array of Fields (created from a schema with mapFieldsFromSchema)
 * @param {any[]}               data            An array of data to export, object keys should match a field name property
 * @param {name}                name            An optional name for the template
 */
export const exportDataToExcel = async (fields: Field[], data: any[], name?: string) => {
  const { fields: oFields, worksheet, workbook } = createWorkbook(fields);

  data.forEach((d: any) => {
    const row = oFields.map((field: Field) => {
      const found = get(d, field.name) || '';
      if (field.typeInfo && field.typeInfo.type === 'date') {
        const date = Moment(found);
        if (date.isValid()) {
          return date.format('YYYY/MM/DD');
        }
      }
      return found;
    });
    worksheet.addRow(row);
  });

  downloadWorkbook(workbook, name);
};

interface WorkBookData {
  workbook: Excel.Workbook,
  worksheet: Excel.Worksheet,
  fields: Field[]
}

/**
 * Creates a workbook based on a list of Fields
 * @param {Field[]}             fields         An array of Fields (created from a schema with mapFieldsFromSchema)
 * @return {WorkBookData}       returns a workbook object containing a workbook, worksheet and a flattened list of Fields
 */
const createWorkbook = (fields: Field[]): WorkBookData => {
  const orderedFields = orderBy(getFields(fields), ['required'], ['desc']);
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('Data Import');

  worksheet.columns = orderedFields.map((field: Field) => Object.assign({ header: field.label + (field.required ? '*' : ''), key: field.name, width: 45 }));

  const headerRow = worksheet.getRow(1);
  headerRow.eachCell((cell, number) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: DEFAULT_BG_COLOR }
    };
    cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    cell.font = { name: 'Calibri', family: 4, size: 12, bold: true, color: { argb: DEFAULT_COLOR } };
  });

  return { workbook, worksheet, fields: orderedFields };
};

/**
 * Downloads a workbook
 * @param {Excel.Workbook}           workbook        An ExcelJS workbook to download
 */
const downloadWorkbook = (workbook: Excel.Workbook, name?: string) => {
  workbook.xlsx.writeBuffer().then((data) => {
    const document: Document = {
      file: data,
      name: name || DEFAULT_NAME,
      type: 'xlsx',
      isBufferArray: true
    };
    downloadFile(document);
  })
};
