import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setRedirectOnLogin } from '../../redux/actions';

const GuardRoute = ({ component: Component, guard, redirect, setRedirectOnLogin, returnOnLogin, history, ...rest }: any) => {
  if (returnOnLogin && !guard && history.location.pathname !== '/login') setRedirectOnLogin(history.location.pathname);
  return <Route {...rest} render={props => (guard ? <Component {...rest} /> : <Redirect to={redirect} />)} />;
};

const mapStateToProps = (state: any, props: any) => {
  return {
    ...props
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setRedirectOnLogin: (path: string) => {
      dispatch(setRedirectOnLogin(path));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GuardRoute));
