import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Grid } from '@material-ui/core';
import * as yup from 'yup';
import { Formik, Field, FormikValues, FormikHelpers } from 'formik';
import { presentSuccess, presentError } from '@necta-tech/alert';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { PrimaryButton, Form, CenteredColumn } from '../../global/style';
import { getFormData, TextField } from '../../services/helpers/form-helper';

const CONTACT = gql`
  mutation SEND_MESSAGE($newContactInput: NewContactInput!) {
    addContact(newContactData: $newContactInput) {
      email
      name
      message
    }
  }
`;

const { schema, fields, initialValues } = getFormData(
  yup.object().shape({
    name: yup
      .string()
      .required()
      .label('Name'),
    contactEmail: yup
      .string()
      .email()
      .trim()
      .required()
      .label('Email'),
    message: yup
      .string()
      .required()
      .label('Your Message')
  })
);

const ModalContainer = styled.div`
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background: white;
  border-radius: 0.2rem;
  width: 45%;
  max-width: 500px;
  max-height: 80vh;
  overflow: scroll;
  outline: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
  @media only screen and (max-width: 1024px) {
    width: 60%;
  }
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`;

const Button = styled(PrimaryButton)`
  && {
    width: 36%;
    height: 50px;
    margin-right: 0;
    margin-left: 0;
  }
`;

const ImportForm = styled(Form)`
  justify-content: space-evenly;
  width: 100%;
  margin-top: 20px;
`;

const CTextField = styled(TextField)`
  && {
    width: 100%;
  }
`;

const Title = styled.h1`
  width: 80%;
  font-size: 30px;
  text-align: left;
  color: #0f4c81;
`;

interface PropTypes {
  button: React.Component<any>;
  text: string;
}

const ContactUs: React.FC<any> = ({ button: Trigger, text }) => {
  const [open, setOpen] = useState(false);

  const [sendMessage] = useMutation(CONTACT);

  const submitForm = async (values: FormikValues, actions: FormikHelpers<any>) => {
    actions.setSubmitting(true);

    try {
      //send email here
      console.log(values);
      await sendMessage({
        variables: {
          newContactInput: {
            email: values.contactEmail.replace(/\s/g, '').toLowerCase(),
            name: values.name,
            message: values.message
          }
        }
      });
      presentSuccess('email successfully sent!');
    } catch (e) {
      presentError(e, 'Unable to send email');
    } finally {
      actions.setSubmitting(false);
      setOpen(false);
    }
  };

  const handleReset = (resetForm: Function) => {
    resetForm();
  };

  return (
    <>
      <Trigger onClick={() => setOpen(true)}>{text || 'Contact Us'}</Trigger>
      <Modal open={open} style={{ zIndex: 100, backgroundColor: 'none' }}>
        <ModalContainer>
          <Formik validationSchema={schema} initialValues={initialValues} validateOnBlur onSubmit={submitForm} onReset={handleReset}>
            {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
              <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <CenteredColumn>
                  <Title>Contact Us</Title>
                  <Grid>
                    <Field {...fields.name} component={CTextField} />
                    <Field {...fields.contactEmail} component={CTextField} />
                    <Field {...fields.message} component={CTextField} />
                  </Grid>
                  <ImportForm>
                    <Button disabled={isSubmitting} onClick={() => setOpen(false)}>
                      Cancel
                    </Button>
                    <Button disabled={isSubmitting} type="submit">
                      Send
                    </Button>
                  </ImportForm>
                </CenteredColumn>
              </form>
            )}
          </Formik>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default ContactUs;
