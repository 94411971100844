import React, { FC, useMemo } from 'react';
import ContentLoader from 'react-content-loader';

const defaultHeight = 85;
const ADD_HEIGHT = 80;
const BREAK_POINT = 599 * 0.9216;

const generateArray = (n: number) => [...Array(n)].map((_, index) => index + 1);

interface ListLoaderProps {
  width: number,
  speed: number,
  backgroundColor: string,
  foregroundColor: string,
  iterations: number,
}

const ListLoader: FC<ListLoaderProps> = (props) => {
  const { backgroundColor, foregroundColor, speed, width, iterations } = props;

  const large = useMemo(() => width > BREAK_POINT, [width]);

  const iArray = useMemo(() => generateArray(iterations), [iterations]);

  const height = useMemo(() => (!large ? defaultHeight + ADD_HEIGHT : defaultHeight) * (iterations || 1) + (large ? 0 : 15), [iterations, large]);

  const calcHeight = useMemo(() => !large ? defaultHeight + ADD_HEIGHT : defaultHeight, [large]);

  return (
    <ContentLoader
      speed={speed}
      width={width}
      height={height}
      viewBox={'0 0 ' + width + ' ' + height}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      {large ?
        (
          <>
            <rect x="10" y="0" rx="3" ry="3" width="60" height="10" />
            <rect x={width*0.9126}  y="0" rx="3" ry="3" width="40" height="10" />
          </>
        ) : (
          <rect x={width*0.4} y="0" rx="3" ry="3" width={width*0.2} height="13" />
        )}
      {
        iArray.map((i: number) => {
            return (
              <React.Fragment key={i}>
                {large ? (
                  <>
                    <circle cx="30" cy={36 + (defaultHeight * (i - 1))} r="20" />
                    <rect x="60" y={16 + (defaultHeight * (i - 1))} rx="3" ry="3" width={width*0.68} height="40" />
                    <rect x={width*0.68 + 80} y={16 + (defaultHeight * (i - 1))} rx="3" ry="3" width={width*0.2} height="40" />
                  </>
                ) : (
                  <>
                    <circle cx={width*0.5} cy={46 + ((defaultHeight + ADD_HEIGHT ) * (i - 1))} r="20" />
                    <rect x={width*0.25} y={70 + ((defaultHeight + ADD_HEIGHT ) * (i - 1))} rx="3" ry="3" width={width*0.5} height="25" />
                    <rect x={width*0.05} y={100 + ((defaultHeight + ADD_HEIGHT ) * (i - 1))} rx="3" ry="3" width={width*0.9} height="30" />
                  </>
                )}
                <rect x="10" y={70 + (large ? 0 : ADD_HEIGHT) + (calcHeight * (i - 1))} rx="3" ry="3" width={width < 20 ? width : width - 20} height="2" />
              </React.Fragment>
            );
          }
        )}
    </ContentLoader>
  );
};

ListLoader.defaultProps = {
  backgroundColor: '#f3f3f3',
  foregroundColor: '#e6f7fe',
  speed: 2,
  iterations: 2,
};

export default ListLoader;
