import React, { useState } from 'react';
import styled from 'styled-components';
import { InputAdornment } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { CTextField } from './style';

const Field = styled(CTextField)`
    && {
        width: 100%
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: inherit;
`;

const fieldProps = {
    id: 'outlined-password-input',
    label: 'Password',
    name: 'password',
    autoComplete: 'current-password',
}

type PasswordProps = {
    value: string,
    onChange: any,
    variant?: string
}

const Password = ({value, variant, onChange, ...additionalProps}: any) => {

    const [ show, setShow ] = useState<boolean>(false);

    return (
        <Container>
            <Field
                {...fieldProps}
                variant={variant ? variant : 'filled'}
                type={show ? 'text' : 'password'}
                value={value}
                onChange={onChange}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton aria-label="Toggle password visibility" onClick={() => setShow(!show)}>
                                {show ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                    autoComplete: 'new-password'
                }}
                {...additionalProps}
            />
        </Container>
    )
};

export default Password;