import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import { presentSuccess, presentError } from '@necta-tech/alert';
import { useMutation } from 'react-apollo';
import { ExpandLess, ExpandMore, KeyboardArrowLeft as Back } from '@material-ui/icons';
import { getThumbnail } from '@necta-tech/s3';
import { Formik, Field, FormikValues, FormikHelpers } from 'formik';
import Swal from 'sweetalert2';
import * as yup from 'yup';
import withReactContent from 'sweetalert2-react-content';
import { setCurrentUser } from '../../redux/actions';
import { NEW_MANAGER_MUTATION } from '../../global/gql/users';
import FileUpload from '../../components/file-upload/FileUpload';
import {
  PasswordField,
  SelectField,
  TextField as FormikTextField,
  getFormData,
  generatePassword,
  LocationPicker
} from '../../services/helpers/form-helper';
import {
  Page,
  CenteredColumn,
  Form,
  CardTitle,
  Divider,
  Required,
  SubTitle,
  Card,
  BackButton,
  PrimaryButton,
  Collapser,
  Collapse,
  Collapsable,
  ImageContainer,
  ImageClick,
  DropzoneContainer,
  DropzoneInner,
  Highlight
} from '../../global/style';
import { useExpanded } from '../../custom-hooks/formhooks';
import OrgsDropdown from '../../components/organisation/OrgDropDown';
import Dropzone from 'react-dropzone';
import { getActiveOrgId, getIsAdmin } from '../../global/helpers/selectors';
import { mapArrayToObjects } from '../../global/helpers/data-maps';
import { COUNTRIES, SA_PROVINCES } from '../../global/constants';
import ErrorDisplay from '../../components/error-display/ErrorDisplay';
import playerSchema from '../player/schema';

const MySwal = withReactContent(Swal);

const provinces = mapArrayToObjects(SA_PROVINCES);
const countries = mapArrayToObjects(COUNTRIES);

const fullSchema = playerSchema.shape({
  dateOfBirth: yup.string().nullable().label('Date of Birth'),
  addToOrg: yup.string().nullable().label('Organisation'),
});

const { schema, fields, initialValues } = getFormData(fullSchema);

const TextField = styled(FormikTextField)`
  && {
    width: 100%;
  }
`;

const CreateButton = styled(PrimaryButton)`
  && {
    height: 56px;
    width: 100%;
    border-radius: 0;
  }
`;

const ManagerAdd = ({ cognitoUser, currentUser, isAdmin, activeOrgId, props }: any) => {
  const [defaultValues] = useState<any>({ ...initialValues, addToOrg: activeOrgId });

  const [addUser, { loading }] = useMutation(NEW_MANAGER_MUTATION);

  const { expanded, handleOnExpandedClick } = useExpanded({
    location: false,
    access: false,
    security: false
  });

  const onSubmit = async (values: FormikValues, actions: FormikHelpers<any>) => {
    actions.setSubmitting(true);
    try {
      const newUser = { ...values, role: 'Organisation' };
      await addUser({ variables: { newUser } });
      presentSuccess('Manager added Successfully!', 'Success');
      actions.resetForm(initialValues);
    } catch (e) {
      presentError(e, 'Unable to add manager!');
    } finally {
      actions.setSubmitting(false);
    }
  };

  const imageUploaded = (setFieldValue: any) => (key: string) => {
    setFieldValue('logoUrl', key);
    MySwal.close();
  };

  const uploadImage = (setFieldValue: any, defaultFiles?: File[]) => {
    MySwal.fire({
      title: 'Upload Profile Picture',
      html: <FileUpload path="profilePictures" id={cognitoUser.username} type="image" single={true} onComplete={imageUploaded(setFieldValue)} defaultFile={defaultFiles} />,
      width: '800px',
      showConfirmButton: false,
      showCloseButton: true
    });
  };

  if (!isAdmin && !activeOrgId) return <ErrorDisplay title={'Add Manager'} error={'You have no active organisation'} />;

  return (
    <Page>
      <Card>
        <CenteredColumn>
          <Formik initialValues={defaultValues} validateOnBlur validationSchema={schema} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
              <>
                <CardTitle>
                  <BackButton onClick={() => props.history.goBack()}>
                    <Back />
                  </BackButton>
                  Add Manager
                </CardTitle>
                <Divider />
                <Required>
                  <SubTitle>User Information</SubTitle>
                  <h3>*required</h3>
                </Required>
                <Form>
                    <ImageContainer>
                      <img onClick={() => uploadImage(setFieldValue)} alt="Profile Pic" src={!values.logoUrl ? require('../../assets/img/default-org.png') : getThumbnail(values.logoUrl)} />
                      <ImageClick>
                        <Dropzone onDrop={acceptedFiles => uploadImage(setFieldValue, acceptedFiles)} disabled={loading}>
                          {({ getRootProps, getInputProps }) => (
                            <DropzoneContainer {...getRootProps()}>
                              <input {...getInputProps()} />
                              <DropzoneInner>
                                <h4>Profile picture<br />
                                  Drag and Drop or <Highlight>Click</Highlight> to search</h4>
                              </DropzoneInner>
                            </DropzoneContainer>
                          )}
                        </Dropzone>
                      </ImageClick>
                    </ImageContainer>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Field {...fields.firstname} component={TextField} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field {...fields.lastname} component={TextField} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field {...fields.email} component={TextField} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field {...fields.contactNumber} component={TextField} />
                      </Grid>
                    </Grid>
                </Form>
                <Divider />
                <Collapser onClick={(e: any) => handleOnExpandedClick(e, 'location')}>
                  <Required>
                    <SubTitle>Location</SubTitle>
                    {expanded.location ? <ExpandLess /> : <ExpandMore />}
                  </Required>
                </Collapser>
                <Collapse in={expanded.location}>
                  <Collapsable>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Field {...fields.locationAddress} component={LocationPicker} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field {...fields.locationCountry} component={SelectField} options={countries} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field {...fields.locationProvince} component={SelectField} options={provinces} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field {...fields.locationCity} component={TextField} />
                      </Grid>
                    </Grid>
                  </Collapsable>
                </Collapse>
                <Divider />
                { cognitoUser.permissions === 'Admin' && (
                  <>
                    <Collapser onClick={(e: any) => handleOnExpandedClick(e, 'access')}>
                      <Required>
                        <SubTitle>Access&nbsp;<span>*</span></SubTitle>
                        {expanded.security ? <ExpandLess /> : <ExpandMore />}
                      </Required>
                    </Collapser>
                    <Collapse in={expanded.access}>
                      <Collapsable>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <OrgsDropdown Render={Field} field={fields.addToOrg} />
                          </Grid>
                        </Grid>
                      </Collapsable>
                    </Collapse>
                    <Divider />
                  </>
                )}
                <Collapser onClick={(e: any) => handleOnExpandedClick(e, 'security')}>
                  <Required>
                    <SubTitle>Security&nbsp;<span>*</span></SubTitle>
                    {expanded.security ? <ExpandLess /> : <ExpandMore />}
                  </Required>
                </Collapser>
                <Collapse in={expanded.security}>
                  <Collapsable>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={9}>
                        <Field {...fields.password} component={PasswordField} />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <PrimaryButton onClick={() => {
                          setFieldValue('password', generatePassword())
                        }}>
                          Generate
                        </PrimaryButton>
                      </Grid>
                    </Grid>
                  </Collapsable>
                </Collapse>
                <CreateButton disabled={loading || isSubmitting} onClick={() => handleSubmit()}>
                  {loading || isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Add Manager'}
                </CreateButton>
              </>
            )}
          </Formik>
        </CenteredColumn>
      </Card>
    </Page>
  );
};

const mapStateToProps = (state: any, props: any) => {
  return {
    cognitoUser: state.cognitoUser,
    currentUser: state.currentUser,
    isAdmin: getIsAdmin(state),
    activeOrgId: getActiveOrgId(state),
    props
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setCurrentUser: (currentUser: any) => {
      dispatch(setCurrentUser(currentUser));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ManagerAdd)
);
