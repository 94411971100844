import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useLazyQuery, useMutation } from 'react-apollo';
import MaterialTable, { Column } from 'material-table';
import { Edit, Delete, Block, Cached } from '@material-ui/icons';
import  { presentError, presentSuccess } from '@necta-tech/alert';
import { withRouter } from 'react-router-dom';
import { DELETE_USER_QUERY, MANAGERS_QUERY } from '../../global/gql/users';
import Swal from "sweetalert2";
import { getActiveOrgId, getIsAdmin, getIsCurrentOrgOwner } from '../../global/helpers/selectors';
import { UPDATE_ORG_ACCESS } from '../../global/gql/orgAccess';
import { getDateFull } from '../../global/helpers/table-helper';
import { TableOuter, TableContainer } from '../../global/style';

const columns: Column<any>[] = [
  {
    title: 'First Name',
    field: 'firstname'
  }, {
    title: 'Surname',
    field: 'lastname'
  }, {
    title: 'Email',
    field: 'email',
    filtering: true,
  }, {
    title: 'Cell',
    field: 'contactNumber',
    filtering: true,
  }, {
    title: 'Shortid',
    field: 'shortid',
    hidden: true,
    filtering: true,
  }, {
    title: 'Database ID',
    field: 'id',
    hidden: true,
    filtering: false,
    editable: 'never'
  }, {
    title: 'Signed Up',
    field: 'createdAt',
    filtering: false,
    sorting: true,
    editable: 'never',
    render: (r: any) => r ? getDateFull(r.createdAt) : '',
  }
];

const ManagerList : React.FC<any> = ({ cognitoUser, currentUser, activeOrgId, isOwner, isAdmin, history, props }) => {
  const [users, setUsers] = useState([]);

  const [fetchUsers, { loading, data }] = useLazyQuery(MANAGERS_QUERY, {
    variables: { userQuery: { } },
    fetchPolicy: 'network-only',
    onError: (error: any) => {
        console.log(error)
    }
  });

  const [handleDelete, { loading: isLoading }] = useMutation(DELETE_USER_QUERY, {
    onError: error => {
        presentError(error, "Unable to delete");
    }
  });

  const [handleRemoveFromOrg, { loading: isRemoveLoading }] = useMutation(UPDATE_ORG_ACCESS, {
    onError: error => {
      presentError(error, "Unable to revoke access");
    }
  });

  useEffect(() => {
    if (data && data.managers) {
      setUsers(data.managers);
    } else {
      fetchUsers();
    }
  }, [loading, fetchUsers, data]);

  const deleteUser = useCallback(async (e: any, row: any) => {
    if (!isAdmin) {
      return
    }
    if (row.id === cognitoUser.username) {
      presentError('You may not delete yourself');
      return;
    }
    const confirm = await Swal.fire({
      title: 'Delete user',
      text: 'This will permanently delete the user',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete'
    });
    if (!confirm.value) {
      return;
    }
    const res = await handleDelete({ variables: { id: row.id } });
    if (res) { // Present success
      presentSuccess("User deleted successfully", "Success", true);
      const filtered = users.filter((d: any) => d.id !== row.id);
      setUsers(filtered);
    }
  }, [setUsers, users, handleDelete, isAdmin, cognitoUser.username]);

  const removeUserFromOrg = useCallback(async (e: any, row: any) => {
    if (!isOwner && !isAdmin) {
      return
    }
    if (row.id === cognitoUser.username) {
      presentError('You may not remove yourself. Please appoint a new owner by editing your organisation and then leave by going to My Account -> Org Access.');
      return;
    }
    const confirm = await Swal.fire({
      title: 'Remove user',
      text: 'This will revoke this users access to your organisation',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Revoke'
    });
    if (!confirm.value) {
      return;
    }
    const res = await handleRemoveFromOrg({ variables: { id: activeOrgId, updateOrgAccess: { archived: true } } });
    if (res) { // Present success
      presentSuccess("Access revoked successfully", "Success", true);
      const filtered = users.filter((d: any) => d.id !== row.id);
      setUsers(filtered);
    }
  }, [setUsers, users, handleRemoveFromOrg, isAdmin, isOwner, cognitoUser.username, activeOrgId]);


  const editUser = useCallback(async (e: any, row: any) => {
    if (!isOwner && !isAdmin && row.id !== cognitoUser.username) {
      presentError('Only the organisation owner or an admin may edit other manager users.');
      return;
    }
    history.push('/users/edit/' + row.shortid);
  }, [isAdmin, isOwner, history, cognitoUser.username]);

  return (
    <TableContainer>
      <TableOuter onContextMenu={e => console.log('do something')}>
        <MaterialTable
          title={'Manager List'}
          columns={columns}
          options={{
            emptyRowsWhenPaging: false,
            columnsButton: true,
            pageSize: 10,
            filtering: true,
            exportButton: true
          }}
          isLoading={loading || isLoading || isRemoveLoading}
          data={users}
          actions={[
            {
              tooltip: 'Edit',
              icon: () => <Edit />,
              onClick: editUser,
            },
            {
              tooltip: 'Delete user',
              icon: () => <Delete />,
              onClick: deleteUser,
              hidden: !isAdmin
            },
            {
              tooltip: 'Revoke Access',
              icon: () => <Block />,
              onClick: removeUserFromOrg,
              hidden: !isOwner
            },
            {
              tooltip: 'Refresh',
              icon: () => <Cached />,
              onClick: fetchUsers,
              isFreeAction: true
            }
          ]}
        />
      </TableOuter>
    </TableContainer>
  )
};

const mapStateToProps = (state: any, props: any) => {
    return {
      cognitoUser: state.cognitoUser,
      currentUser: state.currentUser,
      isOwner: getIsCurrentOrgOwner(state),
      isAdmin: getIsAdmin(state),
      activeOrgId: getActiveOrgId(state),
      ...props,
    }
};

const mapDispatchToProps = (dispatch: Function) => {
    return {}
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManagerList));
