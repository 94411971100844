import React, { FC, useCallback, useState } from 'react';
import { useMutation } from 'react-apollo';
import { connect } from 'react-redux';
import { presentError, presentSuccess } from '@necta-tech/alert';
import { Formik, Field, FormikValues, FormikHelpers } from 'formik';
import gql from 'graphql-tag';
import styled from 'styled-components';
import * as yup from 'yup';
import { PERMISSIONS } from '../../global/constants';
import { mapArrayToObjects } from '../../global/helpers/data-maps';
import { SelectField, getFormData } from '../../services/helpers/form-helper';
import { getIsAdmin } from '../../global/helpers/selectors';
import AutoSave from '../../components/autosave';

const schemaObject = yup.object().shape({
  role: yup
    .string()
    .label('Role'),
});

const { schema, fields } = getFormData(schemaObject);

const permissions = mapArrayToObjects(PERMISSIONS);

const UPDATE_USER = gql`
    mutation UPDATE_USER_MUTATION($id: String!, $role: String!) {
        adminUpdateUser(id: $id, user: { role: $role }) {
            role
        }
    }
`;

const Select = styled(SelectField)`
  && {
    min-width: 150px;
  }
`;

interface PermissionFormProps {
  user: any,
  onChange: (role: string) => void;
}

const PermissionForm: FC<PermissionFormProps> = ({ user, onChange }) => {

  const [defaultValues] = useState({ role: user.role });
  const [handleEdit] = useMutation(UPDATE_USER);

  const onSubmit = async (values: FormikValues, actions: FormikHelpers<any>) => {
    actions.setSubmitting(true);
    try {
      if (!user.role || user.role === values.role) {
        return;
      }
      const res = await handleEdit({ variables: { id: user.id, role: values.role } });
      if (res) {
        onChange(res.data.adminUpdateUser.role);
        presentSuccess('User role has been updated successfully');
      }
    } catch(e) {
      presentError(e, 'Unable to update');
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik validationSchema={schema} initialValues={defaultValues} enableReinitialize validateOnBlur onSubmit={onSubmit}>
      {({isSubmitting}) => (
        <>
          <Field {...fields.role} component={Select} variant={'standard'} options={permissions} disabled={isSubmitting} />
          <AutoSave />
        </>
      )}
    </Formik>
  );
};

interface PermissionSelectProps {
  isAdmin: boolean,
  user: any,
  onChange?: (data: any) => void;
}

const PermissionSelect: FC<PermissionSelectProps> = ({ isAdmin, user, onChange }) => {

  const [userData, setUserData] = useState(user);

  const userChanged = useCallback((role: string) => {
    const newData = {...userData, role };
    setUserData(newData);
    if (onChange) onChange(newData);
  }, [userData, setUserData, onChange]);

  if (!userData || !isAdmin) return null;

  return <PermissionForm user={userData} onChange={userChanged} />;
};

const mapStateToProps = (state: any, props: any) => {
  return {
    isAdmin: getIsAdmin(state),
    ...props,
  }
};

const mapDispatchToProps = (dispatch: Function) => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionSelect);
