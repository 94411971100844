import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import Collapsed from '@material-ui/core/Collapse';
import Field from '@material-ui/core/TextField';
import Divide from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { FormControl } from '@material-ui/core';

export const PrimaryButton = styled(Button)`
  && {
    width: 100%;
    height: 3.5rem;
    background-color: ${p => p.theme.primary};
    color: white;
    border-radius: 3px;
    box-shadow: 4px 4px 4px rgba(36, 22, 55, 0.5);
    :disabled {
        background-color: ${p => p.theme.primary + '66'};
        color: white;
        box-shadow: 4px 4px 4px rgba(36, 22, 55, 0.5);
    }
    :hover {
        background-color: ${p => p.theme.primary + 'AA'};
        color: white;
    }
    :focus {
      background-color: ${p => p.theme.primary + 'A0'};
      color: white;
    }
  }
`

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;

  width: 100%;

  padding-top: 20px;
  padding-bottom: 20px;
`;

export const CenteredColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
`;

export const CardTitle = styled.h1`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 30px;
  line-height: 30px;
  word-wrap: break-word;
  width: 100%;
  margin: 0;
  @media only screen and (max-width: 768px) {
    font-size: 22px;
    line-height: 22px;
  }
`;

export const BackButton = styled(Button)`
  && {
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
`;

export const Divider = styled(Divide)`
  && {
    width: 100%;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  flex-wrap: wrap;
  margin-bottom: 20px;
  align-self: center;
`;

export const ImportForm = styled(Form)`
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 0;
`;

export const TextField = styled(Field)`
  && {
    width: 42%;
    margin-right: 20px;
    margin-left: 20px;
  }
`;

export const Collapser = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  width: 100%;
  cursor: pointer;

  :hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const Collapse = styled(Collapsed)`
  && {
    width: 80%;
  }
`;

export const Collapsable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: flex-start;
  padding-top: 10px;
  padding-bottom: 20px;

  width: inherit;
`;

export const CenterColumn = styled.div<{width?: number}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: ${props => props.width ? props.width : '100'}%;
`

export const Padder = styled.div`
    width: 100%;
    height: 45px;
`

export const DangerButton = styled(Button)`
  && {
    width: 100%;
    height: 3.5rem;
    background-color: rgb(244, 67, 54);
    color: white;
    border-radius: 3px;
    box-shadow: 4px 4px 4px rgba(36, 22, 55, 0.5);
    :disabled {
      background-color: rgb(244, 67, 54);
        color: white;
        box-shadow: 4px 4px 4px rgba(36, 22, 55, 0.5);
    }
    :hover {
        background-color: rgb(244, 67, 54) !important;
        color: white;
    }
    :focus {
      background-color: rgb(255, 98, 86) !important;
      color: white;
    }
  }
`
export const Required = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  h3 {
    font-size: 16px;
    line-height: 143.9%;
    text-transform: uppercase;
    color: #ccc;
    margin-right: 10px;
  }
`;

export const SubTitle = styled.h2`
  color: #0f4c81;
  font-size: 18px;
  line-height: 143.9%;
  flex: 1;

  span {
    font-size: 21px;
    text-transform: uppercase;
    color: #ccc;
  }
`;

export const Label = styled.h3`
  color: #0f4c81;
  font-size: 18px;
  line-height: 143.9%;
  margin-bottom: 15px;
`;

export const Description = styled.p`
  width: 100%;
  font-size: 14px;
  line-height: 143.9%;
  color: #8d8d8d;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const SubmitButton = styled(PrimaryButton)`
  && {
    width: 100%;
    height: 50px;
  }
`;

export const ImportButton = styled(PrimaryButton)`
  && {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const CreateButton = styled(ImportButton)`
  && {
    height: 56px;
    width: 100%;
    margin: 0;
    border-radius: 0;
  }
`;

export const AssignButton = styled.div`
  width: 75%;

  font-weight: lighter;
  font-size: 24px;
  line-height: 143.9%;

  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;

  text-align: left;

  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);

  border-radius: 3px;

  background-color: #f294c5;
  color: black;

  transition: background-color 0.2s ease;

  :hover {
    background-color: rgba(242, 148, 197, 0.7);
  }

  cursor: pointer;
`;

export const TableOuter = styled(Paper)`
  overflow-x: scroll;
  width: 100%;
  padding-bottom: 0;
  flex-shrink: 0;

  && {
    box-shadow: none;
    border: none;
  }
`;

export const CustomPadder = styled.div<{ width?: string; height?: string; marginBottom?: string }>`
  width: ${props => (props.width ? props.width : '100%')};
  height: ${props => (props.height ? props.height : '100%')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
`;

export const Success = styled.span`
  color: ${p => p.theme.success};
`;

export const Warning = styled.span`
  color: ${p => p.theme.warning};
`;

export const Rating = styled.div`
  color: #eae112;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  p {
    padding: 5px 0px 0px 5px;
  }
`;

export const Card = styled(Paper)`
  && {
    width: 90%;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    flex-shrink: 0;
    @media only screen and (max-width: 768px) {
      width: 96%;
    }
  }
`;

export const TableContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: #dfdfdf;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 5%;
    padding-right: 5%;
    @media only screen and (max-width: 768px) {
      padding-left: 2%;
      padding-right: 2%;
    }
`;

export const Line = styled.div`
  width: 100%;
  border-bottom: 2px solid ${p => p.theme.primary};
`;

export const SelectOuter = styled(FormControl)`
  margin-left: 10px !important;
  background-color: #3d3d3d;
  .MuiSelect-selectMenu {
    color: white !important;
    padding: 5px 30px 5px 12px !important;
    text-transform: uppercase;
    font-size: 12.5px;
    letter-spacing: 2px;
  }
  svg {
    color: white !important;
  }
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
`;

export const ImageContainer = styled.div`
  img {
    height: 120px;
    width: 120px;
    align-self: flex-start;
    margin: 5px;
    border: 2px solid ${p => p.theme.primary};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    transition: box-shadow 0.3s;
  }
  img:hover {
    box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.4);
  }
  width: 100%;
  padding: 0.75rem 0 0.75rem 0;
  align-self: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

export const ImageClick = styled.div`
  border: 1px solid #ccc;
  border-radius: 2px;
  align-self: flex-start;
  height: 121px;
  margin-top: 5px;
  flex: 1;
`;

export const Highlight = styled.span`
  color: ${p => p.theme.primary};
`;

export const DropzoneInner = styled.div`
  align-self: center;
  width: 100%;
`;

export const DropzoneContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  text-align: center;
  align-self: center;
  color: #0c0c0c;
  line-height: 30px;
  h4 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;
