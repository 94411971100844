import styled from 'styled-components';
import { TextField as Field } from '../../services/helpers/form-helper';
import { PrimaryButton, BackButton as Back, Card } from '../../global/style';

export const BackDrop = styled.div`
  width: 100vw;
  height: 100vh;

  z-index: -1;

  position: absolute;

  background: linear-gradient(0deg, rgb(10, 53, 90, 0.5), rgb(10, 53, 90, 0.5)), url(${require('../../assets/backdrop.jpg')});

  background-size: cover;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  width: 450px;
  color: white !important;

  img {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  @media only screen and (max-width: 768px) {
    width: 90%;
    flex-direction: column;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;

  width: 100vw;
  height: 100vh;
`;

export const CenterColumn = styled.div<{ width?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;

  width: ${props => (props.width ? props.width : '100')}%;
`;

export const Padder = styled.div`
  width: 100%;
  height: 45px;
`;
export const CTextField = styled(Field)`
  width: 100%;
  height: 60px;
  margin-bottom: 1.5rem !important;

  .white {
    textarea {
      color: white;
    }
  }
`;

export const CardTitle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding-top: 20px;
  padding-bottom: 20px;
  h1 {
    text-align: center;
    margin: 0;
    margin-top: 16px;
    flex: 1;
  }
`;

export const ResetLink = styled.a`
  margin-top: 5px;
  float: right;
  margin-right: 5px;
  font-size: 12px;
  cursor: pointer;
`;

export const Form = styled.form`
  width: 100%;
  max-width: 400px;
`;

export const AltForm = styled.div`
  width: 100%;
  max-width: 400px;
`;

export const ContactOuter = styled.div`
  text-align: center;
  margin-top: 15px;
`;

export const RegisterOuter = styled.div`
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const ContactLink = styled.a`
  font-size: 12px;
  cursor: pointer;
`;

export const BrandLogo = styled.img`
  width: 15%;
  padding: 3px;
  height: auto;
`;

export const TextLogo = styled.img`
  width: 78%;
  height: auto;
  padding: 10px;
`;

export const RegisterLink = styled.a`
  font-size: 12px;
  cursor: pointer;
`;

export const Submit = styled(PrimaryButton)`
  && {
      width: 90%;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      @media only screen and (max-width: 768px) {
        width: 96%;
      }
      max-width: 600px;
    }
`;

export const LoginCard = styled(Card)`
  && {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0px;
    padding-bottom: 15px;
    max-width: 600px;
  }
`;

export const BackButton = styled(Back)`
  && {
    width: 10%;
    height: 70px;
    margin-right: 15px;
    border-radius: 0;

    svg {
      width: 30px;
      height: 30px;
    }

    @media only screen and (max-width: 768px) {
      min-width: 50px;
      margin-right: 10px;
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
`;

export const Fields = styled.div`
  padding: 2px;
`;