import moment from 'moment';

export const getAge = (date: string) => {
    if (date == null) return '';
    const dob = moment(date);
    const current = moment();
    const age = current.diff(dob, 'years');
    return age;
};

export const findAge = (terms: any, dateOfBirth: any): boolean => {
    if (terms.length <= 0) return true;
    if (dateOfBirth == null) return false;
    const dob = getAge(dateOfBirth);

    const checkRange = (t: string): boolean => {
        if (t.includes('-')) {
            const split = t.split('-');
            const tLower = parseInt(split[0]);
            const tUpper = parseInt(split[1]);
            if (dob >= tLower && dob <= tUpper) return true;
        } else if (parseInt(t) === dob) {
            return true;
        }
        return false;
    };

    if (Array.isArray(terms)) {
        for (const t of terms) {
            if (checkRange(t)) return true;
        }
    } else {
        if (checkRange(terms)) return true;
    }

    return false;
};

export const getDateShort = (date: string) => {
    if (date == null) return '';
    const d = moment(date);
    return d.format('DD MMM \'YY');
};

export const getDateFull = (date: string) => {
    if (date == null) return '';
    const d = moment(date);
    return d.format('DD MMM \'YY - HH:mm');
};

export const findGenericExact = (terms: any, match: any): boolean => {
    if (terms.length <= 0) return true;
    if (match == null) return false;
    if (Array.isArray(terms)) {
        for (const t of terms) {
            if (t === match) return true;
        }
    } else {
        if (terms === match) return true;
    }
    return false;
};

export const findGeneric = (terms: any, match: any): boolean => {
    if (terms.length <= 0) return true;
    if (match == null) return false;
    if (Array.isArray(terms)) {
        for (const t of terms) {
            if (match.toLowerCase().includes(t.toLowerCase())) return true;
        }
    } else {
        if (match.toLowerCase().includes(terms.toLowerCase())) return true;
    }
    return false;
};
