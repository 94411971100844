import React, { useState, useEffect } from 'react';
import { useLazyQuery } from 'react-apollo';
import { SelectField } from '../../services/helpers/form-helper';
import LoaderContainer from '../loaders/LoaderContainer';
import gql from 'graphql-tag';

const ORGS_SELECT_QUERY = gql`
    query ORGS_SELECT_QUERY {
        organisations (organisationQuery: {}) {
            id
            name
        }
    }
`;

interface Props {
  field: any;
  Render: any;
}

const OrgsDropdown: React.FC<Props> = ({ field, Render }) => {
  const [orgs, setOrgs] = useState<any>([]);

  const [fetchOrgs] = useLazyQuery(ORGS_SELECT_QUERY, {
    variables: { organisationQuery: { } },
    onCompleted: (result: any) => {
      console.log(result);
      setOrgs(result.organisations);
    },
    onError: (error: any) => {
      console.log(error);
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    fetchOrgs();
  }, [fetchOrgs]);

  if (orgs.length === 0) return <LoaderContainer type={'field'} showCard={false} showHeader={false} iterations={1} marginTop={'0'} marginBottom={'0'} />;
  return <Render {...field} component={SelectField} labelWidth={110} options={orgs} />;
};

export default OrgsDropdown;
