import React, { useCallback, useMemo, useState } from 'react';
import { Delete, Edit, KeyboardArrowLeft as Back } from '@material-ui/icons';
import { presentSuccess } from '@necta-tech/alert';
import { get } from 'lodash';
import MaterialTable from 'material-table';
import Moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ObjectSchema } from 'yup';
import { BackButton, CardTitle, PrimaryButton } from '../../../global/style';
import MultiButton from '../../multi-button/MultiButton';
import { exportTemplate } from '../../../services/helpers/template-exporter';
import { Field } from '../../../services/helpers/yup-mapper';
import SpreadsheetImport, { Column } from '../spreadsheet-import';
import { ButtonsContainer, CPrimaryButton, TableOuter } from '../style';

const MySwal = withReactContent(Swal);

const OPTIONS = {
  emptyRowsWhenPaging: false,
  columnsButton: true,
  pageSize: 20,
  filtering: true,
};

const getRowDisplay = (row: any, field: any) => {
  switch (get(field, 'typeInfo.type')) {
    case 'date':
      return Moment(row[field.name]).format('YYYY/MM/DD');
    default:
      return get(row, field.name);
  }
};

const getColumns = (fields: any[]): Column[] => {
  const columns: any[] = [];

  fields.forEach((field: any) => {
    if (field.fields) {
      const nestedCols = getColumns(field.fields);
      nestedCols.forEach((c: Column) => columns.push(c));
      return;
    }

    const column: Column = {
      title: field.label,
      field: field.name,
      type: field.type || 'string',
      render: (row: any) => getRowDisplay(row, field),
      hidden: !field.required
    };
    columns.push(column);

  });

  return columns;
};

interface ImportTableProps {
  validationSchema: ObjectSchema<any>;
  onComplete: (data?: any) => void;
  editRow: (row?: any) => Promise<any>;
  text?: string;
  fields: Field[];
  initialValues: any[];
}

const ImportTables: React.FC<ImportTableProps> = ({ validationSchema, fields, initialValues, onComplete, editRow, text}) => {
  const [state, setState] = useState<any>(null);

  const columns: Column[] = useMemo(() => getColumns(fields), [fields]);

  const validateRows: any = useCallback(async (data: any) => {
    const validData: any[] = [];
    const invalidData: any[] = [];

    for (const row of data) {
      const defaultRow = { ...initialValues, ...row };
      try {
        const val = await validationSchema.validate(defaultRow, { strict: false, stripUnknown: true });
        if (typeof val.error !== 'undefined') {
          delete val.error;
        }
        validData.push(val);
      } catch (error) {
        if (error.errors) invalidData.push({ ...defaultRow, error: error.errors[0] });
        else invalidData.push({ ...defaultRow, error: 'Processing error' });
      }
    }

    return { validData, invalidData };
  }, [initialValues, validationSchema]);

  const parseData = useCallback(async (data: any[]) => {
    const { validData, invalidData } = await validateRows(data);
    const invalidColumns = [...columns, { title: 'Error', field: 'error' }];

    setState({ validData, invalidData, validColumns: columns, invalidColumns });

    MySwal.close();
  }, [validateRows, columns]);

  const handleUpload = useCallback(() => {
    MySwal.fire({
      title: 'Upload Spreadsheet',
      html: <SpreadsheetImport onComplete={parseData} fields={fields}/>,
      width: '800px',
      showConfirmButton: false,
      showCloseButton: true
    });
  }, [parseData, validationSchema, fields]);

  const next = useCallback(async () => {
    const data = state.validData.map(({ tableData, ...rest }: any) => rest); // remove the tableData property
    if (onComplete) onComplete(data);
  }, [state, onComplete]);

  const handleSpreadsheet = useCallback(() => exportTemplate(fields), [fields]);

  const onRowUpdate = useCallback(async (key: string, oldData: any, newData: any) => {
    if (state) {
      const data = state[key];
      const index = data.indexOf(oldData);
      data[index] = newData;
      const temp = {
        ...state,
        [key]: data
      };
      const newState = await validateRows([...temp.validData, ...temp.invalidData]);
      setState({ ...state, ...newState });
    }
  }, [state, validateRows]);

  const handleDelete = useCallback(async (e: any, row: any) => {
    const confirm = await Swal.fire({
      title: 'Are you sure?',
      text: 'This will remove this row from the import',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Confirm'
    });

    if (!confirm.value) return;

    const key = row.error ? 'invalidData' : 'validData';
    const data = state[key];
    const index = data.indexOf(row);
    data.splice(index, 1);
    setState({ ...state, [key]: data });
  }, [state]);

  const handleEdit = useCallback(async (e: any, row: any) => {
    editRow(row).then(editedRow => {
      presentSuccess('Row has been updated successfully');
      if (editedRow.error) {
        onRowUpdate('invalidData', row, editedRow);
      } else {
        onRowUpdate('validData', row, editedRow);
      }
    })
  }, [editRow, onRowUpdate]);

  const handleClear = useCallback(async () => {
    const confirm = await Swal.fire({
      title: 'Are you sure?',
      text: 'This will clear the data from this import',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Confirm'
    });

    if (confirm.value) setState(null);
  }, []);

  const options = useMemo(() => [
    {
      index: 0,
      text: text || 'IMPORT USERS(From Spreadsheet)',
      button: <CPrimaryButton onClick={handleUpload}>{text || 'IMPORT USERS(From Spreadsheet)'}</CPrimaryButton>
    },
    {
      index: 1,
      text: 'Download Template',
      button: (
        <CPrimaryButton onClick={handleSpreadsheet}>Download Template</CPrimaryButton>
      )
    }
  ], [handleUpload, handleSpreadsheet, text]);

  const actions = useMemo(() => [
    {
      tooltip: 'Edit',
      icon: () => <Edit />,
      onClick: handleEdit,
    },
    {
      tooltip: 'Delete',
      icon: () => <Delete />,
      onClick: handleDelete,
    },
  ], [handleEdit, handleDelete]);

  const hasValidData = useMemo(() => state && typeof state.validData !== 'undefined' && state.validData.length > 0, [state]);
  const hasInvalidData = useMemo(() => state && typeof state.invalidData !== 'undefined' && state.invalidData.length > 0, [state]);

  return (
    <>
      { (hasValidData || hasInvalidData) && (
        <CardTitle>
          <BackButton onClick={handleClear}>
            <Back />
          </BackButton>
          Import Data
        </CardTitle>
      )}
      {
        !hasValidData && !hasInvalidData && (
          <ButtonsContainer>
            <MultiButton options={options} />
          </ButtonsContainer>
        )
      }
      <TableOuter>
        {hasInvalidData && (
          <MaterialTable title="Invalid Data" columns={state.invalidColumns} data={state.invalidData} options={OPTIONS} style={{ marginBottom: '2rem' }} actions={actions} />
        )}
        {hasValidData && (
          <MaterialTable title="Valid Data" columns={state.validColumns} data={state.validData} options={OPTIONS} style={{ marginBottom: '2rem' }} actions={actions} />
        )}
      </TableOuter>
      {hasValidData && <PrimaryButton onClick={next}>Next</PrimaryButton>}
    </>
  );
};

export default ImportTables;
