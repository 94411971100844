import gql from 'graphql-tag';

export const UPDATE_ORG_ACCESS = gql`
  mutation UPDATE_ORG_ACCESS ($id: String!, $updateOrgAccess: UpdateOrgAccessInput!) {
    updateOrgAccess (id: $id, updateOrgAccess: $updateOrgAccess) {
      id
    }
  }
`;


export const UPDATE_ORG_USER = gql`
  mutation UPDATE_ORG_USER ($id: String!, $updateOrgUser: UpdateOrgUserInput!) {
    updateOrgUser (id: $id, updateOrgUser: $updateOrgUser) {
      id
    }
  }
`;
