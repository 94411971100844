import React, { useState } from 'react'
import styled from 'styled-components';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { CircularProgress } from '@material-ui/core';
import { CustomPadder } from '../global/style';
import SideNav from '../components/sidenav/SideNav';
import Header from '../components/header/Header';
import LogoutPane from '../components/logout-pane/LogoutPane';
import ManagerList from './user/ManagerList';
import UserList from './user/UserList';
import UserAdd from './user/UserAdd';
import UserEdit from './user/UserEdit';
import PlayerList from './player/PlayerList';
import PlayerAdd from './player/PlayerAdd';
import CoachList from './coach/CoachList';
import CoachAdd from './coach/CoachAdd';
import { setCurrentUser, setCognitoUser } from '../redux/actions';
import { useWindowWidth } from '../custom-hooks/viewhooks';
import OrganisationAdd from './organisation/OrganisationAdd';
import OrganisationList from './organisation/OrganisationList';
import { presentError } from '@necta-tech/alert';
import { logout } from '@necta-tech/cognito';
import OrgManagementSelect from './organisation/OrgManagementSelect';
import ManagerAdd from './organisation/ManagerAdd';
import InviteUser from './user/InviteUser';
import OrganisationEdit from './organisation/OrganisationEdit';
import MyOrganisations from './account/MyOrganisations';
import Home from './home/Home';
import OrganisationMotd from './organisation/OrganisationMotd';
import ScrollToTop from '../components/scroll-to-top/ScrollToTop';
import ProspectList from './player/ProspectList';
import PlayerImport from './player/PlayerImport';

export const MainContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    width: 100vw;
    height: 100vh;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: ${p => p.theme.secondary};
  color: white;

  z-index: 10000;
`;

export interface ContentProps {
    mobile: boolean;
}
export const ContentContainer = styled.div<ContentProps>`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    background: #dfdfdf;

    width: ${props => props.mobile ? '100%' : 'calc(100% - 4rem)'};

    overflow-y: scroll;

    padding-bottom: 100px;
`

const QUERY = gql`
    query USER_QUERY { 
        authorizedUser {
            firstname
            lastname
            email
            contactNumber
            profilePicUrl
            roles
            dateOfBirth
            isPlayer
            isCoach
            orgAccesses {
                id
                status
                organisation {
                  id
                  name
                }
            }
            activeOrg {
              id
              name
              ownedBy
            }
        }
    }
`;

const RouteSelector = ({currentUser, setCurrentUser, setCognitoUser, cognitoUser, props}: any) => {

    const [open, setOpen] = useState(false);

    const { windowWidth } = useWindowWidth(window.innerWidth);

    const { loading, error } = useQuery(QUERY, {
        onCompleted: (response: any) => {
          console.log("USER FETCHED")
          setCurrentUser(response.authorizedUser);
        }, onError: (error: any) => {
          // TODO: only if unauthorized then maybe kick out
          console.log(error);
          presentError(error);
          logout().then(() => {
            setCognitoUser({cognitoUser: null, inSession: false});
          });
        },
        fetchPolicy: 'network-only',
      });

    if (loading) {
      return (
        <SpinnerContainer>
          <CircularProgress size={48} color="inherit" />
        </SpinnerContainer>
      );
    }

    if (error) return null;

    const handleDrawerOpen = () => {
      setOpen(true);
    };

    const handleDrawerClose = () => {
      setOpen(false);
    };

    return (
        <MainContainer>
            <Header handleDrawerOpen={handleDrawerOpen} open={open} windowWidth={windowWidth}/>
            <SideNav handleDrawerClose={handleDrawerClose} open={open} windowWidth={windowWidth}/>
            <ContentContainer mobile={windowWidth <= 768}>
                <CustomPadder height="7vh" marginBottom="10vh" width="100%" />
                <ScrollToTop />
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/players/list" component={PlayerList} />
                    <Route exact path="/players/add" component={PlayerAdd} />
                    <Route exact path="/players/invite/" component={InviteUser} />
                    <Route exact path="/players/prospects" component={ProspectList} />
                    <Route exact path="/players/import" component={PlayerImport} />
                    <Route exact path="/coach/list" component={CoachList} />
                    <Route exact path="/coach/add" component={CoachAdd} />
                    <Route exact path="/coach/add" component={CoachAdd} />
                    <Route exact path="/coach/add" component={CoachAdd} />
                    <Route exact path="/users/list" component={UserList} />
                    <Route exact path="/users/add" component={UserAdd} />
                    <Route exact path="/users/edit/:shortid" component={UserEdit} />
                    <Route exact path="/managers/list" component={ManagerList} />
                    <Route exact path="/managers/add" component={OrgManagementSelect} />
                    <Route exact path="/org/list" component={OrganisationList} />
                    <Route exact path="/org/add" component={OrganisationAdd} />
                    <Route exact path="/org/edit/:id" component={OrganisationEdit} />
                    <Route exact path="/org/my/:id" component={OrganisationEdit} />
                    <Route exact path="/org/motd" component={OrganisationMotd} />
                    <Route exact path="/managers/add/new" component={ManagerAdd} />
                    <Route exact path="/account/org/access" component={MyOrganisations} />
                    <Route render={() => <h1>route not found</h1>} />
                </Switch>
            </ContentContainer>
            <LogoutPane />
        </MainContainer>
    )
}

const mapStateToProps = (state: any, props: any) => {
    return {
        cognitoUser: state.cognitoUser,
        currentUser: state.currentUser,
        props
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setCurrentUser: (currentUser: any) => { dispatch(setCurrentUser(currentUser)) },
        setCognitoUser: (currentUser: any) => { dispatch(setCognitoUser(currentUser)) }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RouteSelector));
