import React, { useEffect, useState, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import { presentSuccess, presentError } from '@necta-tech/alert';
import { useLazyQuery, useMutation } from 'react-apollo';
import { ExpandMore, ExpandLess, KeyboardArrowLeft as Back } from '@material-ui/icons';
import gql from 'graphql-tag';
import { getThumbnail } from '@necta-tech/s3';
import { Formik, Field, FormikValues, FormikHelpers } from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Dropzone from 'react-dropzone';
import { useExpanded } from '../../custom-hooks/formhooks';
import { setCurrentUser } from '../../redux/actions';
import { regExMobile } from '../../global/regex';
import FileUpload from '../../components/file-upload/FileUpload';
import { LocationPicker, SelectField, TextField as FormikTextField, getFormData } from '../../services/helpers/form-helper';
import {
  Page,
  CenteredColumn,
  Collapsable,
  Collapser,
  Collapse,
  Form,
  CardTitle,
  Divider,
  Required,
  SubTitle,
  Card,
  BackButton,
  PrimaryButton,
  ImageContainer,
  ImageClick,
  DropzoneContainer,
  DropzoneInner,
  Highlight
} from '../../global/style';
import UserSelect from '../../components/user-select/UserSelect';
import LoadingSpinner from '../../components/loading-spinner/LoadingSpinner';
import ErrorDisplay from '../../components/error-display/ErrorDisplay';

const MySwal = withReactContent(Swal);

const SA_PROVINCES = [
  { id: 'Eastern Cape', name: 'Eastern Cape' },
  { id: 'Free State', name: 'Free State' },
  { id: 'Gauteng', name: 'Gauteng' },
  { id: 'KwaZulu-Natal', name: 'KwaZulu-Natal' },
  { id: 'Limpopo', name: 'Limpopo' },
  { id: 'Mpumalanga', name: 'Mpumalanga' },
  { id: 'Northern Cape', name: 'Northern Cape' },
  { id: 'North West', name: 'North West' },
  { id: 'Western Cape', name: 'Western Cape' }
];

const COUNTRIES = [{ id: 'South Africa', name: 'South Africa' }, { id: 'Zimbabwe', name: 'Zimbabwe' }, { id: 'Other', name: 'Other' }];

const ORG_TYPES = [{ id: 'School', name: 'School' }, { id: 'Union', name: 'Union' }, { id: 'Club', name: 'Club' }, { id: 'Private', name: 'Private' }, { id: 'Other', name: 'Other' }];

const UPDATE_ORG_MUTATION = gql`
  mutation UPDATE_ORG_MUTATION($id: String!, $updateOrganisation: UpdateOrganisationInput!) {
    updateOrganisation(id: $id, updateOrganisation: $updateOrganisation) {
      name
      id
    }
  }
`;

const GET_ORG_QUERY = gql`
  query GET_ORG_QUERY($id: String!) {
    organisation(id: $id) {
      id
      name
      primaryContactName
      primaryContactEmail
      primaryContactCell
      locationAddress
      locationCountry
      locationProvince
      locationCity
      type
      logoUrl
      ownedBy
      description
      owner {
        firstname
        lastname
        email
        locationProvince
        id        
      }
    }
  }
`;


const { schema, fields, initialValues } = getFormData(
  yup.object().shape({
    id: yup
      .string()
      .required()
      .label('Organisation ID'),
    name: yup
      .string()
      .required()
      .label('Organisation Name'),
    primaryContactName: yup
      .string()
      .required()
      .label('Primary Contact Name'),
    primaryContactEmail: yup
      .string()
      .email()
      .trim()
      .required()
      .label('Primary Contact Email'),
    primaryContactCell: yup
      .string()
      .matches(regExMobile, 'Please enter a valid contact number')
      .required()
      .label('Primary Contact Number'),
    description: yup.string().label('Organisation Bio').nullable(),
    locationAddress: yup.string().label('Address'), // TODO: this isn't being set
    locationCountry: yup.string().label('Country'),
    locationProvince: yup.string().label('Province'),
    locationCity: yup.string().label('City'),
    type: yup.string().label('Organisation Type').required(),
    logoUrl: yup.string().label('Logo Url'),
    email: yup.string().email().trim().label('Search by Email'),
    ownedBy: yup.string().nullable().label('Owned By'),
    owner: yup.object().nullable().label('Owner')
  })
);

const TextField = styled(FormikTextField)`
  && {
    width: 100%;
  }
`;

const CreateButton = styled(PrimaryButton)`
  && {
    height: 56px;
    width: 100%;
    border-radius: 0;
  }
`;

const CurrentOwner = styled.div`
  margin-bottom: 8px;
  font-size: 12px;
`;

const OrganisationEdit = ({ cognitoUser, props }: any) => {
  const [defaultValues, setDefaults] = useState<any>({ ...initialValues });;

  const { id } = props.match.params;

  const [addOrg, { loading }] = useMutation(UPDATE_ORG_MUTATION);

  const [getOrg, { loading: orgLoading, data }] = useLazyQuery(GET_ORG_QUERY, {
    variables: { id },
    fetchPolicy: 'network-only',
    onError: (e: any) => {
      console.log(e);
      // presentError(e, 'Unable to load organisation');
    }
  });

  const { expanded, handleOnExpandedClick } = useExpanded({
    location: false,
    security: false
  });

  const isOwner = useMemo(() => {
    if (cognitoUser.permissions === 'Admin') {
      return true;
    }
    if (data && data.organisation && data.organisation.ownedBy === cognitoUser.username) {
      return true;
    }
    return false;
  }, [data, cognitoUser]);

  const onSubmit = async (values: FormikValues, actions: FormikHelpers<any>) => {
    actions.setSubmitting(true);
    try {
      const { email, id, owner, __typename, ...updateOrganisation } = values;
      await addOrg({ variables: { id, updateOrganisation } });
      await getOrg();
      presentSuccess('Organisation updated Successfully!', 'Success');
      // actions.resetForm(defaultValues);
    } catch (e) {
      presentError(e, 'Unable to update organisation');
    } finally {
      actions.setSubmitting(false);
    }
  };

  const imageUploaded = (setFieldValue: any) => (key: string) => {
    setFieldValue('logoUrl', key);
    MySwal.close();
  };

  const uploadImage = (setFieldValue: any, defaultFiles?: File[]) => {
    MySwal.fire({
      title: 'Upload Organisation Logo',
      html: <FileUpload path="orgLogo" id={cognitoUser.username} type="image" single={true} onComplete={imageUploaded(setFieldValue)} defaultFile={defaultFiles} />,
      width: '800px',
      showConfirmButton: false,
      showCloseButton: true
    });
  };

  useEffect(() => {
    if (data && data.organisation) {
      setDefaults(data.organisation);
    } else {
      getOrg();
    }
  }, [setDefaults, getOrg, data]);

  if (orgLoading) return <LoadingSpinner />;

  if (!data || (data && !data.organisation)) {
    return <ErrorDisplay title={(isOwner ? 'Edit' : 'View') + ' Organisation'} error="Unable to load organisation" />
  }

  return (
    <Page>
      <Card>
        <CenteredColumn>
          <Formik initialValues={defaultValues} validateOnBlur enableReinitialize validationSchema={schema} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
              <>
                <CardTitle>
                  <BackButton onClick={() => props.history.goBack()}>
                    <Back />
                  </BackButton>
                  { isOwner ? 'Edit' : 'View'} Organisation
                </CardTitle>
                <Divider />
                <Required>
                  <SubTitle>Organisation Information</SubTitle>
                  <h3>*required</h3>
                </Required>
                <Form>
                  <ImageContainer>
                    <img onClick={() => isOwner && uploadImage(setFieldValue)} alt="Org Logo" src={!values.logoUrl ? require('../../assets/img/default-org.png') : getThumbnail(values.logoUrl)} />
                    { isOwner && (
                        <ImageClick>
                        <Dropzone onDrop={acceptedFiles => uploadImage(setFieldValue, acceptedFiles)} disabled={loading}>
                          {({ getRootProps, getInputProps }) => (
                            <DropzoneContainer {...getRootProps()}>
                              <input {...getInputProps()} />
                              <DropzoneInner>
                                <h4>Banner / Logo<br />
                                Drag and Drop or <Highlight>Click</Highlight> to search</h4>
                              </DropzoneInner>
                            </DropzoneContainer>
                          )}
                        </Dropzone>
                      </ImageClick>
                    )}
                  </ImageContainer>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Field {...fields.name} disabled={!isOwner} component={TextField} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field {...fields.primaryContactName} disabled={!isOwner} component={TextField} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field {...fields.primaryContactEmail} disabled={!isOwner} component={TextField} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field {...fields.primaryContactCell} disabled={!isOwner} component={TextField} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field {...fields.type} disabled={!isOwner} component={SelectField} options={ORG_TYPES} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Field {...fields.description} component={TextField} disabled={!isOwner} multiline={true} />
                    </Grid>
                  </Grid>
                </Form>
                <Divider />
                <Collapser onClick={(e: any) => handleOnExpandedClick(e, 'location')}>
                  <Required>
                    <SubTitle>Location</SubTitle>
                    {expanded.location ? <ExpandLess /> : <ExpandMore />}
                  </Required>
                </Collapser>
                <Collapse in={expanded.location}>
                  <Collapsable>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Field {...fields.locationAddress} disabled={!isOwner} component={LocationPicker} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field {...fields.locationCountry} disabled={!isOwner} component={SelectField} options={COUNTRIES} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field {...fields.locationProvince} disabled={!isOwner} component={SelectField} options={SA_PROVINCES} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field {...fields.locationCity} disabled={!isOwner} component={TextField} />
                      </Grid>
                    </Grid>
                  </Collapsable>
                </Collapse>
                <Divider />
                <Collapser onClick={(e: any) => handleOnExpandedClick(e, 'security')}>
                  <Required>
                    <SubTitle>{ isOwner ? 'Change ' : '' }Owner</SubTitle>
                    {expanded.security ? <ExpandLess /> : <ExpandMore />}
                  </Required>
                </Collapser>
                <Collapse in={expanded.security}>
                  <Collapsable>
                    { values.owner && values.owner.email && (
                      <CurrentOwner>
                        <b>Current Owner:</b> {values.owner.firstname + ' ' + values.owner.lastname + ' <' + values.owner.email + '>'}
                      </CurrentOwner>
                    )}
                    { isOwner && <UserSelect userId={values.ownedBy} setValue={(value: any) => setFieldValue('ownedBy', value)} title="Select New Owner:" externalLoading={loading || isSubmitting} />}
                  </Collapsable>
                </Collapse>
                <Divider />
                { isOwner && (
                  <CreateButton disabled={loading || isSubmitting} onClick={() => handleSubmit()}>
                    {loading || isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Update Organisation'}
                  </CreateButton>
                )}
              </>
            )}
          </Formik>
        </CenteredColumn>
      </Card>
    </Page>
  );
};

const mapStateToProps = (state: any, props: any) => {
  return {
    cognitoUser: state.cognitoUser,
    currentUser: state.currentUser,
    props
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setCurrentUser: (currentUser: any) => {
      dispatch(setCurrentUser(currentUser));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OrganisationEdit)
);
