import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import { KeyboardArrowLeft as Back } from '@material-ui/icons';
import { useMutation } from 'react-apollo';
import { Formik, FormikValues, FormikHelpers, Field } from 'formik';
import gql from 'graphql-tag';
import * as yup from 'yup';
import { getFormData } from '../../services/helpers/form-helper';
import { setCurrentUser } from '../../redux/actions';
import { Page, CenteredColumn, CardTitle, Divider, Card, BackButton, PrimaryButton } from '../../global/style';
import UserSelect from '../../components/user-select/UserSelect';
import { presentError, presentSuccess } from '@necta-tech/alert';
import OrgsDropdown from '../../components/organisation/OrgDropDown';
import { getActiveOrgId, getIsAdmin } from '../../global/helpers/selectors';
import ErrorDisplay from '../../components/error-display/ErrorDisplay';

const { schema, fields, initialValues } = getFormData(
  yup.object().shape({
    userId: yup.string().required().label('User ID'),
    organisationId: yup.string().required().label('Org ID')
  })
);

const ADD_ORG_MANAGER = gql`
  mutation ADD_ORG_MANAGER($newOrgAccessData: NewOrgAccessInput!) {
    addOrgAccess(newOrgAccessData: $newOrgAccessData) {
      id
      userId
    }
  }
`;


const CardInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StateButton = styled(Button)`
  && {
    color: ${p => p.theme.primary};
    width: 100%;
    height: 150px;
  }
`;

const DivideArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  align-self: center;
  width: 80%;
  @media only screen and (max-width: 768px) {
    width: 96%;
  }
`;

const Or = styled.div`
  padding: 5px;
  width: 50px;
  text-align: center;
  color: rgba(36, 22, 55, 0.67);
  font-size: 16px;
`;

const Divide = styled.div`
  height: 1px;
  border-bottom: 1px solid rgba(36, 22, 55, 0.67);
  flex: 1;
`

const CreateButton = styled(PrimaryButton)`
  && {
    height: 56px;
    width: 100%;
    border-radius: 0;
  }
`;

const UserSelectOuter = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
  width: 96%;
`;

const OrgSelectOuter = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  width: 96%;
`;

const OrgManagementSelect = ({ cognitoUser, currentUser, isAdmin, activeOrgId, props }: any) => {
  const [state, setState] = useState<string | null>(null);
  const [defaultValues] = useState<any>({...initialValues, organisationId: activeOrgId });

  const [addOrgAccess, { loading }] = useMutation(ADD_ORG_MANAGER);

  const goBack = () => {
    if (state) {
      setState(null);
    } else {
      props.history.goBack();
    }
  };

  const onSubmit = async (values: FormikValues, actions: FormikHelpers<any>) => {
    console.log('submit clicked');
    actions.setSubmitting(true);
    try {
      const { email, ...newOrgAccessData } = values;
      await addOrgAccess({ variables: { newOrgAccessData } });
      presentSuccess('Organisation access granted Successfully!', 'Success');
      actions.resetForm(initialValues);
    } catch (e) {
      presentError(e, 'Unable to grant organisation access');
    } finally {
      actions.setSubmitting(false);
    }
    // setTimeout(() => actions.setSubmitting(false), 2000);
  };

  // const setValue = useCallback((value: any) => setFieldValue('manager', value), [setFieldValue]);
  if (!isAdmin && !activeOrgId) return <ErrorDisplay title={'Add Manager'} error={'You have no active organisation'} />;

  return (
    <Page>
      <Card>
        <CenteredColumn>
          <CardTitle>
            <BackButton onClick={() => goBack()}>
              <Back />
            </BackButton>
            New Management User
          </CardTitle>
          <Divider />
          { state == null && (
            <CardInner>
              <StateButton onClick={() => setState('invite')}>Invite Existing ScrumPro User</StateButton>
              <DivideArea>
                <Divide /> <Or>OR</Or> <Divide />
              </DivideArea>
              <StateButton onClick={() => props.history.push('/managers/add/new')}>Create new User</StateButton>
            </CardInner>
          )}
          { state === 'invite' && (

          <Formik initialValues={defaultValues} validateOnBlur validationSchema={schema} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
                <>
                  <OrgSelectOuter>
                    { isAdmin && <OrgsDropdown Render={Field} field={fields.organisationId} /> }
                  </OrgSelectOuter>
                  <UserSelectOuter>
                    <UserSelect userId={values.userId} setValue={(value: any) => setFieldValue('userId', value)} externalLoading={loading || isSubmitting} title={'Select Manager:'}/>
                  </UserSelectOuter>
                  <CreateButton disabled={loading || isSubmitting} onClick={() => handleSubmit()}>
                    {loading || isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Add Manager'}
                  </CreateButton>
                </>
              )}
            </Formik>
          )}
        </CenteredColumn>
      </Card>
    </Page>
  );
};

const mapStateToProps = (state: any, props: any) => {
  return {
    cognitoUser: state.cognitoUser,
    currentUser: state.currentUser,
    isAdmin: getIsAdmin(state),
    activeOrgId: getActiveOrgId(state),
    props
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setCurrentUser: (currentUser: any) => {
      dispatch(setCurrentUser(currentUser));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OrgManagementSelect)
);
