import { useState } from 'react';

export const useValues = (defaultAttributes: any) => {
    const [values, setValues] = useState(defaultAttributes);
  
    const handleOnChange = (e: any) => {
      e.persist();
      setValues({...values, [e.target.name]: e.target.value})
    }

    const handleCheckOnChange = (e: any) => {
      e.persist();
      setValues({...values, [e.target.name]: e.target.checked})
    }
  
    const handleOnKeyPress = (e: any) => {
      if (e.key === "Enter") {
        e.preventDefault();
      }
    }

    const setValue= (name: string, value: any) => {

      setValues({
        ...values,
        [name]: value
      })
    }
  
    const emptyValues = () => {
      setValues(defaultAttributes);
    }
  
    return {
      values,
      handleOnChange,
      handleCheckOnChange,
      handleOnKeyPress,
      setValue,
      emptyValues,
    }
}

export const usePassword = () => {
    const [password, setPassword] = useState<string>('');

    const handleOnChange = (e: any) => {
        console.log(e.target.value);
        setPassword(e.target.value);
    }

    return {
        password,
        setPassword,
        handleOnChange
    }
}

export const useRegex = (regex: RegExp, value: string) => {

    const [ touched, setTouched ] = useState<boolean>(false);

    const valid = (): boolean => {
      if (value.length > 0 && !touched) setTouched(true);
      return regex.test(value);
    };

    return {
        touched,
        setTouched,
        valid,
    };
}

export const useExpanded = (initialValues: any) => {
  const [expanded, setExpanded] = useState(initialValues);

  const handleOnExpandedClick = (e: any, name: any) => {
    setExpanded({
      ...expanded,
      [name]: !expanded[name]
    });
  };

  return {
    expanded,
    setExpanded,
    handleOnExpandedClick
  };
};