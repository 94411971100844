import React, { useCallback, useState, useMemo, useEffect } from 'react'
import styled, { withTheme } from 'styled-components'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import { Drawer, List, Divider, IconButton, ListItem, ListItemIcon, ListItemText, Collapse } from '@material-ui/core';
import { ExpandMore,
  ExpandLess,
  PersonAdd,
  PeopleAlt,
  SportsRugby,
  Sports,
  GroupWork,
  Face,
  ViewList,
  PostAdd,
  Send,
  SupervisedUserCircle,
  Settings,
  ViewStream,
  Dashboard,
  ChevronRight,
  ChevronLeft,
  Business,
  Telegram,
  Assignment,
  ImportContacts,
} from '@material-ui/icons';
import { getActiveOrgId } from '../../global/helpers/selectors';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }),
);

declare interface OpenProps {
  open: any
}
declare interface HighlightedProps {
  active: any
}

const MenuIcon = styled(ListItemIcon)<OpenProps>`
    svg {
        margin: ${props => props.open === false ? '0 auto' : '0' }
    }
`;

const AlignedListItem = styled(ListItem)<{ color: string }>`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: ${p => p.color === 'highlighted' ? p.theme.primary : 'rgba(0, 0, 0, 0.54)'};
    
    svg {
      color: ${p => p.color === 'highlighted' ? p.theme.primary : 'rgba(0, 0, 0, 0.54)'};
    }

    .aligned {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80%;
    }
    .MuiListItemIcon-root {
      min-width: 37px;    
    }
  }
`;

const NestedListItem = styled(ListItem)<any>`
  && {
    padding-left: ${props => props.open ? '30px' : '20px'};
    color: ${p => p.color === 'highlighted' ? p.theme.primary : 'rgba(0, 0, 0, 0.54)'};
    
    svg {
      color: ${p => p.color === 'highlighted' ? p.theme.primary : 'rgba(0, 0, 0, 0.54)'};
    }

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .aligned {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80%;
    }
    
    .MuiListItemIcon-root {
      min-width: 37px;    
    }
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;

  div {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 7px;
  }  

  p {
    margin-top: -1px;
    font-size: 11px;
    color: #241637;
    margin-bottom: 0px;
  }
  h3 {
    margin-bottom: -1px;
    margin-top: 0;
    font-size: 18px;
    color: #241637;
  }
  img {
    width: auto;
    height: 35px;
  }
`;

const SideNav = withTheme(({cognitoUser, currentUser, activeOrgId, open, windowWidth, handleDrawerClose, history, location, theme: SCTheme}: any) => {

    const classes = useStyles();
    const theme = useTheme();

    const [navItems, setNavItems] = useState<any[]>([]);

    const getNav = useCallback((orgId: string) => [
        { name: 'Dashboard', path: '/', key: 'dashboard', icon: <Dashboard /> },
        { name: 'Players', path: '/players', key: 'players', icon: <SportsRugby />, subItems: [
            { name: 'List', path: '/players/list', key: 'list-players', icon: <PeopleAlt /> },
            { name: 'Add Player', path: '/players/add', key: 'add-player', icon: <PersonAdd /> },
            { name: 'Invite Player', path: '/players/invite', key: 'invite-player', icon: <Send /> },
            { name: 'Import Players', path: '/players/import', key: 'import-players', icon: <ImportContacts />, roles: ['Admin'] },
            { name: 'Prospects', path: '/players/prospects', key: 'list-prospects', icon: <Assignment />, roles: ['Organisation']}
          ]
        },
        { name: 'Coaches', path: '/coach', key: 'coaches,', icon: <Sports />, subItems: [
            { name: 'List', path: '/coach/list', key: 'list-coaches', icon: <PeopleAlt /> },
            { name: 'Add Coach', path: '/coach/add', key: 'add-coach', icon: <PersonAdd /> },
          ]
        },
        { name: 'Managers', path: '/managers', key: 'managers', icon: <Face />, subItems: [
            { name: 'List', path: '/managers/list', key: 'list-managers', icon: <PeopleAlt /> },
            { name: 'Add Manager', path: '/managers/add', key: 'add-manager', icon: <SupervisedUserCircle /> },
          ]
        },
        { name: 'My Organisation', path: '/org', key: 'organisation', icon: <GroupWork />, roles: ['Organisation'], subItems: [
            { name: 'Edit My Org', path: '/org/edit/' + orgId, key: 'list-org', icon: <Business /> },
            { name: 'Send Broadcast', path: '/org/motd', key: 'add-org-motd', icon: <Telegram /> },
          ]
        },
        { name: 'Organisations', path: '/org', key: 'organisation', icon: <GroupWork />, roles: ['Admin'], subItems: [
            { name: 'List', path: '/org/list', key: 'list-org', icon: <ViewList /> },
            { name: 'Add Org', path: '/org/add', key: 'add-org', icon: <PostAdd /> },
            { name: 'Send Broadcast', path: '/org/motd', key: 'add-org-motd', icon: <Telegram /> },
          ]
        },
        { name: 'My Account', path: '/account', key: 'account', icon: <Settings />, roles: ['Organisation'], subItems: [
            { name: 'Org Access', path: '/account/org/access', key: 'org-access', icon: <ViewStream /> },
          ]
        },
        { name: 'All Users', path: '/users/list', key: 'list-users', icon: <PeopleAlt />, roles: ['Admin'] },
      ]
    , []);

    useEffect(() => {
      setNavItems(getNav(activeOrgId));
    }, [setNavItems, activeOrgId]);

    const handleExpanded = useCallback((name: string) => {
      const newNav = [...navItems];
      for (const n of newNav) {
        if (n.key === name) {
          n.expanded = !n.expanded;
        }
      }
      setNavItems(newNav)
    }, [navItems, setNavItems]);

    const handleNavClick = useCallback((navItem: any) => (e: any) => {
      if (navItem.subItems) {
        handleExpanded(navItem.key);
      } else {
        if (windowWidth <= 768) {
          handleDrawerClose();
        }
        history.push(navItem.path);
      }
    }, [history, handleExpanded, handleDrawerClose]);

    const checkActive = useCallback((navItem: any) => {
      if (navItem.path === location.pathname) return true;
      if (navItem.subItems) {
        for (const s of navItem.subItems) {
          if (s.path === location.pathname) return true;
        }
      }
      return false;
    }, [location.pathname]);

    const checkRoles = useCallback((roles: any) => {
      if (!roles) return true;
      return roles.includes(cognitoUser.permissions);
    }, [cognitoUser.permissions]);

    return (
        <Drawer
            variant={windowWidth > 768 ? "permanent" : "temporary"}
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
            open={open}
        >
            <div className={classes.toolbar}>
                    {open && (
                      <LogoContainer>
                        <img alt="sp-logo" src={require('../../assets/favicon.png')}/>
                        <div>
                          <h3>Scrum<b>Pro</b></h3>
                          <p>Management Portal</p>
                        </div>
                      </LogoContainer>
                    )}
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
                </IconButton>
            </div>

            <Divider />
            <List>
              { navItems.map((navItem: any) => {
                if (!checkRoles(navItem.roles)) return null;
                return (
                  <React.Fragment key={navItem.key}>
                    <AlignedListItem button key={navItem.key} onClick={handleNavClick(navItem)} color={checkActive(navItem) ? 'highlighted' : 'normal'}>
                      <ListItemIcon className={open ? '' : 'aligned'} >
                        {navItem.icon}
                      </ListItemIcon>
                      {open && <ListItemText primary={navItem.name} />}
                      { navItem.subItems && open && (navItem.expanded ? <ExpandLess /> : <ExpandMore />) }
                    </AlignedListItem>
                    { navItem.subItems && (
                      <Collapse in={navItem.expanded}>
                        <Divider />
                        <List>
                          {navItem.subItems.map((subItem: any) => {
                            if (!checkRoles(subItem.roles)) return null;
                            return (
                              <NestedListItem open={open} button key={subItem.key} onClick={handleNavClick(subItem)} color={checkActive(subItem) ? 'highlighted' : 'normal'}>
                                <MenuIcon open={open}>
                                  {subItem.icon}
                                </MenuIcon>
                                {open && <ListItemText primary={subItem.name}/>}
                              </NestedListItem>
                            )}
                          )}
                        </List>
                        <Divider />
                      </Collapse>
                    )}
                  </React.Fragment>
                )}
              )}
            </List>
        </Drawer>
    )
});

const mapStateToProps = (state: any, props: any) => {
  return {
    cognitoUser: state.cognitoUser,
    currentUser: state.currentUser,
    activeOrgId: getActiveOrgId(state),
    props
  }
};

const mapDispatchToProps = (dispatch: Function) => {
  return {

  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideNav));
