import React from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Formik, Field as FormikField, FormikValues, FormikHelpers } from 'formik';
import styled  from 'styled-components';
import { ObjectSchema } from 'yup';
import { PrimaryButton } from '../../../global/style';
import { Field } from '../../../services/helpers/yup-mapper';
import SubmitButton from '../../submit-button';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2CB7F8'
    },
    secondary: {
      main: '#302050'
    }
  }
});

const CForm = styled.form`
  width: 100%;
`;

const FieldOuter = styled.div`
  width: 100%;
`;

const CField = styled(FormikField)`
  width: 100%;
`;

const CPrimaryButton = styled(PrimaryButton)`
  && {
    margin-top: 15px;
    width: 100%;
    background: #2CB7F8 !important;
  }
`;

const SubForm: React.FC<any> = ({ fields } : any) => {
  return (
    <>
      { fields.map((f: any) => {
        const { typeInfo, ...field } = f;
        if (field.fields) return <SubForm fields={field.fields} key={field.name + '-form-outer'} />;
        return <FieldOuter key={field.name + '-outer'}><CField {...field} /></FieldOuter>;
      })}
    </>
  )
};

interface AutoFormProps {
  schema: ObjectSchema<any>;
  fields: Field[];
  initialValues: any;
  onComplete: (values: FormikValues, actions: FormikHelpers<any>) => void;
  form?: React.FC;
  text?: string;
}

const AutoForm: React.FC<any> = ({ form, schema, fields, initialValues, onComplete, text }) => {
  const FormContainer = form ? form : CForm;

  return (
    <MuiThemeProvider theme={theme}>
      <Formik initialValues={initialValues} validateOnBlur validationSchema={schema} onSubmit={onComplete}>
        {({ handleSubmit, isSubmitting }) => (
          <FormContainer>
            <SubForm fields={fields} />
            <SubmitButton handleSubmit={() => handleSubmit()} disabled={isSubmitting} component={CPrimaryButton}>{ text || 'Submit' }</SubmitButton>
          </FormContainer>
        )}
      </Formik>
    </MuiThemeProvider>
  )
};

export default AutoForm;
