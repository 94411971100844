import { get } from 'lodash';

export const getIsCurrentOrgOwner = (state: any): any => {
  const owner = get(state, 'currentUser.activeOrg.ownedBy') || null;
  const id = get(state, 'cognitoUser.username') || null;
  if (!owner) return false;
  return id === owner;
};

export const getIsAdmin = (state: any): any => {
  const permissions = get(state, 'cognitoUser.permissions') || null;
  return permissions === 'Admin';
};

export const getIsOrg = (state: any): any => {
  const permissions = get(state, 'cognitoUser.permissions') || null;
  const isOrg = permissions === 'Organisation';
  if (!isOrg) return false;
  const active = get(state, 'currentUser.activeOrg.id') || null;
  if (!active) return false;
  return true;
};

export const getIsOrganisation = (state: any): any => {
  const permissions = get(state, 'cognitoUser.permissions') || null;
  return permissions === 'Organisation';
};

export const getActiveOrgId = (state: any): any => {
  const permissions = get(state, 'cognitoUser.permissions') || null;
  const isOrg = permissions === 'Organisation';
  if (!isOrg) return null;
  return get(state, 'currentUser.activeOrg.id') || null;
};

export default {
  getIsCurrentOrgOwner,
  getIsAdmin,
  getIsOrg,
  getIsOrganisation,
  getActiveOrgId
}