import { useSelector } from 'react-redux'
import {
    getIsCurrentOrgOwner,
    getIsAdmin,
    getIsOrg,
    getIsOrganisation,
    getActiveOrgId
} from '../../global/helpers/selectors'

export * from './use-cognito-user/index'
export * from './use-logout-pane'
export * from './use-redirect-on-login'

export const useUserValidations = () => [
    {
        isCurrentOrgOwner: useSelector(getIsCurrentOrgOwner),
        isAdmin: useSelector(getIsAdmin),
        isOrg: useSelector(getIsOrg),
        isOrganisation: useSelector(getIsOrganisation),
        activeOrgId: useSelector(getActiveOrgId),
    }
] as const;