import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import { signIn } from '@necta-tech/cognito';
import { Redirect, withRouter } from 'react-router-dom';
import * as yup from 'yup';
import { Formik, Field, FormikValues, FormikHelpers } from 'formik';
import { presentError } from '@necta-tech/alert';
import { PasswordField, getFormData } from '../../services/helpers/form-helper';
import { setCognitoUser, setRedirectOnLogin } from '../../redux/actions';
import {
  Padder,
  BackDrop,
  Container,
  CenterColumn,
  CTextField,
  CardTitle,
  LogoContainer,
  ResetLink,
  ContactOuter,
  ContactLink,
  LoginCard,
  Fields,
  AltForm as Form
} from './style';
import SendResetOTP from './SendResetOTP';
import ChangePassword from './ChangePassword';
import ContactUs from '../../components/contact-us/ContactUs';
import SubmitButton from '../../components/submit-button';

import { useCognitoUser, useRedirectOnLogin } from '../../custom-hooks';
import { useHistory } from 'react-router';

const { schema, fields, initialValues } = getFormData(
  yup.object().shape({
    email: yup
      .string()
      .email()
      .trim()
      .required()
      .label('Email'),
    password: yup
      .string()
      .required()
      .label('Password')
  })
);

const Title = styled.div`
  padding: 5px;
  font-size: 40px;
  @media only screen and (max-width: 768px) {
    font-size: 30px;
    padding: 2px;
    text-align: center;
  }
`;
const Subtitle = styled.div`
  padding: 5px;
  font-size: 30px;
  margin-bottom: 12px;
  @media only screen and (max-width: 768px) {
    font-size: 20px;
    padding: 2px;
    margin-bottom: 0;
    text-align: center;
  }
`;
/*
setUser and Users come from the connect function on the last line
props are just any additional props passed to the tag <Login propname=propvalue>
*/
const Login = ({ ...props }: any) => {
  const [defaultValues, setDefaultValues] = useState<any>({ email: '', password: ''});
  const [state, setState] = useState<string>('login');
  const [email, setEmail] = useState<string>('');

  const [cognitoUser, setCognitoUser] = useCognitoUser()

  const history = useHistory()
  const [path, handleRedirect] = useRedirectOnLogin((path: string) => history.push(path))

  console.log('path:', path)

  const handleLogin = async (values: FormikValues, { setSubmitting }: FormikHelpers<any>) => {
    setSubmitting(true)
    const email = values.email.replace(/\s/g, '').toLowerCase();
    try {
      await signIn(email, values.password)
      handleRedirect()
    } catch (e) {
      presentError(e, 'Unable to login');
    } finally {
      setSubmitting(false)
    }
  };

  const otpSent = (emailUsed: string) => {
    setEmail(emailUsed);
  };

  const passwordChanged = () => {
    setState('login');
  };

  const handleBack = () => {
    if (email === '') {
      setState('login');
    } else {
      setEmail('');
    }
  };

  if (cognitoUser.inSession) return <Redirect to={path} />

  return (
    <>
      <BackDrop />
      <Container>
        <CenterColumn>
          <LogoContainer>
            <img alt="sp-logo" src={require('../../assets/favicon-light.svg')} height="130px" width="130px" />
            <div>
              <Title>Scrum<b>Pro</b></Title>
              <Subtitle>Step into the Arena</Subtitle>
            </div>
          </LogoContainer>
          { state === 'login' && 
            <Formik validationSchema={schema} enableReinitialize initialValues={defaultValues} onSubmit={handleLogin} validateOnBlur>
              {({ handleSubmit, isSubmitting }) => (
                <>
                  <LoginCard>
                    <CenterColumn>
                      <CardTitle><h1>Please log in</h1></CardTitle>
                      <Form>
                        <Fields>
                          <Field {...fields.email} disabled={isSubmitting} id={'email'} autoComplete="email" component={CTextField} />
                          <Field {...fields.password} disabled={isSubmitting} id={'password'} component={PasswordField} />
                        </Fields>
                        <ResetLink onClick={() => setState('reset')}>Forgot Password?</ResetLink>
                        <Padder />
                        <ContactOuter>
                          <ContactUs button={ContactLink} text="Contact Us" />
                        </ContactOuter>
                      </Form>
                    </CenterColumn>
                  </LoginCard>
                  <SubmitButton loading={isSubmitting} handleSubmit={handleSubmit}>
                    Log In
                  </SubmitButton>
                </>
              )}
            </Formik>
          }
        {state === 'reset' && email === '' && <SendResetOTP otpSent={otpSent} handleBack={handleBack} />}
        {state === 'reset' && email !== '' && <ChangePassword passwordChanged={passwordChanged} handleBack={handleBack} email={email} />}
        </CenterColumn>
      </Container>
    </>
  );
};

const mapStateToProps = (state: any, props: any) => {
  return {
    cognitoUser: state.cognitoUser,
    redirectOnLogin: state.redirectOnLogin,
    ...props
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setCognitoUser: (user: any) => {
      dispatch(setCognitoUser(user));
    }
  };
};

//connect the Login component to the State and functions defined in LoginFunctionality and export the new component
const connectedLogin = connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
export default withRouter(connectedLogin);
