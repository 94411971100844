export const SA_PROVINCES = [
    'Eastern Cape',
    'Free State',
    'Gauteng',
    'KwaZulu-Natal',
    'Limpopo',
    'Mpumalanga',
    'Northern Cape',
    'North West',
    'Western Cape'
];

export const COUNTRIES = [
    'South Africa',
    'Zimbabwe',
    'Other'
];

export const GENDERS = [
    'Male',
    'Female',
    'Other',
];

export const ETHNICITIES = [
    'Unspecified',
    'Asian',
    'Black',
    'Coloured',
    'Indian',
    'White',
    'Other',
];

export const STATS_KEYS = [
    'height',
    'weight',
    'bio',
    'levelPlayedSenior',
    'levelPlayedJunior',
    'educationSchool',
    'educationDescription',
    'accolades',
    'skills',
    'injuryHistory',
    'coachingSpecialization',
    'coachingQualification',
    'coachingHighestLevel',
    'clubCurrent',
    'positionPrimary',
    'positionSecondary',
];

export const POSITIONS = [
    'Prop (loose head)',
    'Hooker',
    'Prop (tight head)',
    'Lock',
    'Flanker',
    'Number 8',
    'Scrum half',
    'Fly half',
    'Left wing',
    'Left centre',
    'Right centre',
    'Right wing',
    'Full back'
];

export const PERMISSIONS = [
    'User',
    'Organisation',
    'Admin'
];

export const TEAMS = [
    '1st Team',
    '2nd Team',
    '3rd Team',
    '4th Team',
    '5th Team',
    '6th Team',
    '7th Team',
    '8th Team',
    '9th Team',
    '10th Team',
    'A Team',
    'B Team',
    'C Team',
    'D Team',
    'E Team',
    'F Team',
    'G Team',
    'H Team',
    'I Team',
    'J Team',
];

export const AUTHORIZED_ROLES = ['Admin', 'Organisation'];

export const BIRTHDAY_ERROR = 'Please enter a valid date of birth';
export const PASSWORD_ERROR = 'Password must be 8-20 characters and include an uppercase letter, lowercase letter and a number';
