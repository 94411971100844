
export const mapArrayToObjects = (arr: string[]) => {
  return arr.map(a => Object.assign({ id: a, name: a }));
};

export const mapArrayToEnum = (arr: string[]) => {
  return arr.reduce((obj: any, a: string) => {
    return {...obj, [a]: a}
  }, {});
};
