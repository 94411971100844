import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useLazyQuery, useMutation } from 'react-apollo';
import MaterialTable, { Column } from 'material-table';
import { presentSuccess, presentError } from '@necta-tech/alert';
import { Send, Cached } from '@material-ui/icons';
import { findGeneric, findAge, getAge, getDateFull } from '../../global/helpers/table-helper';
import { INVITE_USERS_MUTATION, PROSPECTS_PLAYERS } from '../../global/gql/users';
import { mapArrayToEnum } from '../../global/helpers/data-maps';
import DetailPanel from './ProspectPanel';
import { POSITIONS, SA_PROVINCES } from '../../global/constants';
import { TableContainer, TableOuter } from '../../global/style';
import Swal from 'sweetalert2';
import { getThumbnail } from '@necta-tech/s3';
import { getActiveOrgId } from '../../global/helpers/selectors';

const ageGroups = { '0-9': 'Under 9', '10-11': 'Under 11', '12-13': 'Under 13', '14': 'Under 14', '15': 'Under 15', '16': 'Under 16', '17-18': 'Under 18', '19': 'Under 19', '20-21': 'Under 21','22-999': '22+' };
const provinces = mapArrayToEnum(SA_PROVINCES);
const positions = mapArrayToEnum(POSITIONS);

const columns: Column<any>[] = [
  {
    field: 'profilePicUrl',
    title: 'Picture',
    filtering: false,
    editable: 'never',
    render: rowData => (
      <>
        {rowData && (
          <img
            src={!rowData.profilePicUrl ? require('../../assets/img/default-profile.png') : getThumbnail(rowData.profilePicUrl)}
            style={{ width: 50, borderRadius: '50%' }}
            alt="Profile Pic"
          />
        )}
      </>
    )
  },
  {
    title: 'First Name',
    field: 'firstname'
  }, {
    title: 'Surname',
    field: 'lastname'
  }, {
    title: 'Email',
    field: 'email',
    hidden: false,
    filtering: true,
  }, {
    title: 'Cell',
    field: 'contactNumber',
    hidden: true,
    filtering: true,
  }, {
    title: 'Primary Position',
    field: 'stats.positionPrimary',
    lookup: positions,
    customFilterAndSearch: (term: string[], r: any) => (r && r.stats) ? findGeneric(term, r.stats.positionPrimary) : false
  }, {
    title: 'Age',
    field: 'dateOfBirth',
    type: 'numeric',
    editable: "never",
    lookup: ageGroups,
    render: (r: any) => r ? getAge(r.dateOfBirth): '',
    customFilterAndSearch: (term: string[], r: any) => r ? findAge(term, r.dateOfBirth) : false
  }, {
    title: 'Province',
    field: 'locationProvince',
    lookup: provinces,
    customFilterAndSearch: (term: string[], r: any) => r ? findGeneric(term, r.locationProvince) : false
  }
];

const ProspectList: React.FC<any> = ({ activeOrgId, ...props }) => {
  const [players, setPlayers] = useState<any[]>([]);

  const [fetchPlayers, { loading, data }] = useLazyQuery(PROSPECTS_PLAYERS, {
    variables: { id: activeOrgId },
    fetchPolicy: 'network-only',
    onError: (error: any) => {
      console.log(error)
    }
  });

  const [invite, { loading: inviting }] = useMutation(INVITE_USERS_MUTATION, {
      onCompleted: (result: any) => {
          console.log(result.data)
      }
  })

  const handleOnInvite = useCallback(async (e: any, player: any) => {
    try {
      const confirm = await Swal.fire({
        title: 'Are you Sure?',
        text: 'This will invite the user to your Organisation.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm'
      });
      if (!confirm.value) return
      await invite({
        variables: {
          newInvitation: {
            organisationId: activeOrgId + '',
            ids: [player.id]
          }
        }
      })
      presentSuccess('Success', 'Player invited to your Organisation.');
    } catch(e) {
      presentError(e, 'Something went wrong while inviting this player!')
    }
  }, [])

  useEffect(() => {
    if (data && data.savesByOrgId) {
      setPlayers(data.savesByOrgId);
    } else {
      fetchPlayers();
    }
  }, [fetchPlayers, data]);

  const detailPanel: any = (rowData: any) => {
    return <DetailPanel rowData={rowData} />;
  };

  return (
    <TableContainer>
      <TableOuter onContextMenu={e => console.log('do something')}>
        <MaterialTable
          title="Prospect List"
          columns={columns}
          detailPanel={detailPanel}
          options={{
            emptyRowsWhenPaging: false,
            columnsButton: true,
            pageSize: 10,
            filtering: true,
            exportButton: true
          }}
          isLoading={loading || inviting}
          data={players}
          actions={[
            {
              tooltip: 'Invite',
              icon: () => <Send />,
              onClick: handleOnInvite,
              isFreeAction: false
            },
            {
              tooltip: 'Refresh',
              icon: () => <Cached />,
              onClick: fetchPlayers,
              isFreeAction: true
            }
          ]}
        />
      </TableOuter>
    </TableContainer>
  )
};

const mapStateToProps = (state: any, props: any) => {
  return {
    cognitoUser: state.cognitoUser,
    currentUser: state.currentUser,
    activeOrgId: getActiveOrgId(state),
    ...props,
  }
};

const mapDispatchToProps = (dispatch: Function) => {
  return {}
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProspectList));
