import { createStore } from 'redux';
import rootReducer from '../redux/reducers/root-reducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export default () => {

    const persistConfig = {
        key: 'root',    //key for local storage
        storage,        //storage adapter, defaults to local storage
    };

    const persistedReducer = persistReducer(persistConfig, rootReducer);
    //redux store to be passed to Provider
    const store = createStore(persistedReducer);
    const persistor = persistStore(store);

    return {
        store,
        persistor
    };
};