import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useMutation, useLazyQuery } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { CircularProgress, Grid, Button } from '@material-ui/core';
import { getThumbnail } from '@necta-tech/s3';
import { presentError, presentSuccess } from '@necta-tech/alert';
import Swal from 'sweetalert2';
import SkeletonLoader from '../../components/loaders/LoaderContainer';
import { MANAGERS_AT_ORG_QUERY, UPDATE_ORG_QUERY } from '../../global/gql/organisations';
import { UPDATE_ORG_ACCESS } from '../../global/gql/orgAccess';
import { PrimaryButton } from '../../global/style';

const OuterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const Container = styled.div`
    width: 90%;
    margin: 20px;
`;

const EmptyMessage = styled.h1`
    font-size: 14px;
    padding: 0;
    margin: 0;
    padding-left: 20px;
    width: 100%;
`;

const Section = styled.div`
  margin: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const SectionHeader = styled.div`
  margin: 0 15px 5px 17px;
`;

const RowTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  :hover {
      cursor: pointer;
  }

  @media only screen and (max-width: 599px) {
    flex-direction: column;
  }
`;

const UserName = styled.div`
  font-size: 20px;
  padding: 5px 5px 5px 15px;
  @media only screen and (max-width: 599px) {
    font-size: 18px;
  }
`;

const UserImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid ${p => p.theme.primary};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const SectionContainer = styled.div`
  margin-bottom: 40px;
`;

const UserStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end; 
  width: 100%;
  @media only screen and (max-width: 599px) {
    align-items: center;
    && {
      div, button {
        width: 100%;
      }
    }
  }
`;

const UserStatusOuter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 100%;
`;

const HeaderTitleL = styled.div`
  text-align: left;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.25);
  @media only screen and (max-width: 599px) {
    text-align: center;
  }
`;

const HeaderTitleR = styled(HeaderTitleL)`
  text-align: right;
  @media only screen and (max-width: 599px) {
    display: none;
  }
`;

const Active = styled(Button)`
  && {
    color: ${p => p.theme.primary};
  }
`;

const Decline = styled(Button)`
  && {
    color: ${p => p.theme.danger};
  }
`;

const Error = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-bottom: 30px;

  width: 100%;

  h1 {
    margin-bottom: 20px;
    font-size: 21px;
  }
`;

const ErrorButton = styled(PrimaryButton)`
  && {
    width: 100px;
  }
`;

const SectionHeaders = ({ title }: any) => {
  return (
    <SectionHeader>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={9}>
          <HeaderTitleL>
            {title}
          </HeaderTitleL>
        </Grid>
        <Grid item xs={12} sm={3}>
          <HeaderTitleR>
            Actions
          </HeaderTitleR>
        </Grid>
      </Grid>
    </SectionHeader>
  );
};

export const DetailPanel: React.FC<any> = ({ organisation, history }) => {

    const [state, setState] = useState<any>({
        users: [],
        owner: null
    });

    const [assigning, setAssigning] = useState<boolean>(false);

    const { id } = organisation;

    const [fetchUsers, { data, loading, error }] = useLazyQuery(MANAGERS_AT_ORG_QUERY, {
        variables: { id },
        fetchPolicy: 'network-only'
    });

    const [revoke, { loading: revoking }] = useMutation(UPDATE_ORG_ACCESS);
    const [assign, { loading: updatingOwner }] = useMutation(UPDATE_ORG_QUERY);

    const disabled = revoking || updatingOwner;

    useEffect(() => {
        if (data && data.managersByOrgId) {
            setState({
                users: data.managersByOrgId.filter((user: any) => user.id !== organisation.ownedBy && !user.orgAccess.archived),
                owner: data.managersByOrgId.find((user: any) => user.id === organisation.ownedBy)
            })
        } else {
            fetchUsers()
        }
    }, [organisation, data, fetchUsers]);

    const revokeAccess = useCallback(async (user: any) => {
        Swal.fire({
            title: 'Revoke Organisation Access',
            text: 'This will remove the user from the org admin list. They will no longer be visible.',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Confirm'
        }).then(async (result) => {
            if (result.value) {
                await revoke({
                    variables: {
                        id: user.orgAccess.id,
                        updateOrgAccess: {
                            archived: true
                        }
                    }
                });
                setAssigning(false);
                presentSuccess('Organisation Access has been revoked!');
                fetchUsers();
            }
        });
    }, [revoke, fetchUsers]);

    const assignOwner = useCallback(async (user: any) => {
        Swal.fire({
            title: 'Assign New Organisation Owner',
            text: 'This will change the current active organisation owner. Are you sure you sure you want to continue?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Confirm'
        }).then(async (result) => {
            if (result.value) {
                await assign({
                    variables: {
                        id: organisation.id,
                        updateOrganisation: {
                            ownedBy: user.id
                        }
                    }
                });
                setAssigning(false);
                presentSuccess('Organisation Owner has been changed!');
                organisation.ownedBy = user.id;
                fetchUsers();
            }
        });
    }, [fetchUsers, assign, organisation]);

    const handleChangeOwner = useCallback((e: any) => {
        if (state.users.length === 0) {
            presentError('cannot appoint owner with empty manager list!');
            return
        }
        setAssigning( value => !value)
    }, [state]);

  const UserRow = ({ user }: any) => {
    return (
      <Section>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
                <RowTitle onClick={ () => !user ? null : history.push('/users/edit/' + user.shortid) }>
                    <UserImg
                        src={ !user || !user.profilePicUrl ? require('../../assets/img/default-org.png') : getThumbnail(user.profilePicUrl)}
                        alt="Profile Picture"
                    />
                    <UserName>{ user ? (user.firstname + ' ' + user.lastname) : 'No Owner Appointed' }</UserName>
                </RowTitle>
            </Grid>
            <Grid item xs={12} sm={4}>
              <UserStatusOuter>
                <UserStatus>
                    { user && (
                        user.id === organisation.ownedBy
                            ? ( assigning
                                    ? <Decline disabled={disabled} onClick={handleChangeOwner}>Cancel Change</Decline>
                                    : (
                                        <Active disabled={disabled} onClick={handleChangeOwner}>
                                            { assigning ? ( disabled ? <CircularProgress size={20} color="inherit" /> : 'Cancel' ) : 'Change Owner' }
                                        </Active>
                                      )
                            ) : (
                                !assigning
                                    ? (
                                        <Decline disabled={disabled} onClick={() => revokeAccess(user)}>
                                            { disabled ? <CircularProgress size={20} color="inherit" /> : 'Revoke' }
                                       </Decline>
                                    ): <Active disabled={disabled} onClick={() => assignOwner(user)}>Assign</Active>
                                )

                    )}
                    { !user &&
                        <Active disabled={disabled} onClick={handleChangeOwner}>{ assigning ? 'Cancel' : 'Assign Owner'}</Active>
                    }
                </UserStatus>
              </UserStatusOuter>
            </Grid>
          </Grid>
      </Section>
    );
  };

    if (loading) return (
        <SkeletonLoader showHeader={false} showCard={false} title="Organisation Users" type="list" iterations={3}/>
    );

    if (error) return (
        <Error>
          <h1>Something went wrong!</h1>
          <ErrorButton onClick={() => fetchUsers()}>Try Again</ErrorButton>
        </Error>
    );

    return (
        <OuterContainer>
            <Container>
                <SectionContainer>
                    <SectionHeader title="Org Owner" />
                    <UserRow user={state.owner} />
                </SectionContainer>
                <SectionContainer>
                    <SectionHeaders title="Org Admins" />
                    { state.users.map((user: any) => <UserRow key={user} user={user} />) }
                    { state.users.length === 0 &&
                        <EmptyMessage>No managers appointed</EmptyMessage>
                    }
                </SectionContainer>
            </Container>
        </OuterContainer>
    )
};

export default withRouter(DetailPanel)
