import React from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Grid, Paper } from '@material-ui/core';
import { Group, PersonAdd, ViewStream, SupervisedUserCircle, Send, ViewList, GroupWork, PostAdd } from '@material-ui/icons';
import { useWindowWidth } from '../../custom-hooks/viewhooks';
import { Page } from '../../global/style';
import { getActiveOrgId, getIsAdmin, getIsCurrentOrgOwner, getIsOrg } from '../../global/helpers/selectors';

import { withTheme } from 'styled-components';

import { useCognitoUser,  useUserValidations } from '../../custom-hooks';

const ContainerStyle = styled.div`
  width: 100%;
  max-width: 768px;
`

const CardStyle = styled(Paper)<{ color: string, background: string }>`
  && {
    color: ${p => p.color };
    background: ${p => p.background };
    padding: 40px 20px;
    text-align: center;
    font-size: 26px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    border-radius: 2px;
    cursor: pointer;
    :hover {
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
    }
    svg {
      font-size: 80px;
    }
  }
`;

const GridOuter = styled(Grid)`
  padding: 10px;
`;

interface Props {
  onRedirect: () => void;
}

const Card: React.FC<Props & any> = withTheme(({ key, path, children, onRedirect, alternative = false, theme, disabled = false }) => {

  const getColor = (guard: boolean) => guard ? 'white' : theme.primary

  if (disabled) return null

  return (
    <Grid item xs={12} sm={6}>
      <CardStyle onClick={() => onRedirect(path)} color={getColor(alternative)} background={getColor(!alternative)}>
        { children }
      </CardStyle>
    </Grid>
  )
})

interface ContainerProps {
  onRedirect: () => void;
}

const Container: React.FC<ContainerProps & any> = ({ children, onRedirect }) => {
  return (
    <ContainerStyle>
      <GridOuter container spacing={1}>
        {
          children.map((child: React.ReactElement, index: number) => (
            child ? React.cloneElement(child, { onRedirect, key: index }) : null
          ))
        }
      </GridOuter>
    </ContainerStyle>
  )
}

const Home = ({ props }: any) => {

  const [cognitoUser] = useCognitoUser()
  const [{ isAdmin, isOrg, isCurrentOrgOwner: isOwner, activeOrgId}] = useUserValidations()
  const { windowWidth } = useWindowWidth(window.innerWidth);


  const onRedirect = (route: string) => props.history.push(route)

  const renderAddList = cognitoUser && (isOrg || isAdmin)
  const renderUserCircle = isOwner || isAdmin

  const windowState = windowWidth >= 600;

  return (
    <Page>
      <Container onRedirect={onRedirect}>
        <Card
          path="/account/org/access"
          disabled={isAdmin}
        >
          <ViewStream />
          My Access
        </Card>
        <Card
          path="/org/list"
          disabled={!isAdmin}
        >
          <ViewList />
           Organisations List
        </Card>
        <Card
          alternative
          path="/players/list"
          disabled={!renderAddList}
         >
            <Group />
            Player List
        </Card>
        <Card
          alternative={windowState}
          path="/players/add"
          disabled={!renderAddList}
        >
            <PersonAdd />
            Add Player
        </Card>
        <Card
          alternative={!windowState}
          path="/players/invite"
          disabled={!renderAddList}
        >
            <Send />
            Invite Players
        </Card>
        <Card
          path="/managers/add"
          disabled={!renderAddList && renderUserCircle}
        >
            <SupervisedUserCircle />
            Add Manager
        </Card>
        <Card
          alternative={isOwner}
          path={'/org/edit/' + activeOrgId}
          disabled={!renderAddList || isAdmin}
        >
              <GroupWork />
              My Organisation
        </Card>
        <Card
          alternative
          path="/org/add"
          disabled={!isAdmin && !renderAddList}
        >
          <PostAdd />
          Add Organisation
        </Card>
      </Container>
    </Page>
  );
};


const mapStateToProps = (state: any, props: any) => {
  return {
    cognitoUser: state.cognitoUser,
    activeOrgId: getActiveOrgId(state),
    isOwner: getIsCurrentOrgOwner(state),
    isAdmin: getIsAdmin(state),
    isOrg: getIsOrg(state),
    props
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {};
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Home)
);
