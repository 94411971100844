import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Provider from './components/provider/Provider';
import * as serviceWorker from './serviceWorker';

import { toast } from 'react-toastify';

import reduxConfig from './config/redux-config';
import apolloConfig from './config/apollo-config';
import amplifyConfig from './config/amplify-config';

const { store, persistor } = reduxConfig();
const apolloClient = apolloConfig(store);
amplifyConfig(store);

toast.configure();

ReactDOM.render(<Provider apolloClient={apolloClient} persistor={persistor} store={store} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
