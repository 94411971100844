import styled from 'styled-components';
import { Grid, Container } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { PrimaryButton } from '../../global/style';

export const CContainer = styled(Container)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    position: relative;
    text-align: center;
    color: white;
  }
  && > h3 {
    text-align: left;
    width: 100%;
  }
  && > p {
    text-align: left;
    width: 100%;
  }
`;

export const CPrimaryButton = styled(PrimaryButton)`
  && {
    flex: 1;
  }
`;

export const GridOuter = styled(Grid)`
  flex: 1;
  justify-content: center;
  justify-items: center;
`;

export const Add = styled(AddCircleOutlineIcon)`
  color: white;
  font-size: 8rem !important;
  text-align: center;
`;

export const PfpDiv = styled.div`
  border: 2px dashed rgb(10, 53, 90);
  border-image: none;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
`;

export const DropzoneInner = styled.div`
  height: 100%;
  color: black;
  text-align: center;
  padding: 3rem 2rem;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
`;

export const TableOuter = styled.div`
  overflow-x: scroll;
  width: 100%;
  flex-shrink: 0;
`;
