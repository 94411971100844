export enum Role {
    PLAYER = 'PLAYER',
    COACH = 'COACH',
    CLUB = 'CLUB'
}

export const getRoles = (player: boolean, coach: boolean, club: boolean): Role[] => {
    const roles: Role[] = [];
    if (player) roles.push(Role.PLAYER);
    if (coach) roles.push(Role.COACH);
    if (club) roles.push(Role.CLUB)
    return roles;
}