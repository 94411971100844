import React from 'react';
import styled from 'styled-components';
import { KeyboardArrowLeft as Back, Refresh } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import {
  Page,
  CenteredColumn,
  CardTitle,
  Divider,
  Card,
  BackButton
} from '../../global/style';

const TitleOuter = styled.div`
  flex: 1;
`;

interface Props {
  Title: any;
  children: any;
  back?: (e: any) => void;
  reload?: (e: any) => void;
}

export const PageLayout: React.FC<Props> = ({ back, Title, children, reload}) => {
  return (
    <Page>
      <Card>
        <CenteredColumn>
          <CardTitle>
            <BackButton onClick={back}>
              <Back />
            </BackButton>
            <TitleOuter>
              <Title />
            </TitleOuter>
            <IconButton onClick={reload}>
              <Refresh />
            </IconButton>
          </CardTitle>
          <Divider />
          {children}
        </CenteredColumn>
      </Card>
    </Page>
  );
};

export default PageLayout;
