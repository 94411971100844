import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { getUserData } from '@necta-tech/cognito';

export default (store: any) => {

    console.log(process.env.REACT_APP_API_URL)

    const httpLink = new HttpLink({
        uri: process.env.REACT_APP_API_URL
    });

    //perhaps should not return a promise(incase things dont work)
    const asyncAuthMiddleware = setContext(({headers}: any) => new Promise((success, fail) => {
        getToken().then(token => {
            success({
                headers: {
                    ...headers,
                    Authorization: `Bearer ${token}`
                }
            })
        }).catch(ex => {
            success({ headers });
        })
    }));

    //refactor for async middleware

    const getToken = async (): Promise<any> => {

        const cognitoUser = store.getState().cognitoUser;

        if (!cognitoUser.inSession) return Promise.reject("User not in session");

        const fetchedUser = await getUserData();

        return Promise.resolve(fetchedUser.accessToken!.getJwtToken());
    }

    const apolloClient = new ApolloClient({
        link: asyncAuthMiddleware.concat(httpLink),
        cache: new InMemoryCache({
            addTypename: false
        })
    });

    return apolloClient;
}
