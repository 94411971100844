import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';

export const SpinnerContainer = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   height: 100vh;
   width: 100vw;
`;

const LoadingSpinner: React.FC = (props: any) => {
    return (
        <SpinnerContainer>
            <CircularProgress size={48} color="primary"/>
        </SpinnerContainer>
    );
}

export default LoadingSpinner