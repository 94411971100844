import React, { useState } from 'react'
import { TextField, Grid, Card as CardComp, AppBar, Tabs, Tab, CircularProgress } from '@material-ui/core'
import { getThumbnail } from '@necta-tech/s3'
import { generatePassword } from '../../services/helpers/form-helper'
import { useValues } from '../../custom-hooks/formhooks'
import Password from '../../components/form/Password'
import { PrimaryButton } from '../../global/style'
import { useMutation } from 'react-apollo'
import { presentSuccess } from '@necta-tech/alert'
import SwipeableViews from 'react-swipeable-views';
import gql from 'graphql-tag'
import styled from 'styled-components'

const Card = styled(CardComp)`
    && {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    background: #eaeaea;
`

const CPrimaryButton = styled(PrimaryButton)`
    && {
        margin: 0.2rem;
        max-width: 250px;
        height: 2.6rem;
        font-size: 0.8rem !important;
    }
`

const ImageItem = styled(Grid)<{background?: string}>`
    min-height: 215px;
    background: url(${props => props.background ? props.background : require('../../assets/img/default-profile.png')}) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;    
`

const UserInfo = styled(Grid)`
    padding: 1rem 0.5rem;
    h3 {
        font-weight: 500;
        font-size: 1.25rem;
        margin: 0.4rem 0 0.7rem 0;
    }
    h4 {
        font-size: 0.95rem;
        font-weight: 400;
        margin: 0.3rem 0;
    }
    p {
        margin: 0.2rem 0 1rem 0;
    }
`;

const PASSWORD_QUERY = gql`
    mutation UPDATE_PASSWORD_QUERY($id: String!, $password: String!) {
        updateUserPassword(id: $id, password: $password) {
            id
        }
    }
`

const EMAIL_QUERY = gql`
    mutation UPDATE_EMAIL_QUERY($id: String!, $email: String!) {
        updateUser(id: $id, user: {
            email: $email
        }) {
            id
        }
    }
`

//TODO: implement proper query to handle password submission
const DetailPanel = ({rowData}: any) => {

    const getLink = (user: any) => {
        return 'https://scrumpro.co.za/cv/' + user.shortid;
    }

    return (
        <Container>
            <Grid container spacing={1}>
                <Grid item xs={5}>
                    <Card>
                        <Grid container spacing={0}>
                            <ImageItem item md={5} xs={12} background={rowData.profilePicUrl ? getThumbnail(rowData.profilePicUrl) : ''} />
                            <UserInfo item md={7} xs={12}>
                                <h3>{rowData.firstname} {rowData.lastname}</h3>
                                <h4>CV Link:</h4>
                                <p><a href={getLink(rowData)} target="_blank" rel="noopener noreferrer">{getLink(rowData)}</a></p>
                                <h4>Position:</h4>
                                <p>{rowData.stats && rowData.stats.positionPrimary || 'None selected'}</p>
                            </UserInfo>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <div>
                        <CPrimaryButton onClick={() => window.open(getLink(rowData), '_blank')}>
                            View Full CV
                        </CPrimaryButton>
                    </div>
                </Grid>
            </Grid>
        </Container>
    )
}

export default DetailPanel
