import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import { presentSuccess, presentError } from '@necta-tech/alert';
import { useMutation, useLazyQuery } from 'react-apollo';
import { KeyboardArrowLeft as Back, ExpandLess, ExpandMore } from '@material-ui/icons';
import { getThumbnail } from '@necta-tech/s3';
import { Formik, Field, FormikValues, FormikHelpers } from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Dropzone from 'react-dropzone';
import { setCurrentUser } from '../../redux/actions';
import FileUpload from '../../components/file-upload/FileUpload';
import {
  CheckboxField,
  DatePicker as FormikDatePicker,
  PasswordField,
  SelectField,
  TextField as FormikTextField,
  getFormData,
  generatePassword,
  LocationPicker
} from '../../services/helpers/form-helper';
import {
  Page,
  CenteredColumn,
  Form,
  CardTitle,
  Divider,
  Required,
  SubTitle,
  Card,
  BackButton,
  PrimaryButton,
  Collapser,
  Collapsable,
  Collapse,
  ImageContainer,
  ImageClick,
  DropzoneContainer,
  DropzoneInner,
  Highlight
} from '../../global/style';
import LoadingSpinner from '../../components/loading-spinner/LoadingSpinner';
import { useExpanded } from '../../custom-hooks/formhooks';
import ErrorDisplay from '../../components/error-display/ErrorDisplay';
import { mapArrayToObjects } from '../../global/helpers/data-maps';
import { COUNTRIES, SA_PROVINCES, ETHNICITIES, GENDERS, POSITIONS, TEAMS } from '../../global/constants';
import { GET_USER, UPDATE_USER } from '../../global/gql/users';
import playerSchema from '../player/schema';

const MySwal = withReactContent(Swal);

const fullSchema = playerSchema.shape({
  id: yup.string().label('ID'),
  isOther: yup.boolean().label('Other'),
  dateOfBirth: yup.string().nullable().label('Date of Birth'),
  password: yup.string().nullable().label('Password'),
});

const ethnicities = mapArrayToObjects(ETHNICITIES);
const genders = mapArrayToObjects(GENDERS);
const countries = mapArrayToObjects(COUNTRIES);
const positions = mapArrayToObjects(POSITIONS);
const provinces = mapArrayToObjects(SA_PROVINCES);
const teams = mapArrayToObjects(TEAMS);

const { schema, fields, initialValues } = getFormData(fullSchema);

const TextField = styled(FormikTextField)`
  && {
    width: 100%;
  }
`;

const DatePicker = styled(FormikDatePicker)`
  && {
    width: 100%;
  }
`;

const CreateButton = styled(PrimaryButton)`
  && {
    height: 56px;
    width: 100%;
    border-radius: 0;
  }
`;

const TinyTitle = styled(SubTitle)`
  font-size: 14px;
  margin-left: 10px;
`;

const CheckContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserEdit : React.FC<any> = ({ cognitoUser, props }: any) => {

  const [user, setUser] = useState<any>({ ...initialValues, shortid: props.match.params.shortid });

  const { expanded, handleOnExpandedClick } = useExpanded({
    stats: false,
    accountSpecs: true,
    playerSpec: false,
    coachSpec: false,
  });

  const [fetchUser, { loading, data }] = useLazyQuery(GET_USER, {
    onError: (error: any) => {
        presentError(error, 'Could not fetch desired user');
    },
    fetchPolicy: 'network-only'
  });

  const [updateUser, { loading: updating }] = useMutation(UPDATE_USER);

  useEffect(() => {
    if (data && data.cvData) {
      setUser({ ...data.cvData})
    } else {
      fetchUser({ variables: { shortid: user.shortid } });
    }
  }, [data, fetchUser, user.shortid]);

  const onSubmit = async (values: FormikValues, actions: FormikHelpers<any>) => {
    if (values.email !== user.email) {
      const res = await Swal.fire({
        title: 'Change email address',
        text: 'This will change the email address that this user will enter on log in.',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Confirm'
      });
      if (!res.value) {
        return;
      }
    }
    actions.setSubmitting(true);
    try {
      const { id, isOther, ...user } = values as any;
      const result = await updateUser({ variables: { id, user } });
      presentSuccess('User updated successfully!', 'Success');
      setUser({ ...user, ...result.data.adminUpdateUser });
    } catch (e) {
      presentError(e, 'Unable to update user!');
    } finally {
      actions.setSubmitting(false);
    }
  };

  const imageUploaded = (setFieldValue: any) => (key: string) => {
    setFieldValue('profilePicUrl', key);
    MySwal.close();
  };

  const uploadImage = (setFieldValue: any, defaultFiles?: File[]) => {
    MySwal.fire({
      title: 'Upload Profile Picture',
      html: <FileUpload path="profilePictures" id={cognitoUser.username} type="image" single={true} onComplete={imageUploaded(setFieldValue)} defaultFile={defaultFiles} />,
      width: '800px',
      showConfirmButton: false,
      showCloseButton: true
    });
  };

  if (loading) return <LoadingSpinner />;

  if (!data || (data && !data.cvData)) return <ErrorDisplay title="Edit User" error="Unable to load user" />;

  return (
    <Page>
      <Card>
        <CenteredColumn>
          <Formik intialTouched={{dateOfBirth: true}} initialValues={user} enableReinitialize validateOnBlur validationSchema={schema} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
              <>
                <CardTitle>
                  <BackButton onClick={() => props.history.goBack()}>
                    <Back />
                  </BackButton>
                  Edit User
                </CardTitle>
                <Divider />
                <Required>
                  <SubTitle>General Information</SubTitle>
                </Required>
                <Form>
                  <ImageContainer>
                        <img onClick={() => uploadImage(setFieldValue)} alt="Profile" src={!values.profilePicUrl ? require('../../assets/img/default-org.png') : getThumbnail(values.profilePicUrl)} />
                    <ImageClick>
                      <Dropzone onDrop={acceptedFiles => uploadImage(setFieldValue, acceptedFiles)} disabled={loading}>
                        {({ getRootProps, getInputProps }) => (
                          <DropzoneContainer {...getRootProps()}>
                            <input {...getInputProps()} />
                            <DropzoneInner>
                              <h4>Profile Picture<br />
                              Drag and Drop or <Highlight>Click</Highlight> to search</h4>
                            </DropzoneInner>
                          </DropzoneContainer>
                        )}
                      </Dropzone>
                    </ImageClick>
                  </ImageContainer>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Field {...fields.firstname} component={TextField} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field {...fields.lastname} component={TextField} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field {...fields.email} component={TextField} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field {...fields.contactNumber} component={TextField} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field {...fields.idNumber} component={TextField} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field {...fields.gender} component={SelectField} options={genders} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field {...fields.ethnicity} component={SelectField} options={ethnicities} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Field {...fields.dateOfBirth} component={DatePicker} />
                    </Grid>
                  </Grid>
                </Form>
                <Divider />

                <>
                  <Collapser onClick={(e: any) => handleOnExpandedClick(e, 'location')}>
                    <Required>
                      <SubTitle>Location</SubTitle>
                      {expanded.location ? <ExpandLess /> : <ExpandMore />}
                    </Required>
                  </Collapser>
                  <Collapse in={expanded.location}>
                    <Collapsable>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Field {...fields.locationAddress} component={LocationPicker} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field {...fields.locationCountry} component={SelectField} options={countries} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field {...fields.locationProvince} component={SelectField} options={provinces} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field {...fields.locationCity} component={TextField} />
                        </Grid>
                      </Grid>
                    </Collapsable>
                  </Collapse>
                  <Divider />
                </>

                { (values.isPlayer || values.isCoach) && (
                  <>
                    <Collapser onClick={(e: any) => handleOnExpandedClick(e, 'stats')}>
                      <Required>
                        <SubTitle>Stats</SubTitle>
                        {expanded.stats ? <ExpandLess /> : <ExpandMore />}
                      </Required>
                    </Collapser>
                    <Collapse in={expanded.stats}>
                      <Collapsable>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12}>
                            <Field {...fields.stats.bio} component={TextField} multiline={true} />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Field {...fields.stats.height} component={TextField} />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Field {...fields.stats.weight} component={TextField} />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Field {...fields.stats.positionPrimary} component={SelectField} options={positions} />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Field {...fields.stats.positionSecondary} component={SelectField} options={positions} />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Field {...fields.stats.team} component={SelectField} options={teams} />
                          </Grid>
                        </Grid>
                      </Collapsable>
                    </Collapse>
                    <Divider />
                  </>
                )}

                { values.isCoach && (
                  <>
                    <Collapser onClick={(e: any) => handleOnExpandedClick(e, 'coachSpec')}>
                      <Required>
                        <SubTitle>Coach Specifics</SubTitle>
                        {expanded.coachSpec ? <ExpandLess /> : <ExpandMore />}
                      </Required>
                    </Collapser>
                    <Collapse in={expanded.coachSpec}>
                      <Collapsable>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Field {...fields.stats.coachingSpecialization} component={TextField} />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Field {...fields.stats.coachingQualification} component={TextField} />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Field {...fields.stats.coachingHighestLevel} component={TextField} />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Field {...fields.stats.clubCurrent} component={TextField} />
                          </Grid>
                        </Grid>
                      </Collapsable>
                    </Collapse>
                    <Divider />
                  </>
                )}

                { values.isPlayer && (
                  <>
                    <Collapser onClick={(e: any) => handleOnExpandedClick(e, 'playerSpec')}>
                      <Required>
                        <SubTitle>Player Specifics</SubTitle>
                        {expanded.playerSpec ? <ExpandLess /> : <ExpandMore />}
                      </Required>
                    </Collapser>
                    <Collapse in={expanded.playerSpec}>
                      <Collapsable>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                              <Field {...fields.stats.levelPlayedSenior} component={TextField} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Field {...fields.stats.levelPlayedJunior} component={TextField} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Field {...fields.stats.educationSchool} component={TextField} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Field {...fields.stats.accolades} component={TextField} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Field {...fields.stats.skills} component={TextField} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Field {...fields.stats.injuryHistory} component={TextField} />
                            </Grid>
                          </Grid>
                      </Collapsable>
                    </Collapse>
                    <Divider />
                  </>
                )}

                <>
                  <Collapser onClick={(e: any) => handleOnExpandedClick(e, 'accountSpecs')}>
                    <Required>
                      <SubTitle>Account Specifics</SubTitle>
                      {expanded.accoutSpecs ? <ExpandLess /> : <ExpandMore />}
                    </Required>
                  </Collapser>
                  <Collapse in={expanded.accountSpecs}>
                    <Collapsable>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TinyTitle>Account Type:</TinyTitle>
                          <CheckContainer>
                            <Field {...fields.isPlayer} component={CheckboxField} />
                            <Field {...fields.isCoach} component={CheckboxField} />
                            <Field {...fields.isOther} component={CheckboxField} />
                          </CheckContainer>
                        </Grid>
                          <Required>
                            <TinyTitle>Update Password:</TinyTitle>
                          </Required>
                        <Grid item xs={12} md={9}>
                          <Field {...fields.password} component={PasswordField} />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <CreateButton onClick={() => setFieldValue('password', generatePassword())}>
                            Generate
                          </CreateButton>
                        </Grid>
                      </Grid>
                    </Collapsable>
                  </Collapse>
                </>

                <CreateButton disabled={loading || isSubmitting} onClick={(e: any) => handleSubmit(e)}>
                  { updating || isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Update Information' }
                </CreateButton>
              </>
            )}
          </Formik>
        </CenteredColumn>
      </Card>
    </Page>
  );
};

const mapStateToProps = (state: any, props: any) => {
  return {
    cognitoUser: state.cognitoUser,
    currentUser: state.currentUser,
    props
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setCurrentUser: (currentUser: any) => {
      dispatch(setCurrentUser(currentUser));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserEdit)
);
