import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLazyQuery, useMutation } from 'react-apollo';
import MaterialTable, { Column } from 'material-table';
import { Edit, Delete, Cached } from '@material-ui/icons';
import  { presentError, presentSuccess } from '@necta-tech/alert';
import { DELETE_USER_QUERY, ALL_USERS_QUERY } from '../../global/gql/users';
import Swal from "sweetalert2";
import { getActiveOrgId, getIsAdmin, getIsCurrentOrgOwner } from '../../global/helpers/selectors';
import { getDateFull } from '../../global/helpers/table-helper';
import { TableOuter, TableContainer } from '../../global/style';
import { useHistory } from 'react-router';
import PermissionSelect from './PermissionSelect';

const UserList : React.FC<any> = ({ cognitoUser, isAdmin }) => {
  const [users, setUsers] = useState([]);

  const history = useHistory();

  const [fetchUsers, { loading, data }] = useLazyQuery(ALL_USERS_QUERY, {
    variables: { userQuery: { } },
    fetchPolicy: 'network-only',
    onError: (error: any) => {
      console.log(error)
    }
  });

  useEffect(() => {
    if (data && data.allUsers) {
      setUsers(data.allUsers);
    } else {
      fetchUsers();
    }
  }, [loading, fetchUsers, data]);

  const [handleDelete, { loading: isLoading }] = useMutation(DELETE_USER_QUERY, {
    onError: error => {
      presentError(error, "Unable to delete");
    }
  });

  const userChanged = useCallback((user: any) => {
    const found = users.find((u: any) => u.id === user.id) as any;
    if (found) found.role = user.role;
  }, [users]);

  const deleteUser = useCallback(async (e: any, row: any) => {
    if (!isAdmin) {
      presentError('Only an admin may perform this action');
      return;
    }
    if (row.id === cognitoUser.username) {
      presentError('You may not delete yourself');
      return;
    }
    const confirm = await Swal.fire({
      title: 'Delete user',
      text: 'This will permanently delete the user',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete'
    });
    if (!confirm.value) {
      return;
    }
    const res = await handleDelete({ variables: { id: row.id } });
    if (res) { // Present success
      presentSuccess("User deleted successfully", "Success", true);
      const filtered = users.filter((d: any) => d.id !== row.id);
      setUsers(filtered);
    }
  }, [setUsers, users, handleDelete, isAdmin, cognitoUser.username]);

  const editUser = useCallback(async (e: any, row: any) => {
    if (!isAdmin) {
      presentError('Only admins may edit users from here.');
      return;
    }
    history.push('/users/edit/' + row.shortid);
  }, [isAdmin, history]);

  const columns: Column<any>[] = [
    {
      title: 'First Name',
      field: 'firstname'
    }, {
      title: 'Surname',
      field: 'lastname'
    }, {
      title: 'Email',
      field: 'email',
      filtering: true,
    }, {
      title: 'Cell',
      field: 'contactNumber',
      filtering: true,
    }, {
      title: 'Role',
      field: 'role',
      render: (r: any) => <PermissionSelect user={r} onChange={userChanged}/>,
      filtering: true,
    }, {
      title: 'Shortid',
      field: 'shortid',
      hidden: true,
      filtering: true,
    }, {
      title: 'Database ID',
      field: 'id',
      hidden: true,
      filtering: false,
      editable: 'never'
    }, {
      title: 'Signed Up',
      field: 'createdAt',
      filtering: false,
      sorting: true,
      editable: 'never',
      render: (r: any) => r ? getDateFull(r.createdAt) : '',
    }
  ];

  return (
    <TableContainer>
      <TableOuter onContextMenu={e => console.log('do something')}>
        <MaterialTable
          title={'User List'}
          columns={columns}
          options={{
            emptyRowsWhenPaging: false,
            columnsButton: true,
            pageSize: 10,
            filtering: true,
            exportButton: true
          }}
          isLoading={loading || isLoading}
          data={users}
          actions={[
            {
              tooltip: 'Edit',
              icon: () => <Edit />,
              onClick: editUser,
            },
            {
              tooltip: 'Delete user',
              icon: () => <Delete />,
              onClick: deleteUser,
              hidden: !isAdmin
            },
            {
              tooltip: 'Refresh',
              icon: () => <Cached />,
              onClick: fetchUsers,
              isFreeAction: true
            }
          ]}
        />
      </TableOuter>
    </TableContainer>
  )
};

const mapStateToProps = (state: any, props: any) => {
  return {
    cognitoUser: state.cognitoUser,
    currentUser: state.currentUser,
    isOwner: getIsCurrentOrgOwner(state),
    isAdmin: getIsAdmin(state),
    activeOrgId: getActiveOrgId(state),
    ...props,
  }
};

const mapDispatchToProps = (dispatch: Function) => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
