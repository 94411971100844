import * as yup from 'yup';
import { ETHNICITIES, POSITIONS, SA_PROVINCES, BIRTHDAY_ERROR, PASSWORD_ERROR } from '../../global/constants';
import { generatePassword } from '../../services/helpers/form-helper';
import { regExPassword } from '../../global/regex';

const DATE_REGEX = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;

const playerSchema = yup.object().shape({
  firstname: yup
    .string()
    .required('First name is a required field')
    .defined()
    .label('First Name'),
  lastname: yup
    .string()
    .required('Last name is a required field')
    .defined()
    .label('Last Name'),
  email: yup
    .string()
    .required('Email is a required field')
    .defined()
    .email('Please enter a valid email')
    .trim()
    .label('Email'),
  contactNumber: yup
    .string()
    // .required('Contact number is a required field')
    // .defined()
    .nullable()
    .notRequired()
    .label('Contact Number'),
  parentalContact: yup
    .string()
    .nullable()
    .notRequired()
    .label('Parental Contact'),
  dateOfBirth: yup
    .string()
    .required(BIRTHDAY_ERROR)
    .matches(DATE_REGEX, BIRTHDAY_ERROR)
    .defined()
    .meta(['date'])
    .label('Date of Birth'),
  gender: yup
    .string()
    .nullable()
    .notRequired()
    .oneOf([null, 'Male', 'Female'])
    .meta(['select'])
    .label('Gender'),
  ethnicity: yup
    .string()
    .nullable()
    .notRequired()
    .oneOf([null, ...ETHNICITIES])
    .meta(['select'])
    .label('Ethnicity'),
  idNumber: yup
    .string()
    .nullable()
    .notRequired()
    .label('ID Number'),
  locationAddress: yup
    .string()
    .nullable()
    .notRequired()
    .label('Address'),
  locationCountry: yup
    .string()
    .nullable()
    .notRequired()
    .label('Country'),
  locationProvince: yup
    .string()
    .nullable()
    .notRequired()
    .oneOf([null, ...SA_PROVINCES])
    .meta(['select'])
    .label('Province'),
  locationCity: yup
    .string()
    .nullable()
    .notRequired()
    .label('City'),

  stats: yup.object().shape({
    height: yup
      .string()
      .nullable()
      .notRequired()
      .label('Height'),
    weight: yup
      .string()
      .nullable()
      .notRequired()
      .label('Weight'),
    bio: yup
      .string()
      .nullable()
      .notRequired()
      .label('Bio'),
    positionPrimary: yup
      .string()
      .nullable()
      .notRequired()
      .oneOf([null, ...POSITIONS])
      .meta(['select'])
      .meta(['select'])
      .label('Primary Position'),
    positionSecondary: yup
      .string()
      .nullable()
      .notRequired()
      .oneOf([null, ...POSITIONS])
      .meta(['select'])
      .label('Secondary Position'),
    levelPlayedSenior: yup
      .string()
      .nullable()
      .notRequired()
      .label('Senior Played Level'),
    levelPlayedJunior: yup
      .string()
      .nullable()
      .notRequired()
      .label('Junior Played Level'),
    educationSchool: yup
      .string()
      .nullable()
      .notRequired()
      .label('School Attended'),
    educationDescription: yup
      .string()
      .nullable()
      .notRequired()
      .label('Education Description'),
    accolades: yup
      .string()
      .nullable()
      .notRequired()
      .label('Accolades'),
    skills: yup
      .string()
      .nullable()
      .notRequired()
      .label('Skills'),
    injuryHistory: yup
      .string()
      .nullable()
      .notRequired()
      .label('Injury History'),
    coachingSpecialization: yup
      .string()
      .nullable()
      .notRequired()
      .label('Coaching Specialization'),
    coachingQualification: yup
      .string()
      .nullable()
      .notRequired()
      .label('Coaching Qualification'),
    coachingHighestLevel: yup
      .string()
      .nullable()
      .notRequired()
      .label('Highest Level Coaching'),
    clubCurrent: yup
      .string()
      .nullable()
      .notRequired()
      .label('Current Club'),
    team: yup
      .string()
      .nullable()
      .notRequired()
      .label('Team'),
  }).defined().label('stats'),

  isPlayer: yup
    .boolean()
    .defined()
    .label('Player'),
  isCoach: yup
    .boolean()
    .defined()
    .label('Coach'),
  password: yup
    .string()
    .required('A strong password is required')
    .matches(regExPassword, PASSWORD_ERROR)
    .default(generatePassword())
    .meta(['password'])
    .label('Password'),
}).defined();

export const confirmSchema = yup.object().shape({
  organisationId: yup
    .string()
    .required('Please select an organisation')
    .typeError('Please select an organisation')
    .label('Organisation'),
  sendWelcome: yup
    .boolean()
    .default(true)
    .label('Send Welcome Email'),
  downloadExcel: yup
    .boolean()
    .default(true)
    .label('Download Copy of Import'),
});

export type Player = yup.InferType<typeof playerSchema>;

export default playerSchema;
