import styled from 'styled-components';
import { PrimaryButton } from '../../global/style';

export const Submit = styled(PrimaryButton)`
  && {
      width: 90%;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      @media only screen and (max-width: 768px) {
        width: 96%;
      }
      max-width: 600px;
    }
`;