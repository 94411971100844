import { useState } from 'react'
import { useCognitoUser } from '../../custom-hooks'
import { logout } from '@necta-tech/cognito'

export const useLogout = () => {
    const [_, setCognitoUser] = useCognitoUser()
    const [result, setResult] = useState(null)
    const [error, setError] = useState(null)

    const handleLogout = async (onLogout?: () => void) => {
        try {
            await logout()
            setResult(result)
            setCognitoUser({ inSession: false })
            if (onLogout) onLogout()
        } catch(e) {
            setError(e)
        }
    }

    return [
        handleLogout,
        {
            result,
            loading: !!result,
            error
        }
    ] as const;
}

export default useLogout